import React from "react";
import { AboutUsBanner, WhyMedSetGo } from "../components/BannerSection";
import { TeamComponent } from "../components/TestimonialComponent";
import Wrapper from "../components/Wrapper";
import "./styles/index.css";
const AboutUs = () => {
  return (
    <Wrapper>
      <AboutUsBanner />
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="service-banner"
      >
        <p className="about-paragraph">
          We are firm believers of working with compassion when it comes to
          patient care. At the most vulnerable time of one’s life, trust and
          kindness play an important part. The hassles of running around for
          tests, access to the right medical network and something basic as
          transportation during times of crises, often deter a seamless journey
          to medical care.
        </p>
        <p className="about-paragraph">
          We intend to transform this experience for every single one of you.
          Backed with technology that takes care of logistics, a strong network
          that provides you the best healthcare and a team that places your
          comfort over anything else.
        </p>
        <p className="italic">
          We deliver you peaceful and trusted healthcare, when you need it the
          most.
        </p>
      </section>
      <section className="service-banner about-left">
        <div className="container">
          <div className="serv-banner-inner flex align-items-center">
            <div className="banner-left-box">
              <div className="services-banner-info">
                <h1>Our Mission</h1>
                <h4>Care with compassion, Makes it special.</h4>
                <p>
                  To ensure reliability, affordability and convenience become an
                  integral part of the healthcare system. By removing logistical
                  barriers and keeping compassion at the core, we intend to make
                  healthcare access equal and convenient to all.
                </p>
              </div>
            </div>
            <div className="banner-right-box">
              <img
                src="assets/images/young-doctor-supporting-his-patient 1.jpg"
                alt=""
                className="flex"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="service-banner about-left">
        <div className="container">
          <div
            style={{ flexDirection: "row-reverse" }}
            className="serv-banner-inner flex align-items-center"
          >
            <div className="banner-left-box">
              <div className="services-banner-info">
                <h1>Our Vision</h1>
                <p>
                  To upgrade the healthcare ecosystem through innovation and
                  technology that allows custom-made healthcare services for
                  every patient. Equal access and less hassle can contribute to
                  topnotch health facilities knocking at your doorstep, whenever
                  and wherever you want.
                </p>
              </div>
            </div>
            <div className="banner-right-box">
              <img
                src="assets/images/successful-medical-team 1.jpg"
                alt=""
                className="flex"
              />
            </div>
          </div>
        </div>
      </section>

      <WhyMedSetGo />


      <section className="service-banner about-left why-section">
        <TeamComponent />
      </section>
    </Wrapper>
  );
};

export default AboutUs;
