
import { useState } from "react";
import VideoPreview from "../../modals/VideoPreview";

import v1 from '../../../assets/videos/partnerVideo1.mov'
import v2 from '../../../assets/videos/partnerVideo2.mov'
import v3 from '../../../assets/videos/partnerVideo3.mov'

const Testimonials = () => {
  const [currentVideo, setVideo] = useState(0);
  return (
    <div className="w-100 mb-5">
      <div className="container">
        <div className="text-center fs-40 text-primary-light home-mobile-size-label pb-3">
          Proven Testimonials From Our Partners
        </div>

      
        <div

          className="testimonial-wrapper pb-4"
        >
        <div  >
          <div
            className="testimonial-item-box"
            onClick={() => setVideo(1)}
          >
            <img alt='testimonial' src="/assets/videos/partners/partner1thumb.png" />
            <div className="content">
              <div className="fs-16 fw-500 text-white">Tracy</div>
              <div className="fs-12 text-white">Case Manager, Silicon Valley Orthopaedics</div>
            </div>
          </div>
            <div className="text-center pt-2">
            “Simplified home-care with efficient agency communication.”
            </div>
            </div>

<div>

          <div
            className="testimonial-item-box"
            onClick={() =>
              setVideo(2)
            }
          >
            <img
              alt=""

              src="/assets/videos/partners/partner2Thumb.png"

            />
            <div className="content">
              <div className="fs-16 fw-500 text-white">Bobby Wren</div>
              <div className="fs-12 text-white">President, Patients Choice & Physicians Choice</div>
            </div>
          </div>
            <div className="text-center pt-2">
            “Simplify referrals and real-time updates with centralized system!”
            </div>
</div>
          <div>
          <div
            className="testimonial-item-box"
            onClick={() =>
              setVideo(3)
            }
          >

            <img
              alt=""

              src="/assets/videos/partners/partner3Thumb.png"

            />
            <div className="content">
              <div className="fs-16 fw-500 text-white">Porschia Purvis</div>
              <div className="fs-12 text-white">Case Manager, Patients Choice</div>
            </div>
      
          </div>
          <div className="text-center pt-2">“Streamline home health orders for better patient care.”</div>
          </div>
      
        </div>
      </div>

      <VideoPreview 
          url={v1}
      {...{
       
        width: 400,
        height: 600,
      }} open={currentVideo === 1} onClose={() => setVideo(0)} />
      <VideoPreview 
      
      url={v2}
      {...{
        
        width: 400,
        height: 600,
      }} open={currentVideo === 2} onClose={() => setVideo(0)} />
      <VideoPreview
       url={v3}
        {...{
       
        width: 400,
        height: 600,
      }} open={currentVideo === 3} onClose={() => setVideo(0)} />

    </div>
  );
};

export default Testimonials;
