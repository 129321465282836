import React, { useState, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import { isMobile } from "react-device-detect";

const AreaCharts = ({
  title,
  categoriesData,
  seriesData,
  baseColor,
  lightColor,
}) => {
  const labelColor = "#CBCED7";
  const borderColor = "#EFF2F5";

  const options = {
    // xaxis: {
    //   categories: categoriesData,
    //   axisBorder: {
    //     show: false,
    //   },
    //   axisTicks: {
    //     show: false,
    //   },
    //   labels: {
    //     show: false, // Add this line
    //     style: {
    //       colors: labelColor,
    //       fontSize: "12px",
    //     },
    //   },

    // },

    chart: {
      fontFamily: "inherit",
      type: "area",
      height: isMobile ? 250 : 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: categoriesData,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: isMobile ? "10px" : "12px",
        },
      },
      crosshairs: {
        position: "front",
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      min: 0,
      labels: {
        style: {
          colors: labelColor,
          fontSize: isMobile ? "10px" : "12px",
        },
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return `${val}`;
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  };

  const series = [
    {
      name: "Orders",
      data: seriesData,
    },
  ];

  return (
    <div
      style={{
        boxShadow: "0px 10px 15px #dddddd",
        margin: "20px 10px",
        padding: "10px",
      }}
    >
      <div style={{ display: "flex", margin: "3px 15px" }}>
        <span style={{ fontWeight: "400", fontSize: "16px" }}>{title}</span>
      </div>
      <Chart
        options={options}
        series={series}
        type="area"
        height={isMobile ? 250 : 350}
      />
    </div>
  );
};

export default AreaCharts;
