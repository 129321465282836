import React from "react";
import Wrapper from "../components/Wrapper";

const YourAddress = () => {
  return (
    <Wrapper>
      <section className="booking-process">
        <div className="container">
          <div className="profile-main-box">
            <div className="profile-tabs">
              <ul className="flex align-items-center">
                <li>
                  <a href="#" className="active">
                    Your Address
                  </a>
                </li>
              </ul>
            </div>

            <div className="tabs-info-con your-address-tab">
              <ul>
                <li className="add-new-address">
                  <a href="#">
                    <span className="material-symbols-outlined">add</span>
                    <p>Add address</p>
                  </a>
                </li>

                <li className="added-address flex">
                  <div className="address-icon">
                    <span className="material-symbols-outlined">home</span>
                  </div>
                  <div className="address-info">
                    <p>Home</p>
                    <span>
                      33882 Rowland Dr Fremont, CA-94555, California, USA
                    </span>
                    <div className="address-action flex">
                      <a href="#">Edit</a>
                      <a href="#">Delete</a>
                    </div>
                  </div>
                </li>

                <li className="added-address flex">
                  <div className="address-icon">
                    <span className="material-symbols-outlined">work</span>
                  </div>
                  <div className="address-info">
                    <p>Office</p>
                    <span>
                      33882 Rowland Dr Fremont, CA-94555, California, USA
                    </span>
                    <div className="address-action flex">
                      <a href="#">Edit</a>
                      <a href="#">Delete</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default YourAddress;
