import { Select } from 'antd';
import { useCallback } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

const PaginationUI = ({ total, range, onLimitChange, limit = 10 }) => {
  const options = [
    {
      value: 10,
      label: 10,
    },
    {
      value: 20,
      label: 20,
    },
    {
      value: 30,
      label: 30,
    },
    {
      value: 50,
      label: 50,
    },
  ];

  const onLimitSelect = useCallback(
    (val) => {
      onLimitChange(val);
    },
    [onLimitChange],
  );

  return (
    <div className="d-flex align-items-center">
      {onLimitChange && (
        <div className="d-flex align-items-center ">
          <div className="fs-12 lh-18 fw-500 text-grey-10 mr-1 ">
            Show results on a page
          </div>
          <Select
            defaultValue={limit}
            onChange={onLimitSelect}
            options={options}
            className="limitSelector mr-1"
            suffixIcon={
              <MdKeyboardArrowDown
                className="text-primary d-block"
                style={{ fontSize: 16 }}
              />
            }
          />
        </div>
      )}
      <div className="ml-4 fs-12 mr-2 lh-18 fw-500 text-grey-10">
        {range[0]}-{range[1]} of {total} Records
      </div>
    </div>
  );
};


export default PaginationUI;
