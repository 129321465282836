
import React from 'react'
import Wrapper from '../components/Wrapper'


// import neol from "../assets/images/aboutus/noel.png"

// import Samuel from "../assets/images/aboutus/Samuel.png"

import { Fade } from "react-reveal";
import { FaLinkedin } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { Button } from 'antd'
import { teamMembers } from '../utils/constants'
import { aboutus1, aboutus2, aboutus3, aboutus4, aboutus5, aboutus8, NurturingCulture1, NurturingCulture2, NurturingCulture3 } from '../utils/images'




const AboutUs = () =>  (
        <>
            <Wrapper>
                <div className='container mt-5'>
                    <div className='header text-center'>
                        <span className='text-font'>About Us</span> <br />
                        <span className='text-heading mobile-heading mt-2'>Care with compassion, makes it special.</span>
                        <p className='text-font mobile-para mt-2'>Patient well-being is at our core. We streamline medical journeys with <br className='mob-screen' /> technology, a robust network, and unwavering support.</p>
                    </div>

                    <div className="left mt-5 align-item-center">
                        <Fade left cascade>
                            <div className="d-flex justify-content-center align-items-center mb-4">
                                <div>
                                    <img src={aboutus3} className="mr-2 about-img" alt="" />
                                </div>
                                <div>
                                    <img src={aboutus1} alt="" className="mr-2 about-img" />
                                </div>
                                <div>
                                    <img src={aboutus2} alt="" className='about-last' />
                                </div>
                            </div>
                            <div>
                                <img src={aboutus2} alt="" className='mob-img' />
                            </div>
                        </Fade>
                    </div>

                    <div className='row mt-5 main-desc'>
                        <div className='col-sm-6 mb-2' id='div-desc1'>
                            <Fade left cascade>
                                <div className=''>
                                    <img src={aboutus4} alt="" className="mr-2" />
                                </div>
                            </Fade>
                        </div>

                        <div className='col-sm-6 d-flex flex-column justify-content-center align-items-center' id='div-desc2'>
                            <div className=''>
                                <h3 className='font-regular'> Our Mission </h3>
                                
                                <p className='text-font font-regular'>
                                    To ensure reliability, affordability and convenience <br className='mob-screen' />
                                    become an integral part of the healthcare system. <br className='mob-screen' />
                                    By removing logistical barriers and keeping <br className='mob-screen' />
                                    compassion at the core, we intend to make <br className='mob-screen' /> healthcare access equal and convenient to all.
                                </p>
                            </div>
                        </div>

                        <div className='col-sm-6 mt-5 vision d-flex flex-column justify-content-center align-items-center' id='div-desc3'>
                            <div className=''>
                                <h3> Our Vision </h3>
                                <p className='text-font'>
                                    To upgrade the healthcare ecosystem through <br className='mob-screen' /> innovation and technology that allows custom- <br className='mob-screen' />made healthcare services for every patient. Equal <br className='mob-screen' /> access and less hassle can contribute to topnotch <br className='mob-screen' /> health facilities knocking at your doorstep, whenever <br className='mob-screen' /> and wherever  you want.
                                </p>
                            </div>
                        </div>

                        <div className='col-sm-6 vision' id='div-desc4'>
                            <Fade right cascade>
                                <div className=''>
                                    <img src={aboutus5} alt="" className="mr-2" />
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>

                <div className='container-fluid'>
                    <div className='row mt-5 team-container'>
                        <span className='text-heading mobile-heading text-center mt-5'>Meet the Dynamic Team Behind the Vision</span>
                        {
                         (teamMembers??[]).map((team) => (
                                <>
                                    <div className='col-sm-6 mt-5 d-flex justify-content-center align-items-center'>
                                        <div className='team-avatar'>
                                            <img src={team.pic} className="about-team-avatar" alt="" />
                                        </div>
                                        <div className='team-details'>
                                            <div className='d-flex align-items-center align-items-center'>
                                                <span className='team-name mt-2'>{team?.name}{team.title?`, ${team.title}`:''}
                                                <a href={team.linkedin}
                                                className='ml-1 mt-1'
                                                target="_blank" rel="noreferrer"> <FaLinkedin color='#0077B7' size={17} /> </a>
                                                </span>
                                             
                                            
                                            </div>
                                            <p className='team-disc'>{team.desc}</p>
                                        </div>
                                    </div>

                                </>
                            ))
                        }

                    </div>
                </div> 

                <div className='container mt-2 main-desc'>
                    <div className='row mt-5'>
                        <span className='text-heading mobile-heading text-center'>Nurturing Culture and Team Excellence</span>
                        <div className='col-sm-4 pull-sm-2 text-center mt-4'>
                            <div>
                                <img src={NurturingCulture1} alt="" className='mt-1' />
                                <h6 className='mt-4'>Our Culture: Compassion in Unity</h6>
                                <p>Discover a culture of care and togetherness. At <br /> MedSetGo, we're a close-knit family reshaping <br /> patient well-being.</p>
                            </div>
                        </div>
                        <div className='col-sm-4 pull-sm-1 text-center mt-4'>
                            <div>
                                <img src={NurturingCulture2} alt="" className='mt-1' />
                                <h6 className='mt-4'>Our Team: Experts Powering Change</h6>
                                <p>Meet our diverse experts driving healthcare <br /> transformation. Together, we innovate and <br /> collaborate for a healthier future.</p>
                            </div>
                        </div>
                        <div className='col-sm-4 text-center mt-4'>
                            <div>
                                <img src={NurturingCulture3} alt="" className='mt-1' />
                                <h6 className='mt-4'>Leading Well-being Innovations</h6>
                                <p>Experience MedSetGo's essence – pioneering <br /> care, empathy, and progress, shaping a new <br /> era of health.</p>
                            </div>
                        </div>

                    </div>

                    <div className='row mt-5 about-gradient journey-tag'>
                        <div className='col-sm-6 order-sm-2 pt-5 d-flex flex-column justify-content-center align-items-center' id='div-job1'>
                            <Fade right cascade>
                                <div className="d-flex mb-4 text-center">
                                    <img src={aboutus8} alt="" />
                                </div>
                            </Fade>
                        </div>

                        <div className='col-sm-6 order-sm-1 mt-1 p-2 d-flex align-items-center' id='div-job2'>
                            <div className=''>
                                <span className='text-heading text-left'>Join Our Journey</span>
                                <p className='text-font job-desc'>Ready to make a difference? We're forming a <br /> passionate team to revolutionize home-based <br /> patient care nationwide.</p>
                                <NavLink to="/job-application"><Button className='mt-2' type='primary' >  Apply today  </Button></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    )


export default AboutUs