import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Fade } from "react-reveal";
import { toggleDemoModal } from "../../../features/auth/authSlice";
import { bannerimg12 } from "../../../utils/images";

const Quality = () => {
  const dispatch=useDispatch()
  const openDemoModal = () => {
    dispatch(toggleDemoModal(true));
  };
  return (
    <div className="quality-wrapper">
      <div className="container payors-bg ">
        <div className="content-flex-box" style={{gap:36}}>
            <Fade left cascade>

            
          <div className="left banner-text-box max-w-421">
            <div  className="fs-44 text-primary-light home-mobile-size-label max-w-421">
              Cost Reduction and Quality Improvements for{" "}
              <span className="text-primary">Payors </span>
            </div>
            <p className="text-grey pt-2 mb-5 fs-14">
            Giving family members visibility and control as necessary to help with home care needs
            </p>
            <Button onClick={openDemoModal} type="primary">Know More</Button>
          </div>
          </Fade>
          <div className="right">
          <Fade right cascade>

            <img src={bannerimg12} className="coordination-img" alt="" />
          </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quality;
