import { io } from "socket.io-client";

export const chatURL = () => {
  if (
    window.location.href.includes("staging") ||
    window.location.href.includes("localhost")
  ) {
    return (
      process.env.REACT_APP_SOCKET_URL || "https://api.dev.medsetgo.com:7001/"
    );
  }

  return process.env.REACT_APP_SOCKET_URL || "https://chat.medsetgo.com:7001/";
  // return "https://api.dev.medsetgo.com:7001/";
};

export const socket = io(chatURL(), { autoConnect: false });
