import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

import { toggleDemoModal } from "../../../features/auth/authSlice";
import {
  heathcare1,
  heathcare2,
  heathcare3,
  heathcare4,
} from "../../../utils/images";

const HealthCare = () => {
  const dispatch = useDispatch()
  const openDemoModal = () => {
    dispatch(toggleDemoModal(true));
  };
  return (
    <section className="wrapper">
      <div className="container">
        <div className="health-care-section">
          <div className="text-center fs-40 text-primary-light mb-5 home-mobile-size-label">
            Revolutionizing Healthcare
          </div>
          <div className="content-flex-box d-flex flex-lg-column flex-row">
            <div className="left-- ">
              <div className="d-lg-none d-flex lg-box">
                <img src={heathcare2} className="img2" alt="" />
                <img src={heathcare4} className="img4" alt="" />
              </div>
              <div className="health-care-image-wrapper w-100 ml-auto">


              <div className="fab-flex-column">


                  <div className="item item-1">
                    <img src={heathcare1} className="img1" alt="" />
                  </div>
                  <div className="item item-3">
                    <img src={heathcare3} className="img3" alt="" />
                  </div>



                    </div>
                <div className="fab-flex-column">


                <div className="item item-2">
                    <img src={heathcare2} className="img2" alt="" />
                  </div>
                  


                  <div className="item item-4">
                    <img src={heathcare4} className="img4" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="right-- flex-column">
              <div className="fs-44 text-primary-light home-mobile-size-label max-w-421">
                Pre-Qualified Orders for{" "}
                <span className="text-primary"> Service Partners </span>{" "}
              </div>
              <p className="max-w-421">
                To ensure reliability, affordability and convenience become an
                integral part of the healthcare system. we intend to make
                healthcare access equal and convenient to all.
              </p>
              <Button type="primary" onClick={openDemoModal} className="w-max-content">Know More</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HealthCare;
