import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Fade } from "react-reveal";
import { toggleDemoModal } from "../../../features/auth/authSlice";
import { bannerimg10, bannerimg11, bannerimg9 } from "../../../utils/images";

const PatientAtHome = () => {
  const dispatch=useDispatch()
  const openDemoModal = () => {
    dispatch(toggleDemoModal(true));
  };
  return (
    <div className="patient-at-home-wrapper">
      <div className="container">
        <div className="content-flex-box d-flex align-items-center">
          <div className="left">
            <Fade left cascade>
              <div className="d-flex mb-4 top-row">
                <img src={bannerimg9}   alt="" />
                <img src={bannerimg10} alt="" />
              </div>

              <div className="bottom-img">
                <img src={bannerimg11} alt="" className="w-100" />
              </div>
            </Fade>
          </div>
          <div className="right banner-text-box w-max-421">
            <Fade right cascade>
              <div
                
                className="fs-44 text-primary-light home-mobile-size-label max-w-421"
              >
                Exceptional Care for{" "}
                <span className="text-primary">Patients</span>
              </div>
              <p className="text-grey pt-2 mb-5 fs-14">
                Giving family members visibility and control as necessary to
                help with care needs
              </p>
              <Button onClick={openDemoModal} type="primary">Know More</Button>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientAtHome;
