import { Button, Collapse, Divider, Drawer, Popover } from "antd";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import LoginModal from "../../components/LoginModal";
import { IoReorderThreeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  toggleDemoModal,
  updateLoginModal,
  updateSignModal,
} from "../../features/auth/authSlice";
import DemoScreen from "../DemoScreen";
import WhoWeAre from "./WhoWeAre";
import ResponseMessageModal from "../ResponseMessageModal";

const { Panel } = Collapse;

const PublicNavBar = () => {
  const dispatch = useDispatch();
  const { loginModal, isDemoModal } = useSelector(({ user }) => ({ ...user }));

  const [isMobileNavOpen, setMobileOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);


  const openDemoModal = () => {
    dispatch(toggleDemoModal(true))
  }

  return (
    <div className="w-100 sticky-navbar-wrapper">
      <div className="container">
        <nav className="public-navbar d-flex justify-content-between w-100">
          <Link to={"/"}>
            <img
              src="assets/images/logo-black.svg"
              alt=""
              className="public-nav-logo"
            />
          </Link>
          <div className="right-options">
            <Popover
              // trigger={["hover","click"]}
              placement="bottom"
              getPopupContainer={(n) => n.parentNode}
              overlayClassName="who-we-are-popover"
              overlayInnerStyle={{ maxWidth: 240 }}
              content={<WhoWeAre />}
            >
              <div className="text-black mr-3 d-lg-inline-block d-none cursor-pointer">
                Who We Serve <FiChevronDown />
              </div>
            </Popover>
            <NavLink
              to="/about-us"
              className="text-black mr-3 d-lg-inline-block d-none"
            >
              About Us
            </NavLink>

            <Button
              type="primary"
              className="ml-1 req-demo-btn h-40"
              onClick={openDemoModal}
            >
              Request Demo
            </Button>
            <Button
              type="default"
              className="ml-2 px-5 d-lg-inline-block h-40 d-none cursor-pointer"
              onClick={() => dispatch(updateLoginModal(true))}
            // className="btn btn-outline"
            >
              Login
            </Button>
            <IoReorderThreeOutline
              onClick={() => setMobileOpen(!isMobileNavOpen)}
              size={40}
              className="nav-burger text-grey d-inline-block d-lg-none"
            />
          </div>
        </nav>
      </div>
      <Drawer
        open={isMobileNavOpen}
        placement="right"
        width={280}
        title={<div></div>}
        maskClosable
        //  bodyStyle={{padding:0}}
        onClose={() => setMobileOpen(false)}
        className="nav-drawer"
      
      >
        <div className="fab-d-flex flex-column">
          <Collapse ghost defaultActiveKey={["0"]}>
            <Panel
              showArrow={false}
              header={
                <div className="text-black mr-3">
                  Who We Serve <FiChevronDown />
                </div>
              }
              key="1"
            >
              <WhoWeAre />
            </Panel>
          </Collapse>
          <Divider />
          <NavLink to="/about-us" className="text-black mr-3">
            About Us
          </NavLink>
          <Divider />
          <Button
            type="default"
            className="px-5"
            onClick={() => dispatch(updateLoginModal(true))}
          // className="btn btn-outline"
          >
            Login
          </Button>
        </div>
      </Drawer>
      {isDemoModal && (
        <DemoScreen
          isModalOpen={isDemoModal}
          onClose={() => dispatch(toggleDemoModal(false))}
          setIsAlert={setOpenAlert}
        />
      )}

      {openAlert && (
        <ResponseMessageModal
        openAlert={openAlert}
        setIsAlert={setOpenAlert}
        // setIsDemoScreen={setIsDemoScreen}
        />
      )}

      {loginModal && (
        <LoginModal
          onClose={() => {
            dispatch(updateLoginModal(false));
            dispatch(updateSignModal(false));
          }}
          onRegisterOpen={() => {
            dispatch(updateSignModal(false));
            dispatch(updateLoginModal(true));
          }}
        />
      )}
    </div>
  );
};

export default PublicNavBar;
