import { Form, Input, Modal, Select, Spin ,Result} from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { apiHitWithTempToken, apihitCall } from '../service/authService';
import { authStep1 } from '../features/auth/authService';
import { Notify } from '../blocks/dialogs/Notify';
import { setTempToken, setToken } from '../features/auth/authSlice';

const DemoScreenPage = () => {
    const [formSubmitted, setFormSubmitted] = useState(false); // New state variable

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);
    const [cateLoad, setCateLoad] = useState(false);
    const [category, setCategory] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const generateTempToken = async () => {
        try {
            const res = await authStep1();
            if (res.status === 200) {
                dispatch(setTempToken(res?.data?.access_token));
                getService();
            }
        } catch (error) {}
    };

    useEffect(() => {
        generateTempToken();
    }, []);

    const getService = async () => {
        setCateLoad(true);
        const formData = new FormData();
        formData.append("action", "demo_service_category");
        let res = await apiHitWithTempToken(formData);
        if (res?.status === 200) {
            if (res?.data?.status === 200) {
                setCateLoad(false);
                setCategory(res?.data?.category);
            }
        }
    };

    const openNotification = (message) => {
        Notify("error", message ?? "Oops, something went wrong");
    };

    const onSubmit = async (values) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("action", "request_demo");
        formData.append("appliedposition", values.applied_position);
        formData.append("f_name", values.fname);
        formData.append("l_name", values.lname);
        formData.append("company_name", values.company_name);
        formData.append("email", values.email);
        formData.append("phone", values.phone);
        formData.append("message", values.message);
        let res = await apiHitWithTempToken(formData);
        form.resetFields();
        setLoader(false);
        if (res?.status === 200) {
            setFormSubmitted(true)
            // if (res?.data.status === 200) {
            //     setSuccessMessage('Thank you! Your demo request has been submitted.');
                
            //     form.resetFields();
            // } else {
            //     setErrorMessage(res?.data?.message);
            //     setLoader(false);
            // }
        } else {
            setErrorMessage(res?.data?.message ?? "Oops, something went wrong");
            setLoader(false);
        }
    };

    return (
        <>
            {formSubmitted ? (
        <Result
          status="success"
          title="Thank you!"
          subTitle="Your request has been submitted successfully."
        />
      ):<div style={{padding:"20px"}}>
                <Form form={form} onFinish={onSubmit}>
                    <div className="demo-form">
                        <div>
                            {/* <span className='demo-heading'>Get in touch to set up a demo</span> */}
                            <p className='demo-desc'>Fill out the form to see what Medsetgo can do for your business.</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <div>
                                    <label>Choose Service <span className='text-danger'>*</span></label>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please choose service",
                                            },
                                        ]}
                                        name="applied_position" className="input-container">
                                        <Select
                                            height={200}
                                            options={category.map((item) => ({ label: item.name, value: item.id }))}
                                            required
                                            loading={cateLoad}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div>
                                    <label>First Name<span className='text-danger'>*</span></label>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the first name",
                                            },
                                        ]}
                                        name="fname"
                                        className="input-container">
                                        <Input type="text" className='input-box' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div>
                                    <label>Last Name</label>
                                    <Form.Item
                                        name="lname"
                                        className="input-container">
                                        <Input type="text" className='input-box' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div>
                                    <label>Company Name<span className='text-danger'>*</span></label>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the company name",
                                            },
                                        ]}
                                        name="company_name"
                                        className="input-container">
                                        <Input type="texts" style={{ height: "40px" }} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div>
                                    <label>E-mail<span className='text-danger'>*</span></label>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the email",
                                            },
                                        ]}
                                        name="email"
                                        className="input-container">
                                        <Input type="email" className='input-box' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div>
                                    <label>Phone No<span className='text-danger'>*</span></label>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the phone",
                                            },
                                        ]}
                                        name="phone"
                                        className="input-container">
                                        <Input type="phone" className='input-box' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className='col-12'>
                                <label>Message</label>
                                <div className="form-field">
                                    <Form.Item name="message">
                                        <textarea
                                            style={{ width: "100%" }}
                                            type="text"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        {loading ? <Spin /> : <button className="btn btn-primary " style={{ textTransform: "none" }}>Submit</button>}
                    </div>
                </Form>
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            </div>}
        </>
    );
}

export default DemoScreenPage;
