import { createAsyncThunk } from "@reduxjs/toolkit";
import { apihitCall } from "../../service/authService";

import { setChatData, updateChatLoader } from "./Slice";

export const getChatList = createAsyncThunk(
  "getChatList",
  async (payload, { getState, dispatch }) => {
    // The value we return becomes the `fulfilled` action payload
    try {
      dispatch(updateChatLoader(true));
      const { user } = getState();

      const formData = new FormData();
      formData.append("action", "get_chat_user_for_casemanager");
      formData.append("userId", user?.user?.id);
      formData.append("casemanager", user?.user?.id);
      formData.append("auterizetokenid", user?.user?.id);

      const response = await apihitCall(formData, user?.token);

      if (response?.data?.status === 200) {
        dispatch(setChatData(response?.data?.result ?? []));
        dispatch(updateChatLoader(false));
      } else {
        dispatch(setChatData([]));
        dispatch(updateChatLoader(false));
      }
    } catch (error) {
      dispatch(setChatData([]));
      dispatch(updateChatLoader(false));
    }
  }
);
