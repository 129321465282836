
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';
import { CaseMangerWrapper } from '../../../../Wrapper';

Modal.setAppElement('#root'); // Replace '#root' with the id of your app's root element

const AthenaComponent = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

 
    return (
        <div>
            <CaseMangerWrapper/>
            <button>auth :{code}</button>

           
        </div>
    );
};

export default AthenaComponent;
