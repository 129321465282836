import { Button } from "antd";
import { useDispatch } from "react-redux";
import { Fade, Zoom } from "react-reveal";
import { toggleDemoModal } from "../../../features/auth/authSlice";

const person = "/assets/images/banner/circular-img-person.png";

const Banner = () => {
  const dispatch = useDispatch();

  const openDemoModal = () => {
    dispatch(toggleDemoModal(true));
  };

  return (
    <div className="banner-wrapper w-100">
      <div className="container">
        <div className="banner-content-section">
          <div className="left- d-flex flex-column mx-auto">
            <Fade up cascade>
              <p>Care, in Your Hands.</p>

              <h4 className="home-mobile-size-label">
              Health care coordination in<br/>seconds with AI.
              </h4>

              <p>
                Medical care is confusing. MedSetGo simplifies the booking
                and<br/>delivery of medical services across the care continuum.
              </p>
              <Button
                onClick={openDemoModal}
                className="w-max-content"
                type="primary"
              >
                Request Demo
              </Button>
            </Fade>
          </div>
          <div className="right-">
            <Zoom out>
              <div className="image-group-wrap ">
                <img src={person} alt="" className="person-img" />
              </div>
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
