import { configureStore } from "@reduxjs/toolkit";
import {
  // persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import authSlice from "../features/auth/authSlice";
import caseManagerSlice from "../features/case-manager/caseManagerSlice";
import patientReducer from '../store/patient/Slice'
import GlobalSlice from "../features/global/GlobalSlice";
import service from '../store/AddService/Slice'
import chat from '../store/chat/Slice'

const persistConfig = {
  key: "1.3",
  version: 4.3,
  storage,
  blacklist: ["caseManagerSlice"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user: authSlice,
    caseManagerSlice: caseManagerSlice,
    patient:patientReducer,
    service:service,
    global:GlobalSlice,
    chat:chat
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
