import React from "react";
import { logo,soc,hippa,socimt,attested } from "../../../utils/images";
import { BsHeartFill } from "react-icons/bs";
import { ImFacebook2 } from "react-icons/im";
import { AiFillLinkedin } from "react-icons/ai";
import { TwitterIcon } from "../icons";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";

const Footer = () => {
  return (
    <footer className="medsetgo-footer">
      <div className="container">
        <Fade up cascade>


        <div className="footer-content-box d-flex flex-lg-row flex-column justify-content-between">
          <div className="logo-section">
             <div style={{flexDirection:'column',display:'flex'}}>
             <img style={{height:"100px",width:"200px"}} src={logo} alt="medsetgo" />
              <div style={{display:"flex",marginBottom:"10px"}}>
              <img style={{height:"120px",width:"120px"}} src={socimt} alt="medsetgo" />

             <img style={{height:"141px",width:"140px"}} src={attested} alt="medsetgo" />
             <img style={{height:"120px",width:"200px"}} src={soc} alt="medsetgo" />
             </div>


             </div>
          </div>
         
          <div className="footer-links d-flex">
            <div>
              <label>SOLUTION</label>
              <ul>
                <li> <Link to="/service-partner" className="text-primary-light">Service Partner</Link> </li>
                <li> <Link to="/health-system" className="text-primary-light">Health Systems</Link> </li>
                {/* <li>Patients</li>
                <li>Payor</li> */}
              </ul>
            </div>
            <div>
              <label>COMPANY</label>
              <ul>
                <li><Link className="text-primary-light" to="/about-us"> About Us</Link></li>
       
              
                <li><Link to="/service-partner?contact-us=true" className="text-primary-light">Contact Us</Link>  </li>
              </ul>
            </div>
            <div>
              <label>LEGAL</label>
              <ul>
                <li><Link to="/faq" className="text-primary-light">FAQs</Link></li>
                <li>
                  <Link to="/terms-and-condition" className="text-primary-light">
                    {" "}
                    Term & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" className="text-primary-light">
                    {" "}
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-social-links  ">
            <a
              className="text-primary-light"
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100083614982089"
              rel="noreferrer"
            >
              <ImFacebook2 size={25} color="#323659" />
            </a>
            <a
              className="text-primary-light"
              target="_blank"
              href="https://www.linkedin.com/company/medsetgo/"
              rel="noreferrer"
            >
              <AiFillLinkedin size={32} color="#323659" />
            </a>
            <a
              className="text-primary-light"
              target="_blank"
              href="https://twitter.com/Medsetgo1"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="d-flex footer-bottom-content flex-lg-row flex-column justify-content-between  align-items-start">
            <div className="text-primary-light fs-14 lh-18">
              ©{new Date().getFullYear()} MEDCONNECTS INC All Rights Reserved
            </div>
            <div className="text-primary-light fs-14 lh-18">
              Made with <BsHeartFill color="#e83333" /> from USA
            </div>
          </div>
        </div>
        </Fade>
      </div>
    </footer>
  );
};

export default Footer;
