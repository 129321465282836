import React, { useState } from "react";
import Wrapper from "../components/Wrapper";

const Wallet = () => {
  const [tab, setTab] = useState(1);
  const ReferTab = () => {
    return (
      <div class="tabs-info-con wallet-tab">
        <div class="wallet-banner flex">
          <img src="assets/images/wallet-img.png" alt="" />
          <div class="banner-info">
            <h4>Refer and earn</h4>
            <p>Get 5% off on next order</p>
            <a href="#">REFER NOW</a>
          </div>
        </div>

        <div class="wallet-balance flex align-items-center">
          <p>My Wallet Balance</p>
          <span>$ 500</span>
        </div>

        <div class="wallet-listing">
          <ul>
            <li>
              <img src="assets/images/currency.svg" alt="" />
              <div class="ammount-info">
                <p>
                  Amount Paid <span class="date">27th April, 09:00 PM</span>
                </p>
                <span class="order-id">Paid againt order: 5926908</span>
              </div>
              <div class="total-amount">
                <span>$500</span>
              </div>
            </li>

            <li>
              <img src="assets/images/currency.svg" alt="" />
              <div class="ammount-info">
                <p>
                  Amount Received <span class="date">27th April, 09:00 PM</span>
                </p>
                <span class="order-id">Cashback against order: 5926908</span>
              </div>
              <div class="total-amount">
                <span>$700</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const WalletTab = () => {
    return (
      <div class="tabs-info-con wallet-tab">
        <div class="wallet-banner refer flex">
          <img src="assets/images/refer-img.png" alt="" />
          <div class="banner-info">
            <h4>Good friends deserve good offers</h4>
            <a href="#">REFER NOW</a>
          </div>
        </div>

        <div class="refer-info">
          <p>Refer friends and save together</p>
          <span>
            you both get EXTRA 5% OFF on the next order. hurry, refer now!
          </span>

          <a href="#" class="btn btn-outline">
            Referral code <span>A3uI9x</span>
          </a>
          <span class="copy-code">Click to copy the code</span>
        </div>
      </div>
    );
  };
  return (
    <Wrapper>
      <section class="booking-process">
        <div class="container">
          <div class="profile-main-box">
            <div class="profile-tabs">
              <ul class="flex align-items-center">
                <li>
                  <a
                    onClick={() => setTab(1)}
                    className={`${tab === 1 && "active"}`}
                  >
                    Wallet
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => setTab(2)}
                    className={`${tab === 2 && "active"}`}
                  >
                    Refer & earn
                  </a>
                </li>
              </ul>
            </div>
            {/*  */}
            {tab === 1 ? <WalletTab /> : <ReferTab />}
            {/*  */}
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default Wallet;
