import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ChatPushNotification from "./components/ChatPushNotification";
import { register } from "./serviceWorker";

import './styles/main.scss'
// import "node_modules/video-react/dist/video-react.css"; 
import 'bootstrap/dist/css/bootstrap.min.css'


import "antd/dist/antd.css";  // or 'antd/dist/antd.less'

const container = document.getElementById("root");
const root = createRoot(container);

let persistor = persistStore(store);
const isFCMSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

root.render(

    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        {isFCMSupported()? <ChatPushNotification />:null}
      </PersistGate>
    </Provider>
);

if (isFCMSupported())
register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
