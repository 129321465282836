import React, { useState, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import { isMobile } from "react-device-detect";

const DonutChart = ({ title, labels, seriesData }) => {
  const labelColor = "#CBCED7";
  const borderColor = "#EFF2F5";
  const firstColor = "#F1416C";
  const secondColor = "#FEC700";
  const thirdColor = "#50CD89";
  const fourthColor = "#3366CC";
  const fifthColor = "#FE9900";
  const sixthColor = "#990099";
  const seventhColor = "#0F99C6";
  const eightColor = "#FCFCFC";

  const [colors, setColors] = useState([
    firstColor,
    secondColor,
    thirdColor,
    fourthColor,
    fifthColor,
    sixthColor,
    seventhColor,
    eightColor
  ]);

  const options = {
    labels: labels,
    chart: {
      fontFamily: "inherit",
      type: "donut",
      height: isMobile ? 250 : 350,
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: isMobile ? "25%" : "45%",
        },
      },
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(1) + "%";
      },
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: colors,
    },
    markers: {
      strokeColors: firstColor,
      strokeWidth: 3,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
  };
  const series = seriesData;

  const donutChartLabels = () => {
    return labels?.length ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {labels?.map((label, index) => (
            <React.Fragment key={index}>
              <div
                style={{
                  height: 12,
                  width: 12,
                  borderRadius: 6,
                  backgroundColor: colors[index],
                }}
              />
              <div className="column">
                <span
                  className="text-muted fw-semibold mx-3 text-center"
                  style={{ fontSize: isMobile ? "12px" : "16px" }}
                >
                  {label}
                </span>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    ) : null;
  };

  return (
    <div
      style={{
        boxShadow: "0px 10px 15px #dddddd",
        margin: "20px 10px",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          margin: "3px 15px",
        }}
      >
        <span style={{ fontWeight: "400", fontSize: "16px" }}>{title}</span>

        {!isMobile && <div style={{ width: "60%" }} />}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 10,
          }}
        >
          {donutChartLabels()}
        </div>
      </div>

      {isMobile && <div style={{height: '15px'}} />}

      <Chart
        options={options}
        series={series}
        type="donut"
        height={isMobile ? 250 : 350}
      />
      <div style={{ height: "20px" }} />
    </div>
  );
};

export default DonutChart;
