import PropTypes from "prop-types";
import { connect } from "react-redux";

const ShowForType = (props) => {
  
  if (!props.UserType) return null;

  const couldShow = props.UserType === props.type;
  return couldShow ? props?.children ?? null : null;
};

ShowForType.propTypes = {
  type: PropTypes.string,
  UserType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

ShowForType.defaultProps = {
  type: "All",
  currentWorkspace: null,
};

const mapStateToProps = (state) => ({
  UserType: state.user?.user?.user_type,
});

export default connect(mapStateToProps)(ShowForType);
