import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../components/Wrapper";

const BookingSteps = ({ onClickNext }) => (
  <div className="booking-steps">
    <div className="step-wizard" role="navigation">
      <div className="progress">
        <div className="progressbar empty"></div>
        <div
          id="prog"
          className="progressbar"
          style={{
            width: "33.33%",
          }}
        ></div>
      </div>
      <ul>
        <li className="done">
          <div className="step"></div>
          <div className="title">Patient details</div>
        </li>
        <li className="active">
          <div className="step"></div>
          <div className="title">Patient address</div>
        </li>
        <li className="">
          <div className="step"></div>
          <div className="title">Select time slot</div>
        </li>
        <li className="">
          <div className="step"></div>
          <div className="title">Payment</div>
        </li>
      </ul>
    </div>

    <div className="steps-heading border-bottom-dashed">
      <div className="steps-container">
        <h4 className="add-patient-title">
          <a href="#">
            <img src="assets/images/back-arrow.svg" alt="" />
          </a>{" "}
          Add patient details
        </h4>
        <div className="select-members">
          <h6>Select one member</h6>
          <ul>
            <li className="add-member-icon">
              <a href="#">
                <img src="assets/images/plus-icon.svg" alt="" />
              </a>
              <span>
                ADD
                <br /> New Member
              </span>
            </li>
            <li className="add-member-text color-1">
              <a href="#">SJ</a>
              <span>Scarlette</span>
            </li>
            <li className="add-member-icon color-2">
              <a href="#">
                <img src="assets/images/face-img.png" alt="" />
              </a>
              <span>Sheldon</span>
            </li>
            <li className="add-member-icon color-3">
              <a href="#">JC</a>
              <span>John</span>
            </li>
            <li className="add-member-icon color-4">
              <a href="#">MC</a>
              <span>Monika</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className="patient-detail-form border-bottom-dashed">
      <div className="steps-container">
        <div className="patient-form">
          <div className="form-field">
            <label>First name</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Last name</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Date of birth</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Gender</label>
            <div className="gender-select">
              <label>
                <input type="radio" />
                Male
              </label>

              <label>
                <input type="radio" />
                Female
              </label>

              <label>
                <input type="radio" />
                Other
              </label>
            </div>
          </div>
          <div className="form-field">
            <label>Mobile number</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Email address</label>
            <input type="text" />
          </div>
        </div>
      </div>
    </div>

    <div className="patient-detail-form">
      <div className="steps-container">
        <h6>Insurance Information (Optional)</h6>
        <div className="patient-form">
          <div className="form-field">
            <label>Member ID</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Last name</label>
            <input type="text" />
          </div>
          <div className="form-field">
            <label>Group/Account</label>
            <input type="text" />
          </div>

          <div className="booking-action">
            <button
              onClick={onClickNext}
              className="btn btn-primary full-width"
            >
              Save & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const PatientAddress = ({ onClickNext }) => (
  <div class="booking-steps">
    <div class="step-wizard" role="navigation">
      <div class="progress">
        <div class="progressbar empty"></div>
        <div id="prog" class="progressbar" style={{ width: "33.33%" }}></div>
      </div>
      <ul>
        <li class="done">
          <div class="step"></div>
          <div class="title">Patient details</div>
        </li>
        <li class="active">
          <div class="step"></div>
          <div class="title">Patient address</div>
        </li>
        <li class="">
          <div class="step"></div>
          <div class="title">Select time slot</div>
        </li>
        <li class="">
          <div class="step"></div>
          <div class="title">Payment</div>
        </li>
      </ul>
    </div>

    <div class="steps-heading border-bottom-dashed ">
      <div class="steps-container">
        <h4>
          <a href="#">
            <img src="assets/images/back-arrow.svg" alt="" />
          </a>{" "}
          Add patient details
        </h4>
        <div class="select-members">
          <h6>Select one member</h6>
          <ul>
            <li class="add-member-icon">
              <a href="#">
                <img src="assets/images/plus-icon.svg" alt="" />
              </a>
              <span>
                ADD
                <br /> New Member
              </span>
            </li>
            <li class="add-member-text color-1">
              <a href="#">SJ</a>
              <span>Scarlette</span>
            </li>
            <li class="add-member-icon color-2">
              <a href="#">
                <img src="assets/images/face-img.png" alt="" />
              </a>
              <span>Sheldon</span>
            </li>
            <li class="add-member-icon color-3">
              <a href="#">JC</a>
              <span>John</span>
            </li>
            <li class="add-member-icon color-4">
              <a href="#">MC</a>
              <span>Monika</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="patient-detail-form border-bottom-dashed">
      <div class="steps-container">
        <div class="patient-form">
          <div class="form-field">
            <label>First name</label>
            <input type="text" />
          </div>
          <div class="form-field">
            <label>Last name</label>
            <input type="text" />
          </div>
          <div class="form-field">
            <label>Date of birth</label>
            <input type="text" />
          </div>
          <div class="form-field">
            <label>Gender</label>
            <div class="gender-select">
              <label>
                <input type="radio" />
                Male
              </label>

              <label>
                <input type="radio" />
                Female
              </label>

              <label>
                <input type="radio" />
                Other
              </label>
            </div>
          </div>
          <div class="form-field">
            <label>Mobile number</label>
            <input type="text" />
          </div>
          <div class="form-field">
            <label>Email address</label>
            <input type="text" />
          </div>
        </div>
      </div>
    </div>

    <div class="patient-detail-form">
      <div class="steps-container">
        <h6>Insurance Information (Optional)</h6>
        <div class="patient-form">
          <div class="form-field">
            <label>Member ID</label>
            <input type="text" />
          </div>
          <div class="form-field">
            <label>Last name</label>
            <input type="text" />
          </div>
          <div class="form-field">
            <label>Group/Account</label>
            <input type="text" />
          </div>

          <div class="booking-action">
            <button onClick={onClickNext} class="btn btn-primary full-width">
              Save & Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const BookingCount = () => (
  <div className="booking-payment-box">
    <div className="booking-appointment">
      <h5>
        Booking Dr appointment{" "}
        <a href="#">
          <img src="assets/images/delete-icon.svg" alt="" />
        </a>
      </h5>
      <div className="booking-price-box flex">
        <div className="book-amount">
          <p>
            <del>$50</del> 10% OFF
          </p>
          <span>$45</span>
        </div>
        <div className="add-patient">
          <a href="#">
            <img src="assets/images/minus-icon.svg" alt="" />
          </a>
          <span>1 patient</span>
          <a href="#">
            <img src="assets/images/plus-icon-primary.svg" alt="" />
          </a>
        </div>
      </div>
    </div>

    <div className="apply-coupon flex align-items-center">
      <span className="flex align-items-center">
        <img src="assets/images/percentage-icon.svg" alt="" /> Apply coupon
      </span>
      <a href="#" className="flex align-items-center">
        View offer <img src="assets/images/forward-arrow.svg" alt="" />
      </a>
    </div>

    <div className="boking-total-price">
      <ul>
        <li>
          <p className="flex align-items-center">
            Appointment{" "}
            <span>
              <del>$50</del> $45
            </span>
          </p>
        </li>
        <li>
          <p className="flex align-items-center">
            Charges <span>$5</span>
          </p>
        </li>
      </ul>

      <div className="total-amount flex">
        <p className="flex align-items-center">
          Amount payable <span>$45</span>
        </p>
      </div>
    </div>
  </div>
);

const PatientMapDetail = ({ onClickNext }) => {
  return (
    <div class="booking-steps">
      <div class="step-wizard" role="navigation">
        <div class="progress">
          <div class="progressbar empty"></div>
          <div id="prog" class="progressbar" style={{ width: "33.33%" }}></div>
        </div>
        <ul>
          <li class="done">
            <div class="step"></div>
            <div class="title">Patient details</div>
          </li>
          <li class="active">
            <div class="step"></div>
            <div class="title">Patient address</div>
          </li>
          <li class="">
            <div class="step"></div>
            <div class="title">Select time slot</div>
          </li>
          <li class="">
            <div class="step"></div>
            <div class="title">Payment</div>
          </li>
        </ul>
      </div>

      <div class="steps-heading border-bottom-dashed">
        <div class="steps-container">
          <h4>
            <a href="#">
              <img src="assets/images/back-arrow.svg" alt="" />
            </a>{" "}
            Select Patient address
          </h4>

          <div class="map-location">
            <img src="assets/images/map.png" alt="" />
          </div>

          <div class="location-list selected-location">
            <ul>
              <li>
                <img src="assets/images/select-location.svg" alt="" />
                <div class="location-info">
                  <p>33882 Rowland Dr Fremont, CA-94555, California, USA</p>
                  <button class="btn btn-outline">Change</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="patient-detail-form">
        <div class="steps-container">
          <div class="patient-form">
            <div class="form-field">
              <label>Zipcode</label>
              <input type="text" />
            </div>
            <div class="form-field">
              <label>House number and building</label>
              <input type="text" />
            </div>
            <div class="form-field">
              <label>Street name</label>
              <input type="text" />
            </div>

            <div class="select-street">
              <ul class="flex align-items-center">
                <li class="flex align-items-center active">
                  <span class="material-symbols-outlined">home</span>
                  Home
                </li>
                <li class="flex align-items-center">
                  <span class="material-symbols-outlined">work</span>
                  Office
                </li>
                <li class="flex align-items-center">
                  <span class="material-symbols-outlined">public</span>
                  Other
                </li>
              </ul>
            </div>

            <div class="forget-link agree-terrms">
              <label>
                <input type="checkbox" />
                save the address for future use
              </label>
            </div>

            <div class="booking-action">
              <button onClick={onClickNext} class="btn btn-primary full-width">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectTime = ({ onClickNext }) => {
  return (
    <div class="booking-steps">
      <div class="step-wizard" role="navigation">
        <div class="progress">
          <div class="progressbar empty"></div>
          <div id="prog" class="progressbar" style={{ width: "66.33%" }}></div>
        </div>
        <ul>
          <li class="done">
            <div class="step"></div>
            <div class="title">Patient details</div>
          </li>
          <li class="done">
            <div class="step"></div>
            <div class="title">Patient address</div>
          </li>
          <li class="active">
            <div class="step"></div>
            <div class="title">Select time slot</div>
          </li>
          <li class="">
            <div class="step"></div>
            <div class="title">Payment</div>
          </li>
        </ul>
      </div>

      <div class="steps-heading">
        <div class="steps-container">
          <h4>
            <a href="#">
              <img src="assets/images/back-arrow.svg" alt="" />
            </a>{" "}
            Select time slot
          </h4>
          {/* <div class="flex align-items-center select-time-slot">
            <span class="slot-arrow">
              <span class="material-symbols-outlined">keyboard_arrow_left</span>
            </span>
            <ul class="flex align-items-center">
              <li>
                <a href="#">30th Apr</a>
              </li>
              <li>
                <a href="#" class="active">
                  1st May
                </a>
              </li>
              <li>
                <a href="#">2nd May</a>
              </li>
            </ul>
            <span class="slot-arrow">
              <span class="material-symbols-outlined">
                keyboard_arrow_right
              </span>
            </span>
          </div> */}
        </div>
      </div>

      <div class="availble-timing-slots">
        <div class="steps-container">
          <h4>Sun, 1st May</h4>

          <div class="slots-timings">
            <h5>
              <span>Morning</span>
            </h5>
            <ul class="flex align-items-center">
              <li>
                <a href="#" class="active">
                  <span>06:00 AM-07:00 AM</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>07:00 AM-08:00 AM</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>08:00 AM-09:00 AM</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>09:00 AM-10:00 AM</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>10:00 AM-11:00 AM</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>11:00 AM-12:00 PM</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="slots-timings">
            <h5>
              <span>Afternoon</span>
            </h5>
            <ul class="flex align-items-center">
              <li>
                <a href="#">
                  <span>12:00 PM-01:00 PM</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>01:00 PM-02:00 PM</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>02:00 PM-03:00 PM</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="slots-timings">
            <h5>
              <span>Evening</span>
            </h5>
            <ul class="flex align-items-center">
              <li>
                <a href="#">
                  <span>04:00 PM-05:00 PM</span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span>05:00 PM-06:00 PM</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="booking-action slots-button">
            <button onClick={onClickNext} class="btn btn-primary full-width">
              Pay NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const BookingPage = () => {
  const [steps, SetSteps] = useState(0);
  const navigation = useNavigate();

  const renderSteps = () => {
    switch (steps) {
      case 0:
        return <BookingSteps onClickNext={() => SetSteps(1)} />;
      case 1:
        return <PatientAddress onClickNext={() => SetSteps(2)} />;
      case 2:
        return <PatientMapDetail onClickNext={() => SetSteps(3)} />;
      case 3:
        return (
          <SelectTime onClickNext={() => navigation("/booking-profile")} />
        );
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <section className="booking-process">
        <div className="container">
          <div className="booking-process-main-box flex">
            {renderSteps()}
            <BookingCount />
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default BookingPage;
