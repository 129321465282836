import moment from "moment";
import { useSelector } from "react-redux";
import { userTypes } from "./constants";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';


export const makeAndsaveExcelFile = (header,data) => {
  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [header]);
  XLSX.utils.sheet_add_json(ws, data, {origin: 'A2', skipHeader: true});
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
    
  const finalData = new Blob([excelBuffer], {type: 'text/csv;charset=UTF-8'});
  FileSaver.saveAs(finalData, "Data.csv");
}
export const debounce = (func, timeOut = 400) => {
  let timer;
  return (...args) => {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeOut);
  };
};

export const getCurrentStatus = (status) => {
  switch (Number(status)) {
    case 0:
      return {
        isServiceAssigned: false,
        isServiceInProgress: false,
        isServiceCompleted: false,
      };
    case 1:
      return {
        isServiceAssigned: true,
        isServiceInProgress: false,
        isServiceCompleted: false,
      };
    case 4:
      return {
        isServiceAssigned: true,
        isServiceInProgress: true,
        isServiceCompleted: false,
      };
    case 6:
      return {
        isServiceAssigned: true,
        isServiceInProgress: true,
        isServiceCompleted: true,
      };
    default:
      return {
        isServiceAssigned: true,
        isServiceInProgress: false,
        isServiceCompleted: false,
      };
  }
};

export const handleDate = (date) => {
  let m = moment(date, ["YYYY-MM-DD", "DD-MM-YYYY"], true);
  if (m.isValid()) {
    return m.format("MM-DD-YYYY");
  }

  return date;
};

export const generateOne = (max) => {
  const u = Array(max ?? 1).fill("1");
  return u.join();
};

//   client_id: isLiveUrl ? 'MedSetGo' : 'MedSetDev',
// client_secret: isLiveUrl
//   ? '0c95f7f4ce520fdcd6a2c4abb16211c072acb2522d71068b3ceee25d76df6bef'
//   : '4df97833efe0c4be42e9e35b7d23df60bf78bf5c10d691cc95af95ab6352e049',
  //  window.location.href.includes("localhost")||

export const getClient_id = () => {
  if (
    window.location.href.includes("staging") ||
  //  window.location.href.includes("localhost") ||
    //window.location.href.includes("192.168") ||
    window.location.href.includes("dev")
  ) {
    return "MedSetDev";
  }
  return "MedSetGo";
};

export const getClientSecret = () => {
  if (
    window.location.href.includes("staging") ||
    //window.location.href.includes("localhost")  ||
    //window.location.href.includes("192.168") ||
    window.location.href.includes("dev")
  ) {
    return "4df97833efe0c4be42e9e35b7d23df60bf78bf5c10d691cc95af95ab6352e049";
  }
  return "0c95f7f4ce520fdcd6a2c4abb16211c072acb2522d71068b3ceee25d76df6bef";
};

export const getErrorMessage = (err, defaultMsg) =>
  (err.response && err.response.data && err.response.data.message) ||
  err.message ||
  defaultMsg;


  const rand = () => {
    return Math.random().toString(36).substr(2); // remove `0.`
  };
  
  export const generateToken = () => {
    return rand() + rand(); // to make it longer
  };
  

  export const isDoctor = (user) =>{

    if(user.user_type === userTypes.DOCTOR) {
         return true;
    }

    return false;
  }
  