import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  MedBannerSection,
  MedFeatureSection,
  ServiceBanner,
} from "../components/BannerSection";
import TestimonialComponent, {TeamComponent} from "../components/TestimonialComponent";
import Wrapper from "../components/Wrapper";
import { getServiceAction } from "../features/auth/authSlice";
import { apihitCall } from "../service/authService";
import "./styles/index.css";
const ServicePage = () => {
  const navigation = useNavigate();
  const { service } = useSelector(({ user }) => user);
  const [serviceData, setServiceData] = useState([]);
  const dispatch = useDispatch();
  useMemo(() => {
    dispatch(getServiceAction());
  }, []);
  useEffect(() => {
    setServiceData(service?.data ?? []);
  }, [service]);

  return (
    <Wrapper>
      {serviceData.map((i) => {
        return (
          <ServiceBanner
            title={i?.category_name}
            imageSrc={i?.category_image}
            desc={i?.category_description}
            onClick={() => navigation(`/services/${i?.category_id}`)}
          />
        );
      })}
    </Wrapper>
  );
};

export const ServiceDetail = () => {
  const params = useParams();
  const { tempToken } = useSelector(({ user }) => user);
  // const [currentService, setCurrentService] = useState(service?.data ?? []).filter(
  //     (i) => i?.category_id === params?.serviceId
  // );
  const [currentService, setCurrentService] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  useEffect(() => {
    const getDetail = async () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      try {
        const formData = new FormData();
        formData.append("action", "all_subcategory_by_city");
        formData.append("category_id", params?.serviceId);
        formData.append("cityId", "Fremont");
        const response = await apihitCall(formData, tempToken);

        setSubCategory(response.data?.result ?? []);
        (response.data?.result??[]).map((i)=>{
          if(params?.serviceId===i.category_id){
            setCurrentService(i);
          }
        })
      } catch (error) {

      }
    };
    getDetail();
    
  }, []);

  return (
    <Wrapper>
      <ServiceBanner
        title={currentService?.category_name}
        imageSrc={currentService?.catImageParent}
        desc={currentService?.short_description}
        onClick={() => true}
      />
      {/* Product List START*/}
      <section className="product-list">
        <div className="container">
          <h5>Service</h5>
          <div className="product-slider">
            {subCategory.length > 0 ? (
              subCategory.map((i,k) => (
                <div className="product-box-info" key={k}>
                  <div className="product-info-top flex">
                    <div className="product-img flex align-items-center justify-center">
                      <img src={i?.category_image} alt="" />
                    </div>
                    <div className="product-pricing flex">
                      <p>{i?.sub_category_name ?? "No title"}</p>
                      {/*<span>${i?.sub_actual_price ?? "-"}</span>*/}
                      <button
                        // onClick={() => navigation("/booking")}
                        type="button"
                        className="btn btn-outline small"
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                  <div className="product-desc">
                    {/*{JSON.stringify(i)}*/}
                    <p
                        style={{
                          height: 130,
                          overflowY: 'scroll'
                    }}
                      dangerouslySetInnerHTML={{
                        __html: i?.description ?? "No Detail",
                      }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="product-detail-info">
                <p>No Detail found.</p>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* Product List END*/}

      <section className="product-details">
        <div className="container">
          <h5>Service details</h5>
          <div className="product-detail-info">
            <p
              dangerouslySetInnerHTML={{
                __html: currentService?.parentdescirption,
              }}
            />
          </div>
        </div>
      </section>
      {/*<MedFeatureSection />*/}
      <TestimonialComponent />
      <MedBannerSection />
    </Wrapper>
  );
};

export default ServicePage;
