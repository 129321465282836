import { Modal } from "antd";
import { Player ,BigPlayButton } from "video-react";

const VideoPreview = ({ onClose, url, width = 300, open, height = 500 }) =>
  !open ? (
    <></>
  ) : (
    <Modal
      open={open}
      onCancel={onClose}
      centered
      width={width}
      maskStyle={{ background: "#000000b0" }}
      className="video-preview-modal"
      footer={null}
      title={null}
      bodyStyle={{ padding: 0, borderRadius: 12 }}
    >
      <div className="mx-auto">
        <Player fluid playsInline autoPlay src={url}  >
          <BigPlayButton position="center"/>
        </Player>
      </div>
    </Modal>
  );

export default VideoPreview;
