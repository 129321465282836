// api-client.js
import axios from "axios";
import { store } from "../app/store";
import { logout } from "../features/auth/authSlice";

const BASE_URL = `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_VERSION}/`;

const instance = axios.create({
  baseURL: BASE_URL,
});

const instanceForTempToken = axios.create({
  baseURL: BASE_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const conf = config;
    return conf;
  },
  // Do something before request is sent
  (error) =>
    // Do something with request error
    Promise.reject(error)
);
instance.interceptors.request.use(
  (config) => {
    const conf = config;
    if (store.getState().user.token) {
    
      const token = `Bearer ${store.getState().user.token}`;
      conf.headers.Authorization = token;
         
    }
    return conf;
  },
  // Do something before request is sent
  (error) =>
    // Do something with request error
    Promise.reject(error),
);


instanceForTempToken.interceptors.request.use(
  (config) => {
    const conf = config;

    if (store.getState().user.tempToken) {
    
      const token = `Bearer ${store.getState().user.tempToken}`;
      conf.headers.Authorization = token;

    }
  
    return conf;
  },
  // Do something before request is sent
  (error) =>
    // Do something with request error
    Promise.reject(error),
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Do something with response data

    if (response?.data?.status === 401) {
      store.dispatch(logout());
    } else {
      return response;
    }
  },
  (error) => {
    // Do something with response error
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 422)
    ) {
      store.dispatch(logout({ status: error.response.status }));
    }

    return Promise.reject(error);
  }
);

export const COMETCHAT_CONSTANTS = {
  APP_ID: process.env.REACT_APP_CHAT_APP_ID,
  REGION: process.env.REACT_APP_CHAT_REGION,
  AUTH_KEY: process.env.REACT_APP_CHAT_AUTH_KEY,
};

export {instanceForTempToken as tempTokenAxios}
export default instance;
