import React, {  useEffect,  useState } from "react";
import {  useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CaseMangerWrapper } from "../../../components/Wrapper";
import { Button, Table, message,  } from "antd";
import { APIHitCall } from '../../../features/case-manager/caseManagerService';

import {   AiOutlinePlusCircle } from "react-icons/ai";
import { NoData } from "../../../components/common/Nodata/index";
import './SurveyTable.css'; 


import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import moment from "moment";
import { makeAndsaveExcelFile } from "../../../utils/functions";


export const SurveySubmissions = () => {
  const {id}=useParams()
  const [isExporting, setIsExporting] = useState(false);

  const navigation = useNavigate();
  const [surveyData, setsurveyData] = useState([]);
  const [headerData, setheaderData] = useState([]);

  const [loading, setLoading] = useState(false);

 
  const {  user } = useSelector(({  user }) => ({
   
    ...user,
  }));


  function SurveyTable() {
    return (
      <div id="table-container">
        <table>
          <thead>
            <tr>
              <th><span style={{fontSize:'13px'}}>#</span></th>
              <th><span style={{fontSize:'13px'}}>Patient Name</span></th>
              {headerData.map((headerItem, index) => (
                <th key={index}><span style={{fontSize:'13px'}}>{headerItem.question}</span></th>
              ))}
            </tr>
          </thead>
          <tbody>
            {surveyData.map((resultItem, index) => (
              <tr key={index}>
                <td><span style={{fontSize:'12px'}}>{index + 1}</span></td>
                <td><span style={{fontSize:'12px'}}>{resultItem.pt_fname + ' ' + resultItem.pt_lname}</span></td>
                {resultItem.surveySunmission[0].surveyQuestion.map((questionItem, qIndex) => (
                  <td key={qIndex}><span style={{fontSize:'12px'}}>{questionItem.answer.trim()}</span></td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  
  
  
  

  async function getSurvey() {
    setLoading(true);
    const formData = new FormData();

    formData.append("action", "get_clinic_survey_submission");

    formData.append("clinicId", user?.society_id)

    formData.append("surveyId",id);

    formData.append("auterizetokenid", user?.id);
  
    let res = await APIHitCall(formData);
    setLoading(false);
    if (res.data.status === 200) {
       
        setsurveyData(res.data.result)
        setheaderData(res.data.header)

    }else{
        setLoading(false);
    }
   
}

 

 
  useEffect(()=>{
     getSurvey();
  },[])

 
  
  const exportData = () => {
// Prepare header
const header = ['Patient Name'].concat(headerData.map(item => item.question));

// Prepare data
const data = surveyData.map(resultItem => {
 
  const patientName = resultItem.pt_fname + ' ' + resultItem.pt_lname;
  const answers = resultItem.surveySunmission[0].surveyQuestion.map(questionItem => questionItem.answer.trim());
  return [patientName].concat(answers);
});
makeAndsaveExcelFile(header, data);
  setIsExporting(false);

  }

  return (
    <div style={styles.main}>
      <CaseMangerWrapper>
        <section className="booking-process">
          <div style={{margin:'18px'}}>
            <div className="data-table-main-box">
              <div className="datatable-list-head appointments-list">
                <div className="datatable-list-head-title">
                  Survey Submissions
                </div>

              
                <div
                  className="d-flex flex-align-items-center"
                  style={{ gap: "10px" }}
                >
                 
                 <Button
                    onClick={() => {
                      if (!isExporting) {
                        setIsExporting(true);
                        exportData();
                      }
                    }}
                    type="primary"
                  >
                    {isExporting ? "Exporting..." : "Export"}
                  </Button>

                
                </div>
              </div>

              <div className="data-table-contain">
                 {headerData.length ?<SurveyTable />:  <div style={{height:300}}
    className='d-flex w-100 justify-content-center align-items-center'
    >No Submissions found</div>}
                {/* <Table
                 
                  locale={{ emptyText: <NoData /> }}
                  pagination={{
                    responsive: true,
                    hideOnSinglePage: false,
                 
                   
                   
                  }}
                  columns={columns}
                  dataSource={surveyData ?? []}
                  selectableRowsVisibleOnly
                  onRow={(row) => ({
                    onClick: () => {
                    
                    },
                  })}
                /> */}
              </div>
            </div>
          </div>
        </section>

       

      
      </CaseMangerWrapper>
     
    </div>
  );
};
const buttonStyle = {
  backgroundColor: "#4CAF50", /* Green */
  

  color: "white",
  padding: "8px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "10px",
  margin: "4px 2px",
  cursor: "pointer",
  borderRadius:'14px',
  border:"none",
};
