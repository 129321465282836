import React, { useEffect, useState } from "react";
import { CaseMangerWrapper } from "../../../components/Wrapper";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
  
import { APIHitCall } from "../../../features/case-manager/caseManagerService";
import { useDispatch, useSelector } from "react-redux";
import DonutChart from "../../../components/charts/DonutChart";
//import AreaChart from "../../../components/charts/Areachart";
import AreaChart from "../../../components/charts/AreaCharts";
const ChartComponent = () => {
  const { user } = useSelector(({ user }) => ({ ...user }));
  const [completedCategoriesData, setCompletedCategoriesData] = useState([]);
  const [completedSeriesData, setCompletedSeriesData] = useState([]);
  const [createdCategoriesData, setCreatedCategoriesData] = useState([]);
  const [createdSeriesData, setCreatedSeriesData] = useState([]);
  const [orderByCategoryLabel, setOrderByCategoryLabel] = useState([]);
  const [orderByCategorySeriesData, setOrderByCategorySeriesData] = useState(
    []
  );
  const [orderByProviderLabel, setOrderByProviderLabel] = useState([]);
  const [orderByProviderSeriesData, setOrderByProviderSeriesData] = useState(
    []
  );
  const [orderByPartnerLabel, setOrderByPartnerLabel] = useState([]);
  const [orderByPartnerSeriesData, setOrderByPartnerSeriesData] = useState([]);

  useEffect(() => {
    fetchCompletedOrderData();
  }, []);

  useEffect(() => {
    get_analytics_order_by_category();
  }, []);

  useEffect(() => {
    get_order_by_provider();
  }, []);

  useEffect(() => {
    get_order_by_partner();
  }, []);

  async function fetchCompletedOrderData() {
    try {
      const formData = new FormData();
      formData.append("action", "analytics_order_by_date");
      formData.append("casemanaderid", user?.id);
      formData.append("auterizetokenid", user?.id);
      const response = await APIHitCall(formData);
      if (response?.data?.status === 200) {
        if (response.data.completed.length) {
          const newChartData = response.data.completed.slice(0, 8);
          const dates = newChartData.map((item) => item.schedule_date);
          const counts = newChartData.map((item) =>
            Number(item.totalcompletecount)
          );

          setCompletedCategoriesData(dates);
          setCompletedSeriesData(counts);
        }

        if (response.data.created.length) {
          const newChartData = response.data.created.slice(0, 8);
          const dates = newChartData.map((item) => item.schedule_date);
          const counts = newChartData.map((item) => Number(item.totalcount));

          setCreatedCategoriesData(dates);
          setCreatedSeriesData(counts);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function get_analytics_order_by_category() {
    try {
      const FD = new FormData();
      FD.append("action", "analytics_order_by_category");
      FD.append("casemanaderid", user?.id);
      FD.append("auterizetokenid", user?.id);
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        const newChartData = res.data.result;
        const labels = newChartData.map((item) => item.name);
        const counts = newChartData.map((item) => Number(item.catgeory_count));

        setOrderByCategoryLabel(labels);
        setOrderByCategorySeriesData(counts);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function get_order_by_provider() {
    try {
      const formData = new FormData();
      formData.append("action", "analytics_order_by_provider");
      formData.append("casemanaderid", user?.id);
      formData.append("auterizetokenid", user?.id);
      const response = await APIHitCall(formData);
      if (response?.data?.status === 200) {
        const newChartData = response.data.result;
        const labels = newChartData.map((item) => item.name);
        const counts = newChartData.map((item) =>
          Number(item.clinicordercount)
        );

        setOrderByProviderLabel(labels);
        setOrderByProviderSeriesData(counts);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function get_order_by_partner() {
    try {
      const formData = new FormData();
      formData.append("action", "analytics_order_by_partner");
      formData.append("casemanaderid", user?.id);
      formData.append("auterizetokenid", user?.id);
      const response = await APIHitCall(formData);
      if (response?.data?.status === 200) {
        const newChartData = response.data.result;
        const labels = newChartData.map((item) => item.partner);
        const counts = newChartData.map((item) =>
          Number(item.partnerordercount)
        );

        setOrderByPartnerLabel(labels);
        setOrderByPartnerSeriesData(counts);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div style={styles.main}>
      <CaseMangerWrapper>
        <div className="div" style={{ margin: "10px 20px 10px 20px" }}>
          <AreaChart
            title={"Completed Orders"}
            categoriesData={completedCategoriesData}
            seriesData={completedSeriesData}
            baseColor={'#50CD89'}
            lightColor={'#E8FFF3'}
          />

          <DonutChart
            title={"Order By Category"}
            labels={orderByCategoryLabel}
            seriesData={orderByCategorySeriesData}
          />

          <DonutChart
            title={"Order By Provider"}
            labels={orderByProviderLabel}
            seriesData={orderByProviderSeriesData}
          />

          <DonutChart
            title={"Order By Partner"}
            labels={orderByPartnerLabel}
            seriesData={orderByPartnerSeriesData}
          />

          <AreaChart
            title={"Created Orders"}
            categoriesData={createdCategoriesData}
            seriesData={createdSeriesData}
            baseColor={'#FDC700'}
            lightColor={'#FFF8DD'}
          />

          <div style={{height: '20px'}} />
        </div>
      </CaseMangerWrapper>
    </div>
  );
};

export default ChartComponent;