import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { APIHitCall, getPatientListService } from "./caseManagerService";
import { get } from "lodash";

const initialState = {
  patient: {
    loading: true,
    data: [],
    totalRows : 0
  },
  appointment: {
    loading: false,
    data: [],
    count: 0,
  },
  doctors: {
    loading: false,
    data: [],
  },
};

export const getPatientListAsync = createAsyncThunk(
  "getPatientList",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    
    const { user } = getState();

    const formData = new FormData();
    formData.append("action", "get_patient_list");
    formData.append("societyid", user?.user?.society_id);
    formData.append("casemanager", user?.user?.id);
    formData.append("auterizetokenid", user?.user?.id);
    formData.append("societyId", user?.user?.society_id);

    if (payload.page !== undefined && payload.page !== null) {
      formData.append("page_start", payload.page);
      formData.append("keyword", payload.search);
    } else if (payload) {
      formData.append("keyword", payload);
    }
    
     
    const response = await getPatientListService(formData, user?.token);
    return response.data;
  }
);

export const getPatientListBySearch = createAsyncThunk(
  "getPatientListBySearch",
  async (payload, { getState }) => {
    const { user } = getState();

    const response = await getPatientListService(payload, user?.token);

    return response.data;
  }
);

export const getAppointments = createAsyncThunk(
  "getAppointment",
  async (Fd, { getState }) => {
  
    try {
      const response = await APIHitCall(Fd);
      
      return {
        data: response?.data?.result ?? [],
        count: response?.data?.count || 0,
      };
    } catch (error) {
      return {};
    }
  }
);

export const updateAppointment = createAsyncThunk(
  "updateAppointment",
  async (data, { getState }) => {
  
    try {
    //  getState.appointment.data = data?.data ?? [];
      
      return {
        data: data?.data,
        count:  0,
      };
    } catch (error) {
      return {};
    }
  }
);







export const caseManagerSlice = createSlice({
  name: "caseManager",
  initialState,
  reducers: {
   
    // other reducers...

  },
  extraReducers: (builder) => {
    builder
    
    .addCase(updateAppointment.fulfilled, (state, action) => {
      state.appointment.data = action.payload.data ?? [];
    })
      .addCase(getPatientListAsync.pending, (state) => {
        state.patient.loading = true;
      })
      .addCase(getPatientListAsync.fulfilled, (state, action) => {
        state.patient.loading = false;
        state.patient.data = action?.payload?.result;
        state.patient.patientcount = action?.payload?.patientcount ??0;
      })
      .addCase(getPatientListBySearch.fulfilled, (state, action) => {
        state.patient.loading = false;
        state.patient.data = action?.payload?.result;
      })
      .addCase(getAppointments.pending, (state, payload) => {
        state.appointment.loading = true;
      })
      .addCase(getAppointments.fulfilled, (state, action) => {
        state.appointment.loading = false;
        state.appointment.data = action?.payload?.data ?? [];
        state.appointment.count = action?.payload?.count ?? 0;
      })
      .addCase(getProviders.pending, (state, action) => {
        state.doctors.loading = true;
      })
      .addCase(getProviders.fulfilled, (state, action) => {
        state.doctors.loading = false;
        state.doctors.data = action.payload ?? [];
      });
  },
});

export const searchPatients = createAsyncThunk(
  "searchPatients",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    formData.append("action", "get_patient_list");
    formData.append("casemanager", user?.user?.id);
    formData.append("auterizetokenid", user?.user?.id);
    const response = await APIHitCall(formData, user?.token);
    return response.data;
  }
);

export const getProviders = createAsyncThunk(
  "getProviders",
  async (payload, { getState }) => {
    try {
      const { user } = getState();
      const formData = new FormData();
      formData.append("action", "get_doctor_list");
      formData.append("societyid", user?.user?.society_id);
      formData.append("auterizetokenid", user?.user?.id);
      formData.append("page_start", 0);
      const response = await APIHitCall(formData, user?.token);
      return response?.data?.result ?? [];
    } catch (error) {
      return [];
    }
    // The value we return becomes the `fulfilled` action payload
  }
);


export const getTimeSlot = createAsyncThunk(
  "getTimeSlot",
  async (payload, { getState }) => {
    // The value we return becomes the `fulfilled` action payload
    const { user } = getState();
    const formData = new FormData();
    formData.append("action", "get_timeslot_byservice");
    formData.append("serviceId", 0);
    formData.append("auterizetokenid", user.user.id);
    const response = await APIHitCall(formData, user?.token);
    return response.data;
  }
);

export default caseManagerSlice.reducer;
