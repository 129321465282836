import axios from "./../../config/api-client";


const PATH = {
    main: "main.php",
  };
  

export const postApi = (payload, token) =>
   axios.post(PATH.main, payload);

  export const getUsers = (payload, token) =>
   axios.post(PATH.main, payload);

  