import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "../../../utils/functions";
import { CaseMangerWrapper } from "../../../components/Wrapper";
import { getAppointments, updateAppointment } from "../../../features/case-manager/caseManagerSlice";
import { Button, Dropdown, Input, Menu, message, Table, Tooltip } from "antd";
import ".././style.css";
import { FiSearch } from "react-icons/fi";
import {  AiOutlineInfoCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { NoData } from "../../../components/common/Nodata/index";
import { RedoOutlined } from '@ant-design/icons';
import { ReloadOutlined } from '@ant-design/icons'; // Different icon

import {
  initialServiceDetailval,
  setServiceData,
  updateServiceStep,
} from "../../../store/AddService/Slice";
import { patientTabs, serviceSteps } from "../../../utils/constants";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import moment from "moment";
import PaginationUI from "../../../components/common/pagination";
import { BsChatLeftTextFill, BsImages } from "react-icons/bs";
import { setCurrentChat } from "../../../store/chat/Slice";
import { APIHitCall } from "../../../features/case-manager/caseManagerService";
import { makeAndsaveExcelFile } from "../../../utils/functions";
import FilterOptionsDropdown from "../filter";
import { FaGears } from "react-icons/fa6";
import AttachmentsView from "../../../components/common/drawer/AttachmentsView";
import UpdateOrder from "../../../components/common/modals/UpdateOrder";
import CustomTooltipMedset from "../../../components/common/modals/CustomTooltipMedset";
import { setMainPatientTab } from "../../../store/patient/Slice";
import { BiInfoCircle } from "react-icons/bi";
import CaseManagerListForChat from "./casemanagerListForChat";
import { isMobile } from "react-device-detect";
import "./outgoing.css"
import { FaDownload, FaFileDownload } from "react-icons/fa";




export const DoctorReferralPageOutgoing = () => {
  const status = "1";
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [isAttchments, setIsAttachments] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isCaseManagerList, setIsCaseManagerList] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [doctorId, setdoctorId] = useState("");
  const [clinicId, setClinicId] = useState("");
  const [referralStatusId, setReferralStatusId] = useState("all");
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [servicePartnerId, setservicePartnerId] = useState("");
  const [serviceTypeId, setserviceTypeId] = useState("");
  const [isUpdate, setUpdatedata] = useState(null);
  const [caseMlist, setCaseMlist] = useState(null);

  const [isExporting, setIsExporting] = useState(false);
  
  
  const [search, setSearch] = useState("");
  const { appointment, user } = useSelector(({ caseManagerSlice, user }) => ({
    ...caseManagerSlice,
    ...user,
  }));
  
  const getListData = (FD) => {
    dispatch(getAppointments(FD));
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceApicall = useCallback(debounce(getListData), []);

  const exportData = () => {
    if (!appointment || !appointment.data || appointment.data.length === 0) {
      return;
    }
    let header = ["OrderId", "Patient Name", "Date", "From Clinic", "From Doctor","To Clinic","To Doctor"];
    getExportData();
    async function getExportData() {
      setIsExporting(true);
      try {
        const FD = new FormData();
        FD.append("action", "get_doctor_referral");
        FD.append("societyId", user?.society_id);
        FD.append("status", status);
        FD.append("auterizetokenid", user?.id);
        FD.append("limit", 500);
        // FD.append("page_start", (page - 1) * limit);

        FD.append("doctorId", doctorId);
        FD.append("clinicId", clinicId);
        FD.append("start_date", startdate != 'Invalid date' ? startdate : '');
        FD.append("end_date", enddate != 'Invalid date' ? enddate : '');
      //  FD.append("partnerid", servicePartnerId);
        FD.append("type", serviceTypeId);
        FD.append("referralstatus", referralStatusId != '' ? referralStatusId : 'all');

        if (search) {
          FD.append("name", search);
        }
        const res = await APIHitCall(FD);
        setIsExporting(false);
        if (res?.data?.status === 200) {
          const data = res?.data?.result?.map((item) => ({
            "Refferal Id": item.refid,
            "Patient Name": item.patientfname + " " + item.patientlname,
            "Created at": item?.created_at,
            Clinic: item?.clinicfrom,
            Doctor: item?.fromdoctname + item?.fromdoctlname,
            ToClinic: item?.clinicto,
            ToDoctor: item?.todocfname + item?.todoclname,
          }));

          makeAndsaveExcelFile(header, data);
        }
      } catch (error) {
        console.error(error);
        setIsExporting(false);
      } finally {
        setIsExporting(false);
      }
    }
  };

  const fetchData = () => {
    const FD = new FormData();
    FD.append("action", "get_doctor_referral");
    FD.append("societyId", user?.society_id);
    FD.append("status", status);

    FD.append("auterizetokenid", user?.id);
    FD.append("limit", limit);
    FD.append("page_start", (page - 1) * limit);

    FD.append("doctorId", doctorId);
    FD.append("clinicId", clinicId);
    FD.append("start_date", startdate != 'Invalid date' ? startdate : '');
    FD.append("end_date", enddate != 'Invalid date' ? enddate : '');
  //  FD.append("partnerid", servicePartnerId);
    FD.append("type", serviceTypeId);
    FD.append("referralstatus", referralStatusId != '' ? referralStatusId : 'all');

    if (search) {
      FD.append("name", search);
    }
    debounceApicall(FD);
  };

  const onCloseUpateOrder = () => {
    setUpdatedata(null);
  };

  const onCloseCaseList = () => {
    setCaseMlist(null);
  };
  useMemo(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    limit,
    page,
    search,
    doctorId,
    startdate,
    enddate,
    servicePartnerId,
    serviceTypeId,
    referralStatusId,
    clinicId
  ]);

  // useMemo(() => {
  //   setLimit(10);
  //   setPage(1);
  //   fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
   
  //   search,
  //   doctorId,
  //   startdate,
  //   enddate,
  //   servicePartnerId,
  //   serviceTypeId,
  //   referralStatusId,
  //   clinicId
  // ]);

  const onUpdate = (data) => {
    setUpdatedata(data);
  };
  const onAttachmentsView = (data) => {
    setAttachmentsData(data?.attachmentlink ?? []);
    setIsAttachments(true);
  };
  useEffect(()=>{
    dispatch(updateAppointment([]));
  },[])


   async function handleResendClick(data)  {
    try {
      const FD = new FormData();
      FD.append("action", "resend_doctor_referral_efax");
      FD.append("auterizetokenid", user?.id);
      FD.append("referralId", data?.refid);

      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        message.success("Fax Resent")

      } else {
        message.error("Something went wrong while sending fax")
      }
    } catch (error) {
      message.error("Something went wrong while sending fax")

    }
 //   console.log("Resend Fax clicked");
  };

  const handleYesClick = (data) => {
    window.open(data?.fax, '_blank');

    // Define the action when "Yes" is clicked
  //  console.log("Yes clicked");
  };

  
  const columns = [
    {
      title: "#",
      dataIndex: "refid",
      render: (item) => item,
    },
    {
      title: "Patient Name",
      dataIndex: "first_name",
      render: (item, row) =>
        `${row?.patientfname ?? ""} ${row?.patientlname ?? ""}`,
    },
    {
      title: "Doctor",
      width:"10%",
      dataIndex: "todocfname",
      render: (item, row) => {
        if(row?.doctorto && row?.doctorto !== "0"){
          return `${row?.todocfname ?? ""} ${row?.todoclname ?? ""}`
      }else{
       return  "Not Provided";
      }
    }}, 
    // {
    //   title: "Clinic",
    //   dataIndex: "clinicto",
    //   render: (item,row) => item ?? "-",
    // },
    {
      title: "Clinic",
      dataIndex: "clinicto",
      render: (item,row) => {
       return <div>
          <div>{row?.clinicto ?? ""}</div>
          <div>{row?.branchaddress ?? ""}</div>

        </div>
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
     
      render: (item) => item ?? "-",
    }
,    
    {
      title: "Date",
      width: '12%', // This will give the column maximum width
      dataIndex: "created_at",
      render: (item) => (item ? moment(item).format("MM-DD-YYYY") : "N/A"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (item, row) => {
        if (item === "0") {
          return "New";
        }else 
        if (item === "1") {
          return "Accepted";
        }else 
        if (item === "2") {
          return "Completed";
        }else if(item === "3"){ 
          return (<div>
            <div>Rejected </div>
            <div>{row?.reject_reason??""}</div>
          </div>);

        } 
    }},
    {
      title: "E-Fax",
      dataIndex: "sendFax",
      render: (item, row) => {
        
    
        return (
          <div>
            {row.sendFax === "1" ? (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{ color: '#0081ff', cursor: 'pointer' }}
                  onClick={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    handleYesClick(row)}}
                >
                  Yes
                </span>
                <Tooltip  onClick={(e)=>{
                    e.preventDefault();
                    e.stopPropagation();
                    handleYesClick(row)}} title={`Fax no `+row?.clincfromfaxnumber} >
                  <BiInfoCircle
                    style={{ marginLeft: 5, fontSize: '16px', cursor: 'pointer' }}
                   
                    />
                </Tooltip>
                <Tooltip title="Resend Fax">
                  <RedoOutlined
                    style={{ marginLeft: 5, fontSize: '16px', cursor: 'pointer' }}
                    onClick={(e)=>{
                      e.preventDefault();
                      e.stopPropagation();
                      handleResendClick(row)}}
                    />
                </Tooltip>
              </span>
            ) : (
              "No"
            )}
          </div>
        );
      },
    },
    
  
  
    // {
    //   title: "Chat",
    //   dataIndex: "user_id",
    //   render: (item, row) => {
    
    
    //     return (
    //       <div className="p-3 cursor-pointer">
         
    //       <BsChatLeftTextFill onClick={(e)=>{
    //         console.log("click")
    //            e.preventDefault();
    //            e.stopPropagation();
    //            dispatch(
    //              setCurrentChat({
    //              // vendorid: row?.insadminId,
                     
    //                vendorid: row?.tocasemanagerid,
    //                vendorname: row?.insadminname,
    //                 type: "CaseManager",
                  
    //              })
    //            );
    //       }} className="text-primary" size={20} />
    //     </div>
        
    //     );
    //   },
    // },
    {
      title: "action",
      dataIndex: "user_id",
      align: "right",
      width: 50,
      render: (item, row) => {
        const hasData = row.exercise_therapy?.therapy_name?.length > 0 || row.therapy_protocols?.protocol_name?.length > 0;
        const tooltipData = hasData ? (
          <div style={{ display:"flex", backgroundColor: '#fff', color: '#000', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)', border: 'none', flexDirection:"column", padding:'10px', textAlign: 'left' }}>
          <h6>Exercise Therapy:</h6>
          <ul>
            {row?.exercise_therapy?.therapy_name?.map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ul>
          <h6>Therapy Protocols:</h6>
          <ul>
            {row?.therapy_protocols?.protocol_name?.map((name, index) => (
              <li key={index}>{name}</li>
            ))}
          </ul>
        </div>
        
        ) : null;
    
        return (
          <div
            style={{ height: 40 }}
            className="d-flex text-right justify-content-center align-items-center cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Tooltip title="Chat">

<div className="p-3 cursor-pointer">
         
         <BsChatLeftTextFill onClick={(e)=>{
              e.preventDefault();
              e.stopPropagation();
             
             // setCaseMlist(row)
              dispatch(
                setCurrentChat({
               

                  vendorid: row?.refid,
                  vendorname: "Chat",
                   type: "CaseManager",
                 
                })
              );
         }} className="text-primary" size={20} />
       </div>
       
       </Tooltip>
{hasData && (
              <CustomTooltipMedset title={tooltipData} id="custom-tooltip">
                   <AiOutlineInfoCircle  className="hover-text-primary cursor-pointer" size={24} style={{ cursor: 'pointer' ,marginRight:"15px" }} />

              </CustomTooltipMedset>
            )}
          
            <Tooltip title="View Attachments">
              <BsImages
                className="hover-text-primary cursor-pointer"
                size={22}
                onClick={() => onAttachmentsView(row)}
              />
            </Tooltip>
            <Tooltip title="Update Order">
              <FaGears
                className="hover-text-primary cursor-pointer ml-1"
                size={24}
                onClick={() => onUpdate(row)}
              />
            </Tooltip>


            {row?.referralsummaryfile && row?.referralsummaryfile !== undefined && row?.referralsummaryfile !== "" && row?.referralsummaryfile !== null?<Tooltip title="Download Referral">
              <FaDownload
                className="hover-text-primary cursor-pointer ml-1"
                size={18}
                onClick={() => {
                  window.open(row?.referralsummaryfile, '_blank');

                }}
              />
            </Tooltip>:""}
          
          </div>
        );
      },
    }
    
    
    
    
    
    
  ];
  const onCloseAttachments = () => {
    setIsAttachments(false);
    setAttachmentsData([]);
  };



 

  
  

  return (
    <div style={styles.main}>
      <CaseMangerWrapper>
        <section className="booking-process">
          <div className="" style={{margin:"2%"}}>
            <div className="data-table-main-box">
             <div className="datatable-list-head appointments-list">
                <div className="datatable-list-head-title">
                  Provider {status === "1" ? "Outgoing" : "Incoming"} Referral's
                 
                </div>
                <div
                  className={` flex-align-items-center ${isMobile?"":"d-flex"}` }
                  style={{ gap: "10px" ,width:`${isMobile?"inherit":""}` , }}
                >
                  <Input
                    value={search}
                    allowClear
                    onChange={(e) => setSearch(e.target.value)}
                    size="small"
                    prefix={<FiSearch size={14} />}
                    placeholder="search"
                  />
                                  <div style={{display:"flex", gap:"5px",marginTop:`${isMobile?"10px":""}`}}>

                  <Button
                    icon={<AiOutlinePlusCircle size={18} className="mr-1" />}
                    onClick={() => {
                      navigation("/case-manager/request-service");
                      dispatch(updateServiceStep(serviceSteps.SELECT_USER));
                      const payload = {
                        selectLocationValue: "4",
                      };
                    //  dispatch(setServiceData(initialServiceDetailval));

                      dispatch(
                        setServiceData({ ...initialServiceDetailval, ...payload }),
                      );
                    }}
                    type="primary"
                  >
                    Add New Referral
                  </Button>

                  <FilterOptionsDropdown
                    onExport={() => {
                      exportData();
                    }}
                    onApply={(
                      doctorid,
                      referralStatusId,
                      startdate,
                      enddate,
                      partnerId,
                      typeId,
                      clinicIds
                    ) => {
                      setdoctorId(doctorid);
                      setReferralStatusId(referralStatusId);
                      setstartdate(startdate);
                      setenddate(enddate);
                  //    setservicePartnerId(partnerId);
                      setserviceTypeId(typeId);
                      setClinicId(clinicIds);
                    }}
                    onReset={() => {
                      setdoctorId("");
                      setReferralStatusId("");
                      setstartdate("");
                      setenddate("");
                    //  setservicePartnerId("");
                      setserviceTypeId("");
                      setClinicId("");
                    }}
                    isFromIncomingOutgoing={true}
                  />

                  <Button
                    onClick={() => {
                      if (!isExporting) {
                        setIsExporting(true);
                        exportData();
                      }
                    }}
                    type="primary"
                  >
                    {isExporting ? "Exporting..." : "Export"}
                  </Button>
                  </div>
                </div>
              </div>
              
              <div className="data-table-contain">
                <Table 
                  loading={appointment?.loading || false}
                  locale={{ emptyText: <NoData /> }}
                  pagination={{
                    responsive: true,
                    hideOnSinglePage: false,
                    onChange: setPage,
                    pageSize: limit ?? 20,
                    total: appointment?.count || 0,
                    current: page ?? 1,

                    showSizeChanger: false,
                    showTotal: (total, range) => (
                      <PaginationUI
                        total={total}
                        range={range}
                        limit={limit ?? 20}
                        onPageChange={setPage}
                        page={page ?? 1}
                        onLimitChange={setLimit}
                      />
                    ),
                  }}
                  columns={columns}
                  dataSource={appointment?.data ?? []}
                  selectableRowsVisibleOnly
                  onRow={(row) => ({
                    onClick: () => {
                      if(row?.status === "3"){}else{

                      dispatch(setMainPatientTab(patientTabs.PERSONAL_INFORMATION));
                      navigation(
                        `/case-manager/patient/details/${row?.patientid}`
                      );
                    }
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </section>

       

      
      </CaseMangerWrapper>
      {isAttchments && (
          <AttachmentsView
            data={attachmentsData}
            onClose={onCloseAttachments}
          />
        )}

{isUpdate && (
          <UpdateOrder
            data={isUpdate}
            reloadData={fetchData}
            onClose={onCloseUpateOrder}
            isProviderReffer={true}
          />
        )}

        {
          (caseMlist && <CaseManagerListForChat onCaseListClose={onCloseCaseList} row={caseMlist} onCaseManagerSelect={(caseManager)=>{
                     //  message.info(`Selected Case Manager: ${caseManager.name} ${caseManager.l_name}`);
                     console.log("Selected Case Manager")
                        dispatch(
                setCurrentChat({
               

                  vendorid: caseManager?.casemanagerid,
                 // vendorname: caseMlist?.insadminname,
                  vendorname: caseManager.name,
                   type: "CaseManager",
                 
                })
               
              );

              setCaseMlist(null)

          }} />)
        }
    </div> 
  );
};
