import React from "react";
import Wrapper from "../components/Wrapper";

const NotificationPage = () => {
  return (
    <Wrapper>
      <section class="booking-process">
        <div class="container">
          <div class="profile-main-box">
            <div class="profile-tabs">
              <ul class="flex align-items-center">
                <li>
                  <a href="#" class="active">
                    Notification
                  </a>
                </li>
              </ul>
            </div>

            <div class="tabs-info-con notification-tab">
              <ul>
                <li>
                  <h3>
                    Phlebotomist Assigned <span className="date">27th April, 09:00 PM</span>
                  </h3>
                  <p>
                    Hi Scarlett Johansson, Robert will collect your sample on
                    04-28-2022 between 07:00 AM - 08:00 AM for lab oder
                    @D1772435
                  </p>
                </li>
                <li>
                  <h3>
                    Phlebotomist Assigned <span  className="date">27th April, 09:00 PM</span>
                  </h3>
                  <p>
                    Hi Scarlett Johansson, Robert will collect your sample on
                    04-28-2022 between 07:00 AM - 08:00 AM for lab oder
                    @D1772435
                  </p>
                </li>
                <li>
                  <h3>
                    Phlebotomist Assigned <span  className="date">27th April, 09:00 PM</span>
                  </h3>
                  <p>
                    Hi Scarlett Johansson, Robert will collect your sample on
                    04-28-2022 between 07:00 AM - 08:00 AM for lab oder
                    @D1772435
                  </p>
                </li>
                <li>
                  <h3>
                    Phlebotomist Assigned <span  className="date">27th April, 09:00 PM</span>
                  </h3>
                  <p>
                    Hi Scarlett Johansson, Robert will collect your sample on
                    04-28-2022 between 07:00 AM - 08:00 AM for lab oder
                    @D1772435
                  </p>
                </li>
                <li>
                  <h3>
                    Phlebotomist Assigned <span  className="date">27th April, 09:00 PM</span>
                  </h3>
                  <p>
                    Hi Scarlett Johansson, Robert will collect your sample on
                    04-28-2022 between 07:00 AM - 08:00 AM for lab oder
                    @D1772435
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default NotificationPage;
