
import { Fade, Zoom } from "react-reveal";

const avatars = "/assets/images/banner/banner-graph-avatars.svg";

const graphLines = "/assets/images/banner/graph-routes.svg";

const HealthSystem = () => {
  return (
    <div className="w-100">
      <Fade up>
        <div
          style={{ maxWidth: 886 }}
          className="mx-auto text-center text-primary-light fs-40 home-mobile-size-label"
        >
          Nurturing Value-Based Care for Health Systems and Service
          Partners
        </div>
      </Fade>
      <Fade up>
        <p
          style={{ maxWidth: 647, marginBottom: 37 }}
          className="mx-auto text-center text-grey fs-14"
        >
          Our platform intelligently connects health systems, payors, service
          partners, and patients into one cohesive system.
        </p>
      </Fade>
      <section
        className="health-system-graph position-realtive w-100"
        // style={{ backgroundImage: `url(${Bg})` }}
      >
        <div className="container" style={{ zIndex: 2 }}>
          <Zoom out>
            <img src={graphLines} alt="" className="graph-lines " />
          </Zoom>
          <Zoom>
            <img src={avatars} alt="" className="graph-avatars" />
          </Zoom>
          <div></div>
        </div>
      </section>
    </div>
  );
};

export default HealthSystem;
