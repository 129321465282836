import { useState } from "react";

const CustomTooltipMedset = ({ children, title }) => {
    const [visible, setVisible] = useState(false);
  
    return (
      <div
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        style={{ position: 'relative', display: 'inline-block' }}
      >
        {children}
        {visible && (
          <div style={{
            minWidth:"200px",
            position: 'absolute',
            backgroundColor: '#fff',
            color: '#000',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
            border: 'none',
            padding: '0px',
            zIndex: 1,
            top: '100%',
           
            left: '50%',
            transform: 'translateX(-50%)',
            whiteSpace: 'pre-line',
          }}>
            {title}
          </div>
        )}
      </div>
    );
  };

  export default CustomTooltipMedset;
