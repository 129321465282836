import React, { useState } from "react";
import { Modal, Form, Input, Alert, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setTempToken,
  setToken,
  setUserData,
  updateLoginModal,

} from "../features/auth/authSlice";
import { ReactSession } from 'react-client-session';

import { authStep2, authStep1, authStep3 } from "../features/auth/authService";
import { Link, useNavigate } from "react-router-dom";
// import { generateToken } from "../utils/functions";

 
import ForgotPasswordModal from "./ForgotPasswordModal";
import VerifyOtp from "./VerifyOtp";
import { testAccounts } from "../utils/constants";
// import { testAccounts } from "../utils/constants";

const LoginModal = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVerifyOtpModalOpen, setIsVerifyOtpModalOpen] = useState(false);
  const { fcmToken } = useSelector(({ user }) => user);

  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelVerifyOtp = () => {
    setIsVerifyOtpModalOpen(false);
  };

  const { onClose } = props;
  // const { onRegisterOpen } = props;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(undefined);
  const [loginData, setLoginData] = useState([]);
  // const { errorMessage,} = useSelector(({ user }) => user);

  const dispatch = useDispatch();
  // const [loader, setLoader] = useState(false);

  const generateTempToken = async (userId) => {
    try {
      const res = await authStep3(userId);

      if (res.status === 200) {
        setLoading(false);
        const secureToken = await authStep1(res.data);
        dispatch(
          setToken({ token: secureToken?.data?.access_token, isAuth: true })
        );
     //   console.log('token',generateToken());
          
        ReactSession.setStoreType("localStorage");
        ReactSession.set("sessionTokenMedset", userId);
        // need to  save login data
     //   ReactSession.set("loginData", );
        navigate("/home");
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const [email ,setEmail]= useState("")

  const onLogin = async (data) => {
    try {
       setEmail(data.email);
      
      const userRes = await authStep2(data);
    
      if (userRes?.data?.status === 200) {
        const { result } = userRes?.data;
       
        
       // dispatch(setUserData(result?.[0]));


        
        // generateTempToken(result?.[0]?.id);
        if (
          false
          // window.location.href.includes("staging") ||
          // window.location.href.includes("localhost") ||
         // testAccounts.includes(result?.[0]?.email?.toLowerCase())
        ) {
          setLoginData(result?.session);
          generateTempToken(result?.[0]?.id);
        } else {
          setLoginData(result);
         // setLoginData(result?.[0]);
          setLoading(false);
          
          setIsVerifyOtpModalOpen(true);
        }

        // dispatch(setUserData(result?.[0]));
      } else {
        setErrMsg(userRes.data.message);
        setLoading(false);
        setTimeout(() => {
          setErrMsg(null);
        }, [5000]);
      }
    } catch (err) {
      setErrMsg(err?.data?.message);
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await authStep1();
      if (res?.status === 200 && res?.data?.access_token) {
        dispatch(setTempToken(res?.data?.access_token))
        onLogin({
          ...data,
          token: res?.data?.access_token,
          fcmToken
        });
      }
    } catch (error) {
      setErrMsg(error?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        footer={false}
        style={{paddingBottom:0}}
        onCancel={() => onClose(false)}
        bodyStyle={{ padding: 0}}
        className="w-100 login-modal"

        open
        centered
      >
        <section className="w-100">
          <div className="d-flex">
            <div className="med-login-img  flex justify-center align-items-center"></div>
            <div className="med-login-form   flex justify-center align-items-center">
              <div className="med-login-content">
                <div className="med-form-header">
                  <h2>Welcome</h2>
                  <span>Sign-In, Already a customer?</span>
                </div>
                {errMsg && (
                  <div style={{ marginTop: 20 }}>
                    <Alert
                      message="Error"
                      description={errMsg}
                      type="error"
                      showIcon
                    />
                  </div>
                )}

                <Form form={form} onFinish={onSubmit} className="med-form">
                  <div className="form-field">
                    <label>Email</label>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid email",
                        },
                      ]}
                      name="email"
                      className="input-container"
                    >
                      <Input type="text" autoComplete="off" />
                    </Form.Item>
                  </div>
                  <div className="form-field">
                    <label>Password</label>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please enter the password",
                        },
                      ]}
                      name="password"
                      className="input-container"
                    >
                      <Input type="password" autoComplete="off" />
                    </Form.Item>
                  </div>
                  <div className="forget-link">
                    <Link type="button" to="#" onClick={showModal}>
                      Forgot Password ?
                    </Link>
                  </div>

                  <div className="login-action">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {loading && <Spin />}
                    </div>
                    {!loading && (
                      <button className="btn btn-primary full-width">
                        Login
                      </button>
                    )}
                    <p className="text-center">
                      By continuing you agree to our{" "}
                      <Link
                        to="/terms-and-condition"
                        type="button"
                        onClick={() => {
                          dispatch(updateLoginModal(false));
                        }}
                      >
                        Term of Service
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="/privacy-policy"
                        type="button"
                        onClick={() => {
                          dispatch(updateLoginModal(false));
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
      </Modal>
  
      <ForgotPasswordModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
      />
      {isVerifyOtpModalOpen && (
        <VerifyOtp
          email={email}
          onSuccess={generateTempToken}
          verifyOtpModalOpen={isVerifyOtpModalOpen}
          verifyOtpModalClose={handleCancelVerifyOtp}
          loginData={loginData}
        />
      )}
    </>
  );
};



export default LoginModal;

