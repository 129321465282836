import React, { useEffect } from 'react';
import { Button, Drawer, List, Spin, Avatar, message } from 'antd';
import { useSelector } from 'react-redux';
import { APIHitCall } from '../../../features/case-manager/caseManagerService';

const CaseManagerListForChat = ({ onCaseListClose, row ,onCaseManagerSelect}) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [clinic, setClinic] = React.useState([]);

  const { user } = useSelector(({ caseManagerSlice, user }) => ({
    ...caseManagerSlice,
    ...user,
  }));

  const showLoading = () => {
    setLoading(true);
    // Simulate loading with timeout for demonstration
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  async function getCaseManagerList() {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "get_casemanager_list");
    formData.append("auterizetokenid", user?.id);
    const myClinicId = user?.society_id;
    var otherClinicId = "";
    if(row?.clincfromid === myClinicId){
        otherClinicId = row?.clinctoid;
        setClinic(row?.clinicto)
    }else{
        otherClinicId = row?.clincfromid;
        setClinic(row?.from)

    }

    formData.append("clinicId",otherClinicId);
    
    let res = await APIHitCall(formData);
    if (res.data.status === 200) {
      setData(res?.data?.result);
      setLoading(false);
    } else if (res?.data?.status === 400) {
      setData([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    getCaseManagerList();
  }, []);

 
  const handleCaseManagerClick = (caseManager) => {
    onCaseManagerSelect(caseManager);
    // You can replace this with any action you want
   // message.info(`Selected Case Manager: ${caseManager.name} ${caseManager.l_name}`);
    // For example, you could trigger opening a chat window or navigate to a chat page here
  };

  return (
    <>
      <Drawer
        closable
        destroyOnClose
        title={<p>Case Manager ({clinic})</p>}
        placement="right"
        open
        onClose={onCaseListClose}
      >
       

        {loading ? (
          <Spin size="large" />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={item => (
              <List.Item
              onClick={() => handleCaseManagerClick(item)}
                style={{ cursor: 'pointer' }} // Add cursor pointer for better UI feedback
              >
                <List.Item.Meta
                  avatar={<Avatar>{item.name.charAt(0)}</Avatar>}
                  title={`${item.name} ${item.l_name}`}
                  description={`Case Manager ID: ${item.casemanagerid}`}
                  
                />
              </List.Item>
            )}
          />
        )}
      </Drawer>
    </>
  );
};

export default CaseManagerListForChat;
