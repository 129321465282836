
import { BilledIcon, DischargeTime, HeartIcon, HomeServiceOfferedIcon } from '../../common/icons'

const Stats = () => {
  return (
    <div className='home-stat-section'>
        <div className='container'>

        <div className='text-center fs-40 text-primary-light home-mobile-size-label '>Fostering Lasting Advantages</div>

        <div className='stats-grid mx-auto ' >
        <div className='d-flex align-items-start row-1'>

         
            <div className='stat-grid-item'>
                <DischargeTime  style={{transform:'translateY(-10px)'}}/>
                <div className='text-1'>75%</div>
                <div className='text-2'>Faster Discharge Time</div>
            </div>
            <div className='stat-grid-item'>
                <HeartIcon style={{transform:'scale(1.5)'}}/>
                <div className='text-1'>$1+ billion</div>
                <div className='text-2'>Estimated Readmission-Associated Savings</div>
            </div>
            </div>
   <div className='d-flex align-items-start row-2'>

            <div className='stat-grid-item'>
                <BilledIcon style={{transform:'scale(1.5)'}}/>
                <div className='text-1'>2X</div>
                <div className='text-2'>Home Services Offered</div>
            </div>
            <div className='stat-grid-item'>
                <HomeServiceOfferedIcon style={{transform:'scale(1.5)'}}/>
                <div className='text-1'>1+ million</div>
                <div className='text-2'>More Hours of Care Billed Appropriately At Home</div>
            </div>
   </div>
        </div>
        </div>
    </div>
  )
}

export default Stats