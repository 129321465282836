import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Fade } from "react-reveal";
import { toggleDemoModal } from "../../../features/auth/authSlice";
import { bannerimg8 } from "../../../utils/images";

const Coordination = () => {
  const dispatch=useDispatch()
  const openDemoModal = () => {
    dispatch(toggleDemoModal(true));
  };
  return (
    <div className="coordination-wrapper">
      <div className="container">
        <div className="content-flex-box">
          <Fade left cascade>
            <div className="left">
              <div
                
                className="fs-44 text-primary-light home-mobile-size-label w-max-421"
              >
                Intelligence for{" "}
                <span className="text-primary">Health System </span>Care
                Coordination
              </div>
              <p className="text-grey pt-2 mb-5 fs-14">
                Boost Care Coordination Efficiency by 10x with MedSetGo's
                Easy-to-Use Discharge Tool
              </p>
              <Button onClick={openDemoModal} type="primary">Know More</Button>
            </div>
          </Fade>
          <div className="right">
          <Fade right >
            <img src={bannerimg8} className="coordination-img" alt="" />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coordination;
