//  logo

export const logo ="assets/images/logo-black.svg"
export const hippa ="assets/images/brands/hippa.png"
export const soc ="assets/images/brands/SOC.png"
export const socimt ="assets/images/socimg.png"
export const attested ="assets/images/file.png"

export const veryhappy ="assets/images/veryhappy.png"
export const veryunhappy ="assets/images/veryunhappy.png"
export const unhappy ="assets/images/unhappy.png"
export const happy ="assets/images/happy.png"

// ------

export const heathcare1='/assets/images/banner/healthcare1.png'
export const heathcare2='/assets/images/banner/healthcare2.png'
export const heathcare3='/assets/images/banner/healthcare3.png'
export const heathcare4='/assets/images/banner/healthcare4.png'



//  langing page images
export const bannerimg8='/assets/images/banner/banner-img8.png'
export const bannerimg9='/assets/images/banner/banner-img9.png'
export const bannerimg10='/assets/images/banner/banner-img10.png'
export const bannerimg11='/assets/images/banner/banner-img11.png'
export const bannerimg12='/assets/images/banner/banner-img12.png'

//  langing page images end shere



// about us page images

// export const aboutus1 = '/assets/images/aboutus/aboutus1.png'
// export const aboutus2 = '/assets/images/aboutus/aboutus1.png'
// export const aboutus3 = '/assets/images/aboutus/aboutus1.png'



//  team images

export const saanchi='/assets/images/team/sanchi.png'

export const  vivak = "/assets/images/aboutus/Vivek.png"
export const  shawan = "/assets/images/aboutus/swan.png"
export const  shrey = "/assets/images/aboutus/shrey.png"
export const  harry = "/assets/images/aboutus/harry.png"
export const  upinder = "/assets/images/aboutus/Upinder.png"
export const  Colleen = "/assets/images/aboutus/Colleen.png"
export const  Alex = "/assets/images/aboutus/alex.png"
export const  Nicgay = "/assets/images/aboutus/Nicgay.png"
export const  bob = "/assets/images/aboutus/bob.jpeg"

export const aboutus1 = '/assets/images/aboutus/aboutus1.png'
export const aboutus2 = '/assets/images/aboutus/aboutus2.png'
export const aboutus3 = '/assets/images/aboutus/aboutus3.png'
export const aboutus4 = '/assets/images/aboutus/pexels-pavel-danilyuk-7653310 1.png'
export const aboutus5 = '/assets/images/aboutus/medical-banner-with-doctor-working-laptop 1.png'
export const aboutus8 = '/assets/images/aboutus/brooke-cagle-JBwcenOuRCg-unsplash 1.png'

export const NurturingCulture1 = "/assets/images/aboutus/NurturingCulture1.png"
export const NurturingCulture2 = "/assets/images/aboutus/NurturingCulture2.png"
export const NurturingCulture3 = "/assets/images/aboutus/NurturingCulture3.png"
