import React from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../components/Wrapper";

const FamilyMember = () => {
  const navigation = useNavigate();
  return (
    <Wrapper>
      <section className="booking-process">
        <div className="container">
          <div className="profile-main-box">
            <div className="profile-tabs">
              <ul className="flex align-items-center">
                <li>
                  <a href="#" className="active">
                    Family members
                  </a>
                </li>
              </ul>
            </div>
            <div className="tabs-info-con family-members-info">
              <div className="add-members-box">
                <button className="btn btn-outline">Add members</button>
              </div>
              <div className="manage-family-members">
                <p>Manage Family member</p>
                <ul className="flex">
                  <li>
                    <a onClick={() => navigation("/family-profile")}>
                      <div className="image-div">MC</div>
                      <div className="member-info">
                        <p>Monika</p>
                        <span>Daughter</span>
                      </div>
                      <span className="material-symbols-outlined arrow-icon">
                        keyboard_arrow_right
                      </span>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => navigation("/family-profile")}>
                      <div className="image-div">MC</div>
                      <div className="member-info">
                        <p>John</p>
                        <span>Spouse</span>
                      </div>
                      <span className="material-symbols-outlined arrow-icon">
                        keyboard_arrow_right
                      </span>
                    </a>
                  </li>
                  <li>
                    <a onClick={() => navigation("/family-profile")}>
                      <div className="image-div">
                        <img src="assets/images/face-img.png" alt="" />
                      </div>
                      <div className="member-info">
                        <p>Sheldon</p>
                        <span>Son</span>
                      </div>
                      <span className="material-symbols-outlined arrow-icon">
                        keyboard_arrow_right
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default FamilyMember;
