import React, {useDebugValue, useEffect, useState,} from "react";
import {Button, DatePicker, Form, notification, Radio, Spin, TimePicker,} from "antd";
import Autocomplete from "react-autocomplete";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import _ from "lodash";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {CaseMangerWrapper} from "../../components/Wrapper";
import {
    addPatientAddressAction,
    addPatientinsuracneAction,
    addServiceFormDataAction,
    getServiceAction,
    getShippingAddressAction,
    getSubServiceAction,
    // getTokenAsync,
    placeOrderAction,
} from "../../features/auth/authSlice";
import {getTimeSlot, searchPatients} from "../../features/case-manager/caseManagerSlice";
import moment from "moment";

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
};

const PaymentDetail = ({payItem}) => {
    const dispatch = useDispatch();
    const {addServiceFormData, patient} = useSelector(
        ({user, caseManagerSlice}) => ({
            ...user,
            ...caseManagerSlice,
        })
    );
    return (
        <div className="booking-payment-box">
            <div className="booking-appointment">
                <h5>Booking Dr appointment </h5>
                <div className="booking-price-box flex">
                    <div style={{width: "auto"}} className="patient-value">
                        ${addServiceFormData?.payment_amount}
                    </div>
                </div>
            </div>

            <div className="boking-total-price">
                <ul>
                    <li>
                        <p className="flex align-items-center">
                            Appointment{" "}
                            <span>
                <del>${parseInt(addServiceFormData?.payment_amount) + 10}</del>
                                {" "}
                                ${addServiceFormData?.payment_amount}
              </span>
                        </p>
                    </li>
                    {/*
              <li>
                <p className="flex align-items-center">
                Charges <span>$5</span>
                </p>
              </li> 
          */}
                </ul>

                <div className="total-amount flex">
                    <p className="flex align-items-center">
                        Amount payable <span>${addServiceFormData?.payment_amount}</span>
                    </p>
                </div>
                {payItem}
            </div>
        </div>
    );
};

export const RequestsPage = () => {
    const {service, subService} = useSelector(({user}) => user);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [showTimeSlot, setShowTimeSlot] = useState(false);
    const [timePickerStartTime, setTimePickerStartTime] = useState(moment('10:59:00 PM','HH:mm:ss A'));
    useDebugValue(timePickerStartTime);
    const [timePickerEndTime, setTimePickerEndTime] = useState(moment('12:00:00 PM','HH:mm:ss A'));
    useDebugValue(timePickerEndTime);
    const [currentService, setCurrentService] = useState(
        (service?.data ?? [])?.[0]?.category_id
    );
    useEffect(() => {
        dispatch(getServiceAction());
    }, []);

    const onChange = (event) => {
        setCurrentService(event.target.value);
        dispatch(getSubServiceAction(event.target.value));
    };

    const onFinshForm = (item) => {
        const cat = subService?.data?.[currentService].filter(
            (i) => i?.sub_cat_id === item.services
        )?.[0];
        dispatch(
            addServiceFormDataAction({
                ...item,
                payment_amount: cat?.sub_actual_price,
            })
        );
        navigation("/case-manager/request-service-two");
    };

    const handleTimeSlotClick = () => {
        if (showTimeSlot) {
            setShowTimeSlot(false);
        } else {
            setShowTimeSlot(true);
        }
    }

    const TimeSlot = () => {
        const dispatch = useDispatch();
        const [afterNoonSlot, setAfterNoonSlot] = useState([]);
        const [eveningSlot, setEveningSlot] = useState([]);
        const [morningSlot, setMorningSlot] = useState([]);

        useEffect(() => {
            async function fetchData() {
                return dispatch(getTimeSlot());
            }

            fetchData()
                .then((response) => {
                    setAfterNoonSlot(response.payload.afternoonslot);
                    setEveningSlot(response.payload.eveningslot);
                    setMorningSlot(response.payload.morningslot);
                })
                .catch((error) => {
                    console.debug(error);
                });

        }, [1]);
        const handleSlotTimeClick = (data) => {
            let target = data.target;
            let firstRangePartOne = target.innerHTML.split('-')[0].split(' ')[0];
            let firstRangePartTwo = target.innerHTML.split('-')[0].split(' ')[1];
            let firstTime = firstRangePartOne + ':00 ' + firstRangePartTwo;

            let secondRangePartOne = target.innerHTML.split('-')[1].split(' ')[0];
            let secondRangePartTwo = target.innerHTML.split('-')[1].split(' ')[1];
            let secondTime = secondRangePartOne + ':00 ' + secondRangePartTwo;

            setTimePickerStartTime(moment(firstTime,'HH:mm:ss A'));
            setTimePickerEndTime(moment(secondTime,'HH:mm:ss A'));
            setShowTimeSlot(false);
        }
        return (
            <div className="custom-drawer-overlay" style={{zIndex:2}}>
                <div className="custom-drawer-box">
                    <div className="availble-timing-slots">

                        {morningSlot?.length < 1 ?
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: "10%",
                                }}
                            >
                                <Spin/>
                            </div> :
                            <div className="steps-container">
                                {/*<h4>Sun, 1st May</h4>*/}

                                <div className="slots-timings">
                                    <h5><span>Morning</span></h5>
                                    <ul className="flex align-items-center">
                                        {morningSlot?.map((data, key) =>
                                            <li key={key}>
                                                <a className="active" onClick={handleSlotTimeClick}>
                                                    <span>{data.slotName}</span></a>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                                <div className="slots-timings">
                                    <h5><span>Afternoon</span></h5>
                                    <ul className="flex align-items-center">
                                        {afterNoonSlot?.map((data, key) =>
                                            <li key={key}>
                                                <a className="active" onClick={handleSlotTimeClick}>
                                                    <span>{data.slotName}</span></a>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                                <div className="slots-timings">
                                    <h5><span>Evening</span></h5>
                                    <ul className="flex align-items-center">
                                        {eveningSlot?.map((data, key) =>
                                            <li key={key}>
                                                <a className="active" onClick={handleSlotTimeClick}>
                                                    <span>{data.slotName}</span></a>
                                            </li>
                                        )}
                                    </ul>
                                </div>

                                <div className="booking-action slots-button">
                                    <button className="btn btn-primary full-width"
                                            onClick={() => setShowTimeSlot(false)}>DONE
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
    return (
        <CaseMangerWrapper>
            <div className='booking-process'>
                <Form form={form} onFinish={onFinshForm} className="booking-process">
                    <div className="container">
                        <div className="profile-main-box">
                            <div className="profile-tabs">
                                <ul className="flex align-items-center">
                                    <li>
                                        <Link to='/case-manager/bookings'>
                                            <img src="assets/images/back-arrow.svg" alt=''/> Request Service
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="tabs-info-con profile-tab-info">
                                <div className="profile-fields-row flex no-border">
                                    <div className="form-field">
                                        <label>Service</label>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select the cateogry",
                                                },
                                            ]}
                                            name={"category_id"}
                                        >
                                            <select onChange={onChange} style={{width: "100%"}}>
                                                <option>Select Category</option>
                                                {(service?.data ?? []).map((i,k) => (
                                                    <option value={i?.category_id} key={k}>
                                                        {i?.category_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Item>
                                    </div>
                                    <div className="form-field">
                                        <label>Sub Service</label>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select the service",
                                                },
                                            ]}
                                            name={"services"}
                                        >
                                            <select style={{width: "100%"}}>
                                                <option>Select Service</option>
                                                {(subService?.data?.[currentService] ?? []).map((i,k) => (
                                                    <option value={i?.sub_cat_id} key={k}>
                                                        {i?.sub_category_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Form.Item>
                                    </div>
                                    <div className="form-field">
                                        <label>Date</label>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select the date",
                                                },
                                            ]}
                                            name={"schedule_date"}
                                        >
                                            <DatePicker
                                                className="date-picker"
                                                style={{width: "100%"}}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-field" onClick={handleTimeSlotClick}>
                                        <label>Time</label>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "Please select the time",
                                                },
                                            ]}
                                            name={"timeSlot"}
                                        >
                                            <TimePicker.RangePicker
                                                disabled
                                                value={[timePickerStartTime,timePickerEndTime]}
                                                style={{ width: "100%",opacity:1 }}
                                                className="date-picker"
                                                showTime={{
                                                    format: 'HH:mm:ss A',
                                                    use12Hours:true ,
                                                    defaultValue:[(timePickerStartTime),(timePickerEndTime)]
                                                }}
                                                    />
                                        </Form.Item>
                                    </div>
                                    {showTimeSlot ?
                                        <TimeSlot/> : null
                                    }
                                    <div className="form-field form-field-textarea">
                                        <label>Add Notes </label>
                                        <Form.Item name={"notes"}>
                                            <textarea></textarea>
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="booking-action slots-button">
                                    <button
                                        onClick={() =>
                                            // navigation("/case-manager/request-service-two")
                                            form.submit()
                                        }
                                        className="btn btn-primary"
                                    >
                                        Submit
                                    </button>
                                    {" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </CaseMangerWrapper>
    );
};

export const RequestsPageStepTwo = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const {errorMessage, token, loading} = useSelector(({user}) => user);
    const [searchVal, setSearchVal] = useState("");
    const [patientList, setPatientList] = useState([]);
    useDebugValue(`patientList ${patientList}`);
    const [selectedSearch, setSelectedSearch] = useState("");
    const {addServiceFormData, patient} = useSelector(
        ({user, caseManagerSlice}) => ({
            ...user,
            ...caseManagerSlice,
        }),
    );
    const onSelectpatient = (userId) => {
        dispatch(
            addServiceFormDataAction({
                user_id: userId,
            })
        );
        navigation("/case-manager/request-service-three");
    };
    const searchValue = (input) => {
        let _input = input.trim();
        setSearchVal(input);

        async function fetchData() {
            if (!token) {
                // await dispatch(getTokenAsync());
            }
            return dispatch(searchPatients(_input));
        }

        fetchData()
            .then((response) => {

                const result = response.payload.result
                    .filter(obj => obj.f_name.includes(_input))
                console.debug(result, _input);
                setPatientList(result);
            })
            .catch((error) => {
                console.debug(error);
            });
    }
    return (
        <CaseMangerWrapper>
            <section className="booking-process">
                <div className="container">
                    <div className="booking-process-main-box flex">
                        <div className="booking-steps">
                            <div className="request-head-title">
                                <div className="request-head-title-inner">
                                    <Link to="/case-manager/request-service">
                                        <img src="assets/images/back-arrow.svg"/>
                                    </Link>{" "}
                                    Request Service
                                </div>
                            </div>

                            <div className="steps-heading2 border-bottom-dashed">
                                <div className="steps-container">
                                    <h4 className="manager-sub-form-title">
                                        <a href="#">
                                            <img src="assets/images/back-arrow.svg" alt=""/>
                                        </a>{" "}
                                        Add patient details
                                    </h4>

                                    <div className="">
                                        <div className="form-field">
                                            <label>Search Patient</label>
                                            <Autocomplete
                                                getItemValue={(item) => item.f_name}
                                                items={_.union(patientList, "patientid")}
                                                inputProps={{
                                                    style: {
                                                        width: "100%",
                                                    },
                                                }}
                                                wrapperProps={{
                                                    style: {
                                                        zIndex: 999,
                                                    },
                                                }}
                                                renderItem={(item, isHighlighted) => (
                                                    <div
                                                        style={{
                                                            background: isHighlighted ? "lightgray" : "white",
                                                            padding: 10,
                                                            zIndex: 99,
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {item.f_name}
                                                    </div>
                                                )}
                                                value={searchVal}
                                                onChange={(e) => searchValue(e.target.value)}
                                                onSelect={(val, item) => {
                                                    setSelectedSearch(item);
                                                    setSearchVal(val);
                                                }}
                                                className="container-item-search"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="patient-detail-form">
                                <div className="steps-container">
                                    <div className="patient-form">
                                        <div className="form-field">
                                            <label>First name</label>
                                            <input
                                                value={selectedSearch?.f_name}
                                                type="text"
                                            />
                                        </div>
                                        <div className="form-field">
                                            <label>Last name</label>
                                            <input
                                                value={selectedSearch?.l_name}
                                                type="text"
                                            />
                                        </div>
                                        <div className="form-field">
                                            <label>Date of birth</label>
                                            <input type="date" value={selectedSearch?.dob} />
                                        </div>
                                        <div className="form-field">
                                            <label>Gender</label>
                                            <div className="gender-select">
                                                <label>
                                                    <input
                                                        name="gender"
                                                        checked={selectedSearch?.gender == "Male"}
                                                        type="radio"
                                                    />
                                                    Male
                                                </label>

                                                <label>
                                                    <input
                                                        name="gender"
                                                        checked={selectedSearch?.gender == "Female"}
                                                        type="radio"
                                                    />
                                                    Female
                                                </label>

                                                <label>
                                                    <input
                                                        name="gender"
                                                        checked={selectedSearch?.gender == "Other"}
                                                        type="radio"
                                                    />
                                                    Other
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-field">
                                            <label>Mobile number</label>
                                            <input value={selectedSearch?.phone} type="text"/>
                                        </div>
                                        <div className="form-field">
                                            <label>Email address</label>
                                            <input value={selectedSearch?.email} type="text"/>
                                        </div>

                                        <div className="booking-action">
                                            <button
                                                className="btn btn-primary full-width"
                                                // disabled={!selectedSearch?.f_name}
                                                onClick={() =>
                                                    onSelectpatient(selectedSearch?.patientid)
                                                }
                                            >
                                                Continue
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PaymentDetail/>
                    </div>
                </div>
            </section>
        </CaseMangerWrapper>
    );
};

export const RequestsPageStepThree = React.memo(() => {
    const [currentPatient, setpatient] = useState({});
    const navigation = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const {addServiceFormData, patient} = useSelector(
        ({user, caseManagerSlice}) => ({
            ...user,
            ...caseManagerSlice,
        })
    );

    useEffect(() => {
        const curPatient = (patient?.data??[]).filter(
            (i) => i.patientid === addServiceFormData?.user_id
        )?.[0];
        setpatient(curPatient);
        form.setFieldsValue({
            f_name: curPatient?.f_name,
            ...curPatient,
        });
    }, []);

    const onFrontImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
        }
    };

    const onFinish = async (formValue) => {
        
        await dispatch(
            addPatientinsuracneAction({
                ...formValue,
                user_id: addServiceFormData?.user_id,
            })
        );
        navigation("/case-manager/request-service-four");
    };

    return (
        <CaseMangerWrapper>
            <section className="booking-process">
                <div className="container">
                    <Form form={form} onFinish={onFinish}>
                        <div className="booking-process-main-box flex">
                            <div className="booking-steps">
                                <div className="request-head-title">
                                    <div className="request-head-title-inner">
                                        <Link to="/case-manager/request-service-two">
                                            <img src="assets/images/back-arrow.svg" alt=''/>
                                        </Link>{" "}
                                        Request Service
                                    </div>
                                </div>

                                <div className="steps-heading2">
                                    <div className="steps-container">
                                        <h4 className="manager-sub-form-title">
                                            <a href="#">
                                                <img src="assets/images/back-arrow.svg" alt=""/>
                                            </a>{" "}
                                            Add patient insurance details
                                        </h4>

                                        <div className="">
                                            <div className="form-field">
                                                <label>Insurance carrier</label>
                                                <input type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="patient-detail-form">
                                    <div className="steps-container">
                                        <div className="patient-form">
                                            <div className="form-field">
                                                <label>First name</label>
                                                <Form.Item name="f_name">
                                                    <input
                                                        value={currentPatient?.f_name}
                                                        type="text"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="form-field">
                                                <label>Last name</label>
                                                <Form.Item name="l_name">
                                                    <input
                                                        value={currentPatient?.l_name}
                                                        type="text"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="form-field">
                                                <label>Date of birth</label>
                                                <Form.Item name={"dob"}>
                                                    <input
                                                        type="date"
                                                        value={currentPatient?.dob}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="form-field">
                                                <label>Member ID</label>
                                                <Form.Item name={"memberId"}>
                                                    <input
                                                        type="text"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </Form.Item>
                                                <div className="note-grey-small">
                                                    Do not include numbers after dash in ID (e.g.-
                                                    123456-00)
                                                </div>
                                            </div>
                                            <div className="form-field">
                                                <label>Group/account Number</label>
                                                <Form.Item name={"account_number"}>
                                                    <input
                                                        type="text"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="form-field">
                                                <label>Upload insurance card image</label>
                                                <div className="insurance-card-upload">
                                                    <input type={"file"} onChange={onFrontImage}/>
                                                    {/* <div className="insurance-card-upload-btn">
                          <label>
                            <img src="assets/images/add-file-icon.svg" />
                            <span>Add Back view</span>
                            <input type="file" />
                          </label>
                        </div> */}
                                                </div>
                                            </div>

                                            <div className="booking-action">
                                                <button
                                                    onClick={
                                                        () => form.submit()
                                                        // navigation("/case-manager/request-service-four")
                                                    }
                                                    className="btn btn-primary full-width"
                                                >
                                                    Save & Continue
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <PaymentDetail/>
                        </div>
                    </Form>
                </div>
            </section>
        </CaseMangerWrapper>
    );
});

export const RequestsPageStepFour = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const {addServiceFormData, patientShippingAddress} = useSelector(
        ({user, caseManagerSlice}) => ({
            ...user,
            ...caseManagerSlice,
        })
    );

    useEffect(() => {
        dispatch(getShippingAddressAction(addServiceFormData?.user_id));
    }, []);

    const onSelectAddress = (sId) => {
        dispatch(
            addServiceFormDataAction({
                usershipping_id: sId,
            })
        );
        navigation("/case-manager/request-service-six");
    };

    return (
        <CaseMangerWrapper>
            <section className="booking-process">
                <div className="container">
                    <div className="booking-process-main-box flex">
                        <div className="booking-steps">
                            <div className="request-head-title">
                                <div className="request-head-title-inner">
                                    <Link to="/case-manager/request-service-three">
                                        <img src="assets/images/back-arrow.svg"/>
                                    </Link>{" "}
                                    Request Service
                                </div>
                            </div>

                            <div className="steps-heading2">
                                <div className="steps-container">
                                    <h4 className="manager-sub-form-title">
                                        <a href="#">
                                            <img src="assets/images/back-arrow.svg" alt=""/>
                                        </a>{" "}
                                        Select Patient address
                                    </h4>
                                </div>
                            </div>

                            <div className="patient-detail-form">
                                <div className="steps-container">
                                    <div className="tabs-info-con patient-address-tab your-address-tab">
                                        {_.isArray(patientShippingAddress) ? (
                                            <ul>
                                                {(patientShippingAddress ?? []).map((i) => {
                                                    return (
                                                        <li
                                                            // onClick={() =>
                                                            //   navigation("/case-manager/request-service-five")
                                                            // }
                                                            className="added-address flex"
                                                        >
                                                            <div className="address-icon">
                                <span className="material-symbols-outlined">
                                  {i?.addressType}
                                </span>
                                                            </div>
                                                            <div className="address-info">
                                                                <p>Home</p>
                                                                <span>
                                  {i?.house_number || "-"}
                                                                    {i?.address}, {i?.street_name || "-"}-
                                                                    {i?.pincode || "-"},{i?.city || "-"},{" "}
                                                                    {i?.state || "-"}
                                </span>
                                                                <div className="address-action flex">
                                                                    <a
                                                                        onClick={() =>
                                                                            onSelectAddress(i?.shippingId)
                                                                        }
                                                                    >
                                                                        Select
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })}

                                                {/*
                      <li className="add-new-address">
                        <a href="#">
                          <span className="material-symbols-outlined">add</span>
                          <p>Add address</p>
                        </a>
                      </li> */}
                                            </ul>
                                        ) : (
                                            <div>
                                                <p>{patientShippingAddress}</p>
                                            </div>
                                        )}
                                        <div>
                                            <Button
                                                onClick={() =>
                                                    navigation("/case-manager/request-service-five")
                                                }
                                            >
                                                Add new address
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PaymentDetail/>
                    </div>
                </div>
            </section>
        </CaseMangerWrapper>
    );
};

export const RequestsPageStepFive = () => {
    const navigation = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const {addServiceFormData, patient} = useSelector(
        ({user, caseManagerSlice}) => ({
            ...user,
            ...caseManagerSlice,
        })
    );
    const [value, setValue] = useState(null);

    const onFinshForm = async (formValue) => {
        const currentPatient = patient?.data.filter(
            (i) => i.patientid == addServiceFormData?.user_id
        )?.[0];
        const payload = {
            ...formValue,
            lat: "0",
            lng: "0",
            email: currentPatient?.email,
            mobile: currentPatient?.phone,
            full_name: currentPatient?.f_name,
            user_id: currentPatient?.patientid,
            country_id: "US",
            Isdefault: "0",
            address: value?.label,
        };
        await dispatch(addPatientAddressAction(payload));
        navigation("/case-manager/request-service-four");
    };

    return (
        <CaseMangerWrapper>
            <section className="booking-process">
                <div className="container">
                    <div className="booking-process-main-box flex">
                        <div className="booking-steps">
                            <div className="request-head-title">
                                <div className="request-head-title-inner">
                                    <Link to="/case-manager/request-service-four">
                                        <img src="assets/images/back-arrow.svg"/>
                                    </Link>{" "}
                                    Request Service
                                </div>
                            </div>

                            <div className="steps-heading border-bottom-dashed">
                                <div className="steps-container">
                                    <h4 className="manager-sub-form-title">
                                        <a href="#">
                                            <img src="assets/images/back-arrow.svg" alt=""/>
                                        </a>{" "}
                                        Select Patient address
                                    </h4>

                                    <div className="map-location">
                                        <img src="assets/images/map.png" alt=""/>
                                    </div>

                                    <div className="location-list selected-location">
                                        <ul>
                                            <li>
                                                <img src="assets/images/select-location.svg" alt=""/>
                                                <div
                                                    style={{width: "100%"}}
                                                    className="location-box flex align-items-center google-auto"
                                                >
                                                    <GooglePlacesAutocomplete
                                                        apiKey="AIzaSyAE1z7awgVAVbbHQc7jROz27mDepwePZwI"
                                                        selectProps={{
                                                            value,
                                                            onChange: setValue,
                                                            style: {
                                                                width: "100%",
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="patient-detail-form">
                                <Form
                                    form={form}
                                    onFinish={onFinshForm}
                                    className="booking-process"
                                >
                                    <div className="steps-container">
                                        <div className="patient-form">
                                            <div className="form-field">
                                                <label>Zipcode</label>
                                                <Form.Item name={"pincode"}>
                                                    <input style={{width: "100%"}} type="text"/>
                                                </Form.Item>
                                            </div>
                                            <div className="form-field">
                                                <label>House number and building</label>
                                                <Form.Item name={"house_number"}>
                                                    <input style={{width: "100%"}} type="text"/>
                                                </Form.Item>
                                            </div>
                                            <div className="form-field">
                                                <label>Street name</label>
                                                <Form.Item name={"street_name"}>
                                                    <input style={{width: "100%"}} type="text"/>
                                                </Form.Item>
                                            </div>

                                            <div className="select-street">
                                                <Form.Item name="addressType">
                                                    <Radio.Group>
                                                        <ul className="flex align-items-center">
                                                            <Radio value="home">
                                                                <li className="flex align-items-center">
                                  <span className="material-symbols-outlined">
                                    home
                                  </span>
                                                                    Home
                                                                </li>
                                                            </Radio>
                                                            <Radio value="work">
                                                                <li className="flex align-items-center">
                                  <span className="material-symbols-outlined">
                                    work
                                  </span>
                                                                    Office
                                                                </li>
                                                            </Radio>
                                                            <Radio value="public">
                                                                <li className="flex align-items-center">
                                  <span className="material-symbols-outlined">
                                    public
                                  </span>
                                                                    Other
                                                                </li>
                                                            </Radio>
                                                        </ul>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>

                                            {/* <div className="forget-link agree-terrms">
                    <label>
                      <input type="checkbox" />
                      save the address for future use
                    </label>
                  </div> */}

                                            <div className="booking-action">
                                                <button
                                                    onClick={
                                                        () => form.submit()
                                                        // navigation("/case-manager/request-service-six")
                                                    }
                                                    className="btn btn-primary full-width"
                                                >
                                                    Continue
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>

                        <PaymentDetail/>
                    </div>
                </div>
            </section>
        </CaseMangerWrapper>
    );
};

export const RequestsPageStepSix = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const {
        addServiceFormData,
        patientShippingAddress,
        service: {data: category},
        subService: {data: service},
        patient,
    } = useSelector(({user, caseManagerSlice}) => ({
        ...user,
        ...caseManagerSlice,
    }));
    const onClickPay = () => {
        dispatch(placeOrderAction(addServiceFormData));
        notification.success({
            message: "Service Requested",
            style: {
                display: "flex",
                padding: 15,
            },
        });
        navigation("/case-manager/bookings");
    };
    const getUsername = (item) => {
        const userName = patient?.data?.filter(
            (i) => i.patientid === addServiceFormData?.user_id
        )?.[0];
        return userName !== undefined ? userName[item] : "";
    };
    const getService = () => {
        const ser = service?.[addServiceFormData?.category_id]?.filter(
            (i) => i?.sub_cat_id === addServiceFormData?.services
        )?.[0];
        return ser?.sub_category_name;
    };

    return (
        <CaseMangerWrapper>
            <section className="booking-process">
                <div className="container">
                    <div className="booking-process-main-box flex">
                        <div className="booking-steps">
                            <div className="request-head-title">
                                <div className="request-head-title-inner">
                                    <Link to="/case-manager/request-service-five">
                                        <img src="assets/images/back-arrow.svg"/>
                                    </Link>{" "}
                                    Service Summary
                                </div>
                            </div>

                            <div className="manager-summery-list border-bottom-dashed">
                                <div className="steps-container">
                                    <div className="manager-summery-option">
                                        <div className="manager-summery-data">
                                            <div className="manager-summery-data-icon">
                        <span className="material-symbols-outlined">
                          medical_services
                        </span>
                                            </div>
                                            <div className="manager-summery-data-text">
                                                Service <span>{getService()}</span>
                                            </div>
                                        </div>
                                        <div className="manager-summery-action">
                      <span>
                        <span className="material-symbols-outlined">edit</span>{" "}
                          Change
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="manager-summery-list border-bottom-dashed">
                                <div className="steps-container">
                                    <div className="manager-summery-option">
                                        <div className="manager-summery-data">
                                            <div className="manager-summery-data-icon">
                        <span className="material-symbols-outlined">
                          account_circle
                        </span>
                                            </div>
                                            <div className="manager-summery-data-text">
                                                {getUsername("f_name") ?? ''}
                                                <span>{getUsername("gender") ?? ''}</span>
                                            </div>
                                        </div>
                                        <div className="manager-summery-action">
                      <span>
                        <span className="material-symbols-outlined">edit</span>{" "}
                          Change
                      </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="manager-summery-list border-bottom-dashed">
                <div className="steps-container">
                  <div className="manager-summery-option">
                    <div className="manager-summery-data">
                      <div className="manager-summery-data-icon">
                        <span className="material-symbols-outlined">
                          calendar_today
                        </span>
                      </div>
                      <div className="manager-summery-data-text">
                        Date <span>5 Jul, 2022 - 6 Jul, 2022</span>
                      </div>
                    </div>
                    <div className="manager-summery-action">
                      <span>
                        <span className="material-symbols-outlined">edit</span>{" "}
                        Change
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
                            {/*
              <div className="manager-summery-list border-bottom-dashed">
                <div className="steps-container">
                  <div className="manager-summery-option">
                    <div className="manager-summery-data">
                      <div className="manager-summery-data-icon">
                        <span className="material-symbols-outlined">schedule</span>
                      </div>
                      <div className="manager-summery-data-text">
                        Time <span>06:00 AM-07:00 AM</span>
                      </div>
                    </div>
                    <div className="manager-summery-action">
                      <span>
                        <span className="material-symbols-outlined">edit</span>{" "}
                        Change
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
                            {/*
              <div className="manager-summery-list border-bottom-dashed">
                <div className="steps-container">
                  <div className="manager-summery-option">
                    <div className="manager-summery-data">
                      <div className="manager-summery-data-icon">
                        <span className="material-symbols-outlined">home</span>
                      </div>
                      <div className="manager-summery-data-text">
                        Address{" "}
                        <span>
                          33882 Rowland Dr Fremont, CA-94555, California, USA
                        </span>
                      </div>
                    </div>
                    <div className="manager-summery-action">
                      <span>
                        <span className="material-symbols-outlined">edit</span>{" "}
                        Change
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="manager-summery-list border-bottom-dashed">
                <div className="steps-container">
                  <div className="manager-summery-option">
                    <div className="manager-summery-data">
                      <div className="manager-summery-data-icon">
                        <span className="material-symbols-outlined">call</span>
                      </div>
                      <div className="manager-summery-data-text">
                        Phone number <span>+1 (408) 887-7974</span>
                      </div>
                    </div>
                    <div className="manager-summery-action">
                      <span>
                        <span className="material-symbols-outlined">edit</span>{" "}
                        Change
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}
                        </div>
                        <PaymentDetail
                            payItem={
                                <button
                                    onClick={onClickPay}
                                    className="btn btn-primary full-width manager-pay-btn"
                                >
                                    Pay
                                </button>
                            }
                        />
                    </div>
                </div>
            </section>
        </CaseMangerWrapper>
    );
};
