import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const d = document.querySelector('.wrapper-container');
    if (d) {
      setTimeout(() => {
        d.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 0);
    } 
    else {
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 0);
    }
  }, [pathname]);

  return null;
}
