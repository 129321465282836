import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button, message } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { setFcmToken } from "../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { RequestPermission, onMessageListener } from "../firebase";
import "./style/style.css";
import { setCurrentChat } from "../store/chat/Slice";

const ChatPushNotification = () => {
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(
    Notification.permission
  );
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  
  const getFcmToken = async () => {
    
    const fcmToken = await RequestPermission();

    dispatch(setFcmToken(fcmToken));
    
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleAllowPress = () => {
    Notification.requestPermission();
    setModalVisible(false);
  };
   
  const showPermissionPopup = async () => {
    setModalVisible(false);

    const fcmToken = await RequestPermission();
    dispatch(setFcmToken(fcmToken));
    setNotificationPermission(Notification.permission);
  };
   
  useEffect(() => {
    if (notificationPermission === "default") {
      console.log("Notification permission has not been granted yet");
      if(isSafari){
        setModalVisible(true)
      }else
      showPermissionPopup();
    } else if (notificationPermission === "denied") {
      console.log("Notification permission has been denied");
      dispatch(setFcmToken(""));
    } else if (notificationPermission === "granted") {
      console.log("Notification permission has been granted");
     
      getFcmToken();
        
    }
  }, [notificationPermission]);

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        console.log("payload", payload);
        setNotification(payload);
        let sound = new Audio();
        sound.src = "/assets/audio/notification_sound.wav";
        sound.load();
        sound.play().catch(function (error) {
          console.log(
            "Chrome cannot play sound without user interaction first"
          );
        });
        console.log(payload)

        toast(<Message notification={payload} />);
      })
      .catch((err) => console.log("failed: ", err));
  }, [notification]);

  return (
    <>
      <ToastContainer />
      <CustomModal
        visible={modalVisible}
        onClose={handleCloseModal}
        onAllowPress={showPermissionPopup}
      />
    </>
  );
};
const Message = ({ notification }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div id="notificationHeader" onClick={()=>{
        if(notification?.data && notification?.data?.id && notification?.data?.id !== null && notification?.data?.id !== "" && notification?.data?.type === "CaseManager")
        {
          dispatch(
            setCurrentChat({
           

              vendorid: notification?.data?.id,
              vendorname: "User" ,
          //    vendorname: caseMlist?.insadminname,
               type: "CaseManager",
             
            }))
        }
      }}>
        {/* image is optional */}
        {notification?.notification?.image && (
          <div id="imageContainer">
            <img src={notification?.image} width={100} />
          </div>
        )}
        <span>{notification?.notification?.title}</span>
      </div>
      <div id="notificationBody">{notification?.notification?.body}</div>
    </>
  );
};

const CustomModal = ({ visible, onClose, onAllowPress }) => {
  return (
    <Modal
      title="Allow Notifications"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onAllowPress}>
          Allow
        </Button>,
      ]}
    >
      <p>Medsetgo wants you to allow notifications</p>
    </Modal>
  );
};

export default ChatPushNotification;

// import React, { useState, useEffect } from "react";
// import { toast, ToastContainer } from "react-toastify";
// import { Modal, Button } from "antd";
// import "react-toastify/dist/ReactToastify.css";
// import { setFcmToken } from "../features/auth/authSlice";
// import { useDispatch } from "react-redux";
// import { RequestPermission, onMessageListener } from "../firebase";
// import "./style/style.css";

// const ChatPushNotification = () => {
//   const dispatch = useDispatch();
//   const [notification, setNotification] = useState({});
//   const [modalVisible, setModalVisible] = useState(false);
//   const [notificationPermission, setNotificationPermission] = useState(
//     Notification.permission
//   );

//   const getFcmToken = async () => {
//     const fcmToken = await RequestPermission();
//     dispatch(setFcmToken(fcmToken));
//   };

//   const handleCloseModal = () => {
//     setModalVisible(false);
//   };

//   const handleAllowPress = () => {
//     Notification.requestPermission().then((permission) => {
//       setNotificationPermission(permission); // Update state here to trigger the useEffect hook
//       setModalVisible(false);
//     });
//   };

//   const showPermissionPopup = async () => {
//     const permission = Notification.permission;
//     if (permission === "default") {
//       setModalVisible(true);  // Show the modal only when permission is default
//     } else if (permission === "granted") {
//       await getFcmToken();
//     } else if (permission === "denied") {
//       dispatch(setFcmToken(""));
//     }
//   };

//   useEffect(() => {
//     showPermissionPopup();
//   }, [notificationPermission]);

//   useEffect(() => {
//     onMessageListener()
//       .then((payload) => {
//         console.log("payload", payload);
//         setNotification(payload);
//         toast(<Message notification={payload?.notification} />, {
//           onOpen: () => {
//             // Play sound when toast notification opens
//             let sound = new Audio("/assets/audio/notification_sound.wav");
//             sound.play().catch(function (error) {
//               console.log("Unable to play sound", error);
//             });
//           },
//         });
//       })
//       .catch((err) => console.log("failed: ", err));
//   }, []);

//   return (
//     <>
//       <ToastContainer />
//       <CustomModal
//         visible={modalVisible}
//         onClose={handleCloseModal}
//         onAllowPress={handleAllowPress}
//       />
//     </>
//   );
// };

// const Message = ({ notification }) => {
//   return (
//     <>
//       <div id="notificationHeader">
//         {notification?.image && (
//           <div id="imageContainer">
//             <img src={notification?.image} width={100} />
//           </div>
//         )}
//         <span>{notification?.title}</span>
//       </div>
//       <div id="notificationBody">{notification?.body}</div>
//     </>
//   );
// };

// const CustomModal = ({ visible, onClose, onAllowPress }) => {
//   return (
//     <Modal
//       title="Allow Notifications"
//       open={visible}
//       onCancel={onClose}
//       footer={[
//         <Button key="cancel" onClick={onClose}>
//           Cancel
//         </Button>,
//         <Button key="ok" type="primary" onClick={onAllowPress}>
//           Allow
//         </Button>,
//       ]}
//     >
//       <p>Medsetgo wants you to allow notifications</p>
//     </Modal>
//   );
// };

// export default ChatPushNotification;

