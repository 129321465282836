import React, {  useEffect,  useState } from "react";
import {  useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CaseMangerWrapper } from "../../../components/Wrapper";
import { Button, Table, message,  } from "antd";
import { APIHitCall } from '../../../features/case-manager/caseManagerService';

import {   AiOutlinePlusCircle } from "react-icons/ai";
import { NoData } from "../../../components/common/Nodata/index";


import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import moment from "moment";


export const Survey = () => {
  const navigation = useNavigate();
  const [surveyData, setsurveyData] = useState([]);
  const [loading, setLoading] = useState(false);

 
  const {  user } = useSelector(({  user }) => ({
   
    ...user,
  }));

 

  async function sendSurvey(surveyId) {
    setLoading(true);
    const formData = new FormData();

    formData.append("action", "send_clinic_survey");

    formData.append("surveyId", surveyId)


    formData.append("auterizetokenid", user?.id);
  
    let res = await APIHitCall(formData);
    setLoading(false);
    if (res.data.status === 200) {
      message.success("Survey Sent successfully");
      getSurvey();
       
    }else{
        setLoading(false);
    }
   
}

  async function getSurvey() {
    setLoading(true);
    const formData = new FormData();

    formData.append("action", "get_clinic_survey");
    formData.append("status", "0")

    formData.append("clinicId", user?.society_id)

    formData.append("userid",user?.id);

    formData.append("auterizetokenid", user?.id);
  
    let res = await APIHitCall(formData);
    setLoading(false);
    if (res.data.status === 200) {
       
        setsurveyData(res.data.result)
    }else{
        setLoading(false);
    }
   
}

 

 
  useEffect(()=>{
     getSurvey();
  },[])
  const columns = [
    {
      title: "#",
      dataIndex: "surveyId",
      render: (item) => item,
    },
    {
      title: "Survey Name",
      dataIndex: "surveyName",
      render: (item) => item,

    },
    {
      title: "Created at",
      dataIndex: "created_at",
      render: (date) => date ? moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A') : "-",
  },
    {
      title: "Sent At",
      dataIndex: "sent_at",
      render: (date) => date ? moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY hh:mm A') : "-",
    },
    {
      title: "Status",
      dataIndex: "submissionstatus",
      render: (status,row) => {
          if (row?.sent_at === null) {
              return <button onClick={()=>sendSurvey(row?.surveyId)}  style={buttonStyle} >Send</button>;
          } else  {
              return <button style={buttonStyle} disabled>Sent</button>;
          } 
      },
  }
  
 
    
    
    
    
    
  ];
 
  
  

  return (
    <div style={styles.main}>
      <CaseMangerWrapper>
        <section className="booking-process">
          <div className="container">
            <div className="data-table-main-box">
              <div className="datatable-list-head appointments-list">
                <div className="datatable-list-head-title">
                  Survey List
                </div>
                <div
                  className="d-flex flex-align-items-center"
                  style={{ gap: "10px" }}
                >
                 
                  <Button
                    icon={<AiOutlinePlusCircle size={18} className="mr-1" />}
                    onClick={() => {
                      navigation("/case-manager/addSurvey",{replace: true});
                     
                    }}
                    type="primary"
                  >
                    Add New Survey
                  </Button>

                
                </div>
              </div>

              <div className="data-table-contain">
                <Table
                 
                  locale={{ emptyText: <NoData /> }}
                  pagination={{
                    responsive: true,
                    hideOnSinglePage: false,
                 
                   
                   
                  }}
                  columns={columns}
                  dataSource={surveyData ?? []}
                  selectableRowsVisibleOnly
                  onRow={(row) => ({
                    onClick: () => {
                      navigation(
                        `/case-manager/survey/${row?.surveyId}`
                      );
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </section>

       

      
      </CaseMangerWrapper>
     
    </div>
  );
};
const buttonStyle = {
  backgroundColor: "#4CAF50", /* Green */
  

  color: "white",
  padding: "8px",
  textAlign: "center",
  textDecoration: "none",
  display: "inline-block",
  fontSize: "10px",
  margin: "4px 2px",
  cursor: "pointer",
  borderRadius:'14px',
  border:"none",
};
