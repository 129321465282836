import { Input, Spin } from "antd"
import { CaseMangerWrapper } from "../../../components/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

import { BiSearch } from "react-icons/bi";
import { setServiceData } from "../../../store/AddService/Slice";
import { updateServiceStep } from "../../../store/AddService/Slice";
import DataTable from "react-data-table-component";
import { apihitCall } from "../../../service/authService";
import  moment  from "moment";
import { initialServiceDetailval } from "../../../store/AddService/Slice";
import { serviceSteps } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
const DoctorsAppointmentsList = () => {
    const navigate = useNavigate();

    const { doctors } = useSelector(
        ({ caseManagerSlice }) => caseManagerSlice
    );
    const { token } = useSelector(({ user }) => user);

    const [doctorId, setDrId] = useState(null);
    const [search, setSearch] = useState('')
    const [open, setOpen] = useState(false);
    const { user } = useSelector(({ user, service }) => ({
        ...user,
        ...service,
      }));
      const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [appointment, setAppointment] = useState([]);

    async function getDrAppointments() {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("action", "get_doctor_appointment");
          formData.append("societyid", user?.society_id);
          formData.append("doctorId",  user?.id);
          formData.append("auterizetokenid", user?.id);
          let res = await apihitCall(formData, token);
          if (res?.status === 200) {
            setAppointment(res?.data?.result ?? []);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          setAppointment([]);
        }
      }

    useEffect(() => {
        getDrAppointments();
    }, []);

    const filteredData = useMemo(() => {
        if (search) {
            return (appointment ?? []).filter((el) => String(el?.f_name?.toLowerCase() + " " + el?.l_name?.toLowerCase()).includes(search?.toLowerCase()));
        }
        return appointment?.data ?? []
    }, [appointment, search])

    const columns = [
        {
            name: "Image",
            cell: (row) => (
                <img 
                    id="doctor-list-img" 
                    src={row?.patientimage || "/assets/images/avatar/doctor.jpeg"} 
                    alt="patient" 
                    style={{borderRadius: '50%', maxWidth: '50px', maxHeight: '50px'}} 
                /> 
            )
        },
        {
            name: "NPI",
            selector: (row) => (row.patientnpi !== undefined && row.patientnpi !== null && row.patientnpi !== "0") ? row.patientnpi : row.patientId,

        },
        {
            name: "Name",
            selector: (row) => `${row.patientfname}  ${row.patientlname}`,
        },

        {
            name: "Schedule Date",
            selector: (row) => {
              const formattedDate = moment(row.app_date).format('MM-DD-YYYY');
              return formattedDate;
            }
          }
,          
          
       
     
        {
            name: "Schedule Time",
            selector: (row) => row.app_time,
        },
    ];

    const showDrawer = (data) => {
        setDrId(data?.doctorid);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <CaseMangerWrapper>
                <section className="booking-process">
                    <div className="container">
                        <div className="data-table-main-box">
                            <div className="datatable-list-head ">
                                <div className="datatable-list-head-title">
                                Today's Appointments ({appointment?.length})
                                </div>
                                {/* <div className="datatable-list-head-btn">
                                    <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} suffix={<BiSearch />} className='input-h-40' />
                                </div> */}
                            </div>
                            {(appointment?.loading && filteredData?.length !== 0) ?
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: "10%",
                                }}>
                                    <Spin />
                                </div> :
                                <div className="data-table-contain d-table patient-table text-center">
                                    <DataTable
                                        pagination
                                        columns={columns}
                                        data={appointment ?? []}
                                        onRowClicked={(e)=>{
                                            const p = {
                                                appointmentid: e?.appointmentid,
                                                user_id: e?.patientId,
                                                phone: e?.phone,
                                                email: e?.email,
                                                dob: e?.dob,
                                                gender: e?.gender,
                                                insuranceId:e?.insuranceId || ""
                                              };
                                          
                                             //  console.log('patienr id',d)
                                              dispatch(setServiceData({ ...initialServiceDetailval, ...p }));
                                              dispatch(updateServiceStep(serviceSteps.SELECT_ADDRESS));
                                              navigate("/case-manager/request-service");
                                        }}
                                      
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </CaseMangerWrapper>

        </>
    )
}

export default DoctorsAppointmentsList;

