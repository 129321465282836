import axios, { tempTokenAxios } from "./../config/api-client";
// import axiosHIT from "axios";
const PATH = {
  main: "main.php",
};

export const registerService = (payload, token) =>
  axios.post(PATH.main, payload);

export const loginService = (payload, token) =>
  axios.post(PATH.main, payload);

export const verifyOtp = (payload, token) =>
  axios.post(PATH.main, payload);

export const apihitCall = (payload) =>
  axios.post(`${PATH.main}`, payload);

export const apiHitWithTempToken  =(payload)=>tempTokenAxios.post(`${PATH.main}`, payload)

export const apiGetOrders = (payload, token) =>
    axios.post(PATH.main, payload);

export const updateAlertStatus = (payload, token) =>
    axios.post(PATH.main, payload);
