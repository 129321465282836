import { Input, Popover, Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import { TbMessage } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getChatList } from "../../../store/chat/actions";
import { setCurrentChat } from "../../../store/chat/Slice";

const HeaderChatList = () => {
  const { chatList, loading, currentChat } = useSelector(({ chat }) => ({
    ...chat,
  }));
  const [search, setSearch] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const fetchChatList = () => {
    dispatch(getChatList());
  };
  useEffect(() => {
    fetchChatList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //   vendorid
  const filterdData = useMemo(() => {
    if (search) {
      return chatList?.filter((el) =>
        el?.vendorname?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }
    return chatList ?? [];
  }, [chatList, search]);

  const openChat = (item) => {
    if (currentChat &&currentChat?.vendorid) {
      dispatch(setCurrentChat(null));
      setTimeout(() => {
        dispatch(setCurrentChat(item));
      }, [50]);
    } else {
      dispatch(setCurrentChat(item));
    }
    setOpen(false);
    if (pathname !== "/case-manager/bookings") {
      navigate("/case-manager/bookings");
    }
  };
  return (
    <Popover
      overlayClassName="chat-popover"
      trigger="click"
      isOpen={isOpen}
      onOpenChange={setOpen}
      content={
        <Spin spinning={loading}>
          <div>
            <Input
              onChange={(e) => setSearch(e.target.value)}
              placeholder="search partner"
            />
            <div className="chat-list-box mt-2 d-flex flex-column">
              {(filterdData ?? []).length > 0 ? (
                filterdData?.map((el) => (
                  <div onClick={() => openChat(el)} className="chat-list-item">
                    {el?.vendorname}
                  </div>
                ))
              ) : (
                <div
                  style={{ height: 400 }}
                  className="d-flex justify-content-center align-items-center"
                >
                  No recent chat data found{" "}
                </div>
              )}
            </div>
          </div>
        </Spin>
      }
    >
      <div
        className="header-chat-icon"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <TbMessage size={20} />
      </div>
    </Popover>
  );
};

export default HeaderChatList;
