import React from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../components/Wrapper";

const TabBar = () => {
  const navigation = useNavigate();
  return (
    <div class="profile-tabs">
      <ul class="flex align-items-center">
        <li>
          <a onClick={() => navigation("/booking-profile")}>Profile</a>
        </li>
        <li>
          <a onClick={() => navigation("/booking-insurance")}>
            Insurance details
          </a>
        </li>
        <li>
          <a onClick={() => navigation("/booking-setting")}>Settings</a>
        </li>
      </ul>
    </div>
  );
};

const ProfileDetail = () => {
  const navigation = useNavigate();
  return (
    <Wrapper>
      <section class="booking-process">
        <div class="container">
          <div class="profile-main-box">
            <TabBar />

            <div class="tabs-info-con profile-tab-info">
              <div class="profile-main-info flex">
                <div class="profile-photo flex align-items-center">
                  <img src="assets/images/profile-img.png" alt="" />
                  <div class="profile-img-info">
                    <p>Pick photo from your computer</p>
                    <a href="#">Add photo</a>
                  </div>
                </div>

                <div class="profile-main-fields flex">
                  <div class="form-field">
                    <label>First name</label>
                    <input type="text" />
                  </div>

                  <div class="form-field">
                    <label>Last name</label>
                    <input type="text" />
                  </div>
                </div>
              </div>

              <div class="profile-fields-row flex">
                <div class="form-field">
                  <label>Date of birth</label>
                  <input type="text" />
                </div>
                <div class="form-field">
                  <label>Gender</label>
                  <div class="gender-select">
                    <label>
                      <input type="radio" />
                      Male
                    </label>

                    <label>
                      <input type="radio" />
                      Female
                    </label>

                    <label>
                      <input type="radio" />
                      Other
                    </label>
                  </div>
                </div>
                <div class="form-field">
                  <label>Mobile number</label>
                  <input type="number" />
                </div>
                <div class="form-field">
                  <label>Email address</label>
                  <input type="text" />
                </div>
              </div>

              <div class="profile-fields-row flex no-border">
                <div class="form-field">
                  <label>Street Address</label>
                  <input type="text" />
                </div>
                <div class="form-field">
                  <label>Apt, suit, floor</label>
                  <input type="number" />
                </div>
                <div class="form-field">
                  <label>City</label>
                  <input type="text" />
                </div>
                <div class="form-field">
                  <label>State</label>
                  <input type="text" />
                </div>
                <div class="form-field">
                  <label>Zipcode</label>
                  <input type="text" />
                </div>
              </div>

              <div class="booking-action slots-button">
                <button
                  onClick={() => navigation("/booking-insurance")}
                  class="btn btn-primary"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export const InsuranceDetail = () => {
  const navigation = useNavigate();
  return (
    <Wrapper>
      <section class="booking-process">
        <div class="container">
          <div class="profile-main-box">
            <TabBar />
            <div class="tabs-info-con insurance-tab-info">
              <div class="profile-fields-row flex no-border">
                <div class="form-field">
                  <label>First name</label>
                  <input type="text" />
                </div>
                <div class="form-field">
                  <label>Last name</label>
                  <input type="number" />
                </div>
                <div class="form-field">
                  <label>Date of birth</label>
                  <input type="text" />
                </div>
              </div>

              <div class="profile-fields-row flex no-border insurance-row-field">
                <div class="form-field">
                  <label>Member ID</label>
                  <input type="text" />
                  <span class="note">
                    Do not include numbers after dash in ID (e.g.- 123456-00)
                  </span>
                </div>
                <div class="form-field">
                  <label>Group/account Number</label>
                  <input type="text" />
                </div>
              </div>

              <div class="booking-action slots-button">
                <button
                  onClick={() => navigation("/booking-setting")}
                  class="btn btn-primary"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export const Setting = () => {
  const navigation = useNavigate();
  return (
    <Wrapper>
      <section class="booking-process">
        <div class="container">
          <div class="profile-main-box setting-page">
            <TabBar />
            <div class="tabs-info-con settings-tab-info flex">
              <div class="settings-left-menu flex">
                <ul>
                  <li>
                    <a href="#" class="flex active">
                      Change password{" "}
                      <span class="material-symbols-outlined">
                        keyboard_arrow_right
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#" class="flex">
                      Delete account{" "}
                      <span class="material-symbols-outlined">
                        keyboard_arrow_right
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#" class="logout-link">
                      Logout
                    </a>
                  </li>
                </ul>
              </div>

              <div class="settings-right-area">
                <div class="form-field">
                  <label>New password</label>
                  <input type="text" />
                </div>
                <div class="form-field">
                  <label>Confirm password</label>
                  <input type="text" />
                </div>

                <div class="save-changes">
                  <button
                    onClick={() => navigation("/family-member")}
                    class="btn btn-primary"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default ProfileDetail;
