import React from "react";
import {  Form, Input } from "antd";

import { useNavigate } from "react-router-dom";

const LoginCaseManager = () => {
  const [form] = Form.useForm();
  const navigation = useNavigate();
  const onLogin = () => {
    navigation("/case-manager/bookings");
  };

  return (
    <section>
      <div className="container-fluid">
        <div className="row">
          {/* <div className="med-login-img flex justify-center align-items-center"></div> */}
          <div className="med-login-form flex justify-center align-items-center">
            <div className="med-login-content">
              <div className="med-form-header">
                <h2>Welcome</h2>
                <span>Login as Case manager</span>
              </div>
              <Form form={form} onFinish={onLogin} className="med-form">
                <div className="form-field">
                  <label>Email</label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid email",
                      },
                    ]}
                    name="email"
                    className="input-container"
                  >
                    <Input type="text" />
                  </Form.Item>
                </div>
                <div className="form-field">
                  <label>Password</label>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter the password",
                      },
                    ]}
                    name="password"
                    className="input-container"
                  >
                    <Input type="password" />
                  </Form.Item>
                </div>
                <div className="forget-link">
                  <a href="#">Forgot Password ?</a>
                </div>

                <div className="login-action">
                  <button className="btn btn-primary full-width">Login</button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginCaseManager;
