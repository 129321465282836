import { Button, Dropdown, Menu, DatePicker } from "antd";
import { FaFilter } from "react-icons/fa";
import { APIHitCall } from "../../features/case-manager/caseManagerService";
import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const FilterOptionsDropdown = ({
  onApply,
  onReset,
  onExport,
  isFromIncomingOutgoing,
  ...props
}) => {
  const { user } = useSelector(({ user }) => ({ ...user }));

  const [doctorId, setDoctorId] = useState("");
  const [partnerId, setpartnerId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [referralStatusId, setReferralStatusId] = useState("");
  const [clinicId, setClinicId] = useState("");
  const [referralStatusData, setReferralStatusData] = useState(isFromIncomingOutgoing?[
    {
      id: "4",
      status: !isFromIncomingOutgoing ? "Pending" : "New",
    },
    {
      id: "1",
      status: "Accepted",
    },
    {
      id: "3",
      status: "Rejected",
    },
    {
      id: "2",
      status: "Completed",
    },
  ]:[
    {
      id: "0",
      status:  "New",
    },
    {
      id: "1",
      status: "Interested",
    },
    {
      id: "2",
      status: "Not Interested",
    },
    {
      id: "3",
      status: "Assigned",
    },
    {
      id: "4",
      status: "Accepted",
    },
    {
      id: "5",
      status: "Rejected",
    }, {
      id: "6",
      status: "Cancelled",
    }, {
      id: "7",
      status: "Completed",
    },
  ]);

  const [doctorData, setdoctorData] = useState([]);
  const [clinicData, setClinicData] = useState([]);
  const [startdate, setStartDate] = useState("");
  const [defaultstartdate, setdefaultStartDate] = useState("");
  const [defaultenddate, setdefaultEndDate] = useState("");

  const [enddate, setEndDate] = useState("");
  const [visible, setVisible] = useState(false);
  const [servicePartnerData, setservicePartnerData] = useState([]);
  const [typeData, setTypeData] = useState([]);

  const handleStartDateChange = (date) => {
    const d = moment(date);
    setdefaultStartDate(date);
    const formattedDate = d.format("YYYY-MM-DD");
    setStartDate(formattedDate);
  };

  const handleEndDateChange = (date) => {
    const d = moment(date);
    setdefaultEndDate(date);
    const formattedDate = d.format("YYYY-MM-DD");
    setEndDate(formattedDate);
  };

  const onClickApply = () => {
    onApply(
      doctorId,
      referralStatusId,
      startdate,
      enddate,
      partnerId,
      typeId,
      clinicId
    );
    setVisible(false);
  };

  const onClickReset = () => {
    setDoctorId("");
    setpartnerId("");
    setReferralStatusId("");
    setStartDate("");
    setEndDate("");
    setTypeId("");
    setdefaultStartDate("");
    setdefaultEndDate("");
    setClinicId("");

    onApply("", "", "", "", "", "", "");
    //onReset();
    setVisible(false);
  };

  useEffect(() => {
    get_doctor_list();
    get_clinic_filter();
    !isFromIncomingOutgoing && get_community_vendor_list();
    !isFromIncomingOutgoing && get_order_service_list();
  }, []);

  async function get_doctor_list() {
    try {
      const FD = new FormData();
      FD.append("action", "get_doctor_list");
      FD.append("societyid", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        setdoctorData(res?.data?.result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function get_clinic_filter() {
    try {
      const FD = new FormData();
      FD.append("action", "get_clinic_filter");
      FD.append("societyId", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        console.log("data =>", res?.data?.result);
        setClinicData(res?.data?.result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function get_community_vendor_list() {
    try {
      const FD = new FormData();
      FD.append("action", "get_community_vendor_list");
      FD.append("societyId", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        setservicePartnerData(res?.data?.vendors);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function get_order_service_list() {
    try {
      const FD = new FormData();
      FD.append("action", "get_order_service_list");
      FD.append("societyId", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        setTypeData(res?.data?.result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const menu = (
    <Menu>
      <div
        class="dropup"
        style={{ width: "300px", fontFamily: "Arial, sans-serif" }}
      >
        <div style={{ padding: "1rem", backgroundColor: "#f8f9fa" }}>
          <h5 style={{ color: "#343a40", fontWeight: "bold" }}>
            Filter Options
          </h5>
        </div>
        <div
          style={{
            padding: "1rem",
            overflow: "scroll",
            backgroundColor: "#ffffff",
          }}
        >
          {!isFromIncomingOutgoing && (
            <div class="mb-3">
              <label class="form-label" style={{ fontWeight: "bold" }}>
                Doctor
              </label>
              <select
                value={doctorId}
                id="doctorId"
                class="form-select"
                name="doctorId"
                onChange={(e) => setDoctorId(e.target.value)}
              >
                <option value="">Select</option>
                {doctorData.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item?.doctorid}
                    >{`${item?.f_name} ${item?.l_name}`}</option>
                  );
                })}
              </select>
            </div>
          )}

          {!isFromIncomingOutgoing && (
            <div class="mb-3">
              <label class="form-label" style={{ fontWeight: "bold" }}>
                Service Partner{" "}
              </label>
              <select
                value={partnerId}
                id="doctorId"
                class="form-select"
                name="doctorId"
                onChange={(e) => setpartnerId(e.target.value)}
              >
                <option value="">Select</option>
                {servicePartnerData.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item?.partnerId}
                    >{`${item?.OrganizationName}`}</option>
                  );
                })}
              </select>
            </div>
          )}

          <div class="mb-3">
            <label class="form-label" style={{ fontWeight: "bold" }}>
              Referral Status
            </label>
            <select
              value={referralStatusId}
              id="doctorId"
              class="form-select"
              name="doctorId"
              onChange={(e) => setReferralStatusId(e.target.value)}
            >
              <option value="">Select</option>
              {referralStatusData.map((item, index) => {
                return (
                  <option
                    key={index}
                    value={item?.id}
                  >{`${item?.status}`}</option>
                );
              })}
            </select>
          </div>

          {isFromIncomingOutgoing && (
            <div class="mb-3">
              <label class="form-label" style={{ fontWeight: "bold" }}>
                Clinic
              </label>

              <select
                value={clinicId}
                id="clinicId"
                class="form-select"
                name="clinicId"
                onChange={(e) => setClinicId(e.target.value)}
              >
                <option value="">Select</option>
                {clinicData.map((item, index) => {
                  return (
                    <option key={index} value={item?.clinicId}>
                      {`${item?.clinicname}`}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          {!isFromIncomingOutgoing && (
            <div class="mb-3">
              <label class="form-label" style={{ fontWeight: "bold" }}>
                Type{" "}
              </label>
              <select
                value={typeId}
                id="doctorId"
                class="form-select"
                name="doctorId"
                onChange={(e) => setTypeId(e.target.value)}
              >
                <option value="">Select</option>
                {typeData.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item?.serviceId}
                    >{`${item?.name}`}</option>
                  );
                })}
              </select>
            </div>
          )}

          <div class="row align-items-center">
            <div class="col-md-12">
              <label class="form-label" style={{ fontWeight: "bold" }}>
                Start Date
              </label>
              <DatePicker
                value={defaultstartdate}
                style={{ width: "100%", height: "48px", borderRadius: "6px" }}
                format="MM-DD-YYYY"
                onChange={handleStartDateChange}
              />
            </div>
            <div class="col-md-12 mt-3">
              <label class="form-label" style={{ fontWeight: "bold" }}>
                End Date
              </label>
              <DatePicker
                value={defaultenddate}
                style={{ width: "100%", height: "48px", borderRadius: "6px" }}
                format="MM-DD-YYYY"
                onChange={handleEndDateChange}
              />
            </div>
          </div>

          <div
            class=""
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 30,
              justifyContent: "space-evenly",
            }}
          >
            <button
              style={{ height: 40, width: 100, fontSize: "12px", margin: 1 }}
              type="submit"
              class="btn btn-primary"
              onClick={onClickReset}
            >
              Reset
            </button>
            <button
              style={{ height: 40, width: 100, fontSize: "12px", margin: 1 }}
              type="submit"
              class="btn btn-primary"
              onClick={onClickApply}
            >
              Apply
            </button>
            {/* <button style={{height:40,width:100,fontSize:'12px',margin:1}} type="submit" class="btn btn-primary" onClick={onExport}>Download</button> */}
          </div>
        </div>
      </div>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} open={visible} onOpenChange={setVisible}>
      <Button
        {...props}
        icon={<FaFilter size={18} className="mr-1" />}
        type="primary"
      >
        Filter
      </Button>
    </Dropdown>
  );
};

export default FilterOptionsDropdown;
