import React, { useEffect, useState } from "react";
import { CaseMangerWrapper } from "../../../components/Wrapper";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Checkbox,
  Radio,
  message,
  Select,
} from "antd";
import { apihitCall } from "../../../service/authService";
import { postApi } from "../../../store/AddService/service";
import Dragger from "antd/lib/upload/Dragger";
import { FiUploadCloud } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { APIHitCall } from "../../../features/case-manager/caseManagerService";
import { Option } from "antd/lib/mentions";
import "./styles.css";
import { getPatientDetail } from "../../../features/auth/authSlice";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

let serviceFacilityObject = {};
let anesthesiaObject = {};
let assistantSurgeonObject = {};
let anesthesiaYesObject = {};
let surgicalApproachObject = {};
let computerizedSystemObject = {};
let todays_date = moment().format("YYYY-MM-DD");
let date_from = "";
let date_to = "";
let patient_dob = "";
let requestingProviderName = "";
let serviceProviderName = "";

const FormInput = (label, name, isRequired, message) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required: isRequired, message: message }]}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Input style={{ width: "100%" }} />
    </Form.Item>
  );
};

const FormDateInput = (label, name, isRequired, message) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: isRequired,
          message: message,
        },
      ]}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <DatePicker
        style={{ width: "100%", height: "48px", borderRadius: "6px" }}
        format="MM-DD-YYYY"
        onChange={(date) => {
          if (date) {
            const formattedDate = date.format("YYYY-MM-DD");
            if (name === "todaysDate") {
              todays_date = formattedDate;
            } else if (name === "dateFrom") {
              date_from = formattedDate;
            } else if (name === "dateTo") {
              date_to = formattedDate;
            } else if (name === "patientDOB") {
              patient_dob = formattedDate;
            }
          } else {
            // Handle the case when date is null
            // For example, you might want to set the corresponding variable to an empty string
            if (name === "todaysDate") {
              todays_date = "";
            } else if (name === "dateFrom") {
              date_from = "";
            } else if (name === "dateTo") {
              date_to = "";
            } else if (name === "patientDOB") {
              patient_dob = "";
            }
          }
        }}
        disabledDate={(current) => {
          return (
            name === "patientDOB" && current && current > moment().endOf("day")
          );
        }}
      />
    </Form.Item>
  );
};

const FormRadio = (label, name, valueYes, valueNo, handleChange) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: true,
          message: "Please select at least one option!",
        },
      ]}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Radio.Group onChange={handleChange}>
        <Radio value={valueYes}>Yes</Radio>
        <Radio value={valueNo}>No</Radio>
      </Radio.Group>
    </Form.Item>
  );
};

const CaseManagerDetail = () => {
  return (
    <>
      <span style={{ fontWeight: "500", fontSize: "24px", padding: "0 30px" }}>
        {"Pre Auth Form"}
      </span>
      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-4 mt-4">
          {FormDateInput(
            "Today’s Date",
            "todaysDate",
            true,
            "Please select Today's Date!"
          )}
        </div>

        <div className="col-md-4 mt-4">
          {FormDateInput(
            "Dates of Service",
            "dateFrom",
            true,
            "Please select Date of service!"
          )}
        </div>

        <div className="col-md-4 mt-4">
          {FormDateInput(
            "To",
            "dateTo",
            true,
            "Please select Date of service!"
          )}
        </div>
      </div>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-6 mt-2">
          {FormInput(
            "Contact Name",
            "contactName",
            true,
            "Please input contact name!"
          )}
        </div>

        <div className="col-md-6 mt-2">
          {FormInput("Email", "email", true, "Please input your email!")}
        </div>
      </div>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-4 mt-2">
          {FormInput(
            "Ph#",
            "caseManagerPhone",
            true,
            "Please input your phone number!"
          )}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "Fax#",
            "caseManagerFax",
            false,
            "Please input your fax number!"
          )}
        </div>
      </div>
    </>
  );
};

const PatientInformationDetail = (form, patientId) => {
  const { user } = useSelector(({ user }) => ({
    ...user,
  }));

  async function get_patient_address() {
    try {
      const FD = new FormData();
      FD.append("action", "get_society_user_address");
      FD.append("society_id", user?.society_id);
      FD.append("casemanager", user?.id);
      FD.append("auterizetokenid", user?.id);
      FD.append("userId", patientId);

      const res = await APIHitCall(FD);
      console.log("address response ==>", res);
      if (res?.data?.status === 200) {
        let cityState = `${res?.data?.result[0]?.city} , ${res?.data?.result[0]?.state}`;
        console.log("city state --->", cityState);
        form.setFieldsValue({ patientCityState: cityState });
      } else {
        form.setFieldsValue({ patientCityState: "" });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    get_patient_address();
  }, []);

  return (
    <>
      <span style={{ fontWeight: "500", fontSize: "20px", padding: "0 30px" }}>
        {"PATIENT INFORMATION"}
      </span>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-6 mt-4">
          {FormInput(
            "Patient Name",
            "patientName",
            true,
            "Please input your patient name!"
          )}
        </div>

        <div className="col-md-6 mt-4">
          {FormDateInput(
            "Date of Birth",
            "patientDOB",
            true,
            "Please select your Date of Birth!"
          )}
        </div>
      </div>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-12 mt-2">
          {FormInput(
            "City/State",
            "patientCityState",
            true,
            "Please input your city/state"
          )}
        </div>
      </div>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-4 mt-2">
          {FormInput(
            "Primary Health Insurance",
            "primaryHealthInsurance",
            true,
            "Please input primary health insurance!"
          )}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "ID#",
            "primaryInsuranceID",
            true,
            "Please input primary health insurance ID!"
          )}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "Plan",
            "primaryInsurancePlan",
            true,
            "Please input primary health insurance plan!"
          )}
        </div>
      </div>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-4 mt-2">
          {FormInput(
            "Other Health Insurance",
            "otherHealthInsurance",
            false,
            "Please input other health insurance!"
          )}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "ID#",
            "otherInsuranceID",
            false,
            "Please input other health insurance ID!"
          )}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "Plan",
            "otherInsurancePlan",
            false,
            "Please input other health insurance plan!"
          )}
        </div>
      </div>
    </>
  );
};

const ProviderInformationDetail = (form) => {
  const { user } = useSelector(({ user }) => ({
    ...user,
  }));

  const [isServiceFacilityOtherSelected, setIsServiceFacilityOtherSelected] =
    useState(false);
  const [search, setSearch] = useState("");
  const [searchRequesting, setSearchsearchRequesting] = useState("");

  const serviceFacilityHandleChange = (checkedValues) => {
    setIsServiceFacilityOtherSelected(
      checkedValues.includes("serviceFacilityOther")
    );

    const result = {
      InPatient: checkedValues.includes("inPatient"),
      OutPatient: checkedValues.includes("outPatient"),
      Office: checkedValues.includes("office"),
      Home: checkedValues.includes("home"),
      Other: checkedValues.includes("serviceFacilityOther"),
    };

    serviceFacilityObject = result;
    console.log("service facility object ==>", serviceFacilityObject);
  };
  async function get_doctor_list() {
    try {
      const FD = new FormData();
      FD.append("action", "get_doctor_list");
      FD.append("societyid", user?.society_id);
      FD.append("auterizetokenid", user?.id);
      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        setDoctorDataRequesting(res?.data?.result);
        if(res?.data?.result.length === 1){
          var value = res?.data?.result[0].doctorid;
          form.setFieldsValue({ requestingProvider: value });
                var doc = res?.data?.result.find(
                  (item) => item.doctorid === value
                );
                form.setFieldsValue({ providerAreaCode: doc.phone });
                form.setFieldsValue({ providerNPI: doc.npi_number });
                form.setFieldsValue({ providerCompleteAddress: doc.address });
                let name = `${doc.f_name} ${doc.l_name}`;
                requestingProviderName = name;
        }
      } else {
        setDoctorDataRequesting([]);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function getDoctorListApi() {
    try {
      const FD = new FormData();
      FD.append("action", "filterred_doctor_list");
      FD.append("societyid", user?.society_id);
      FD.append("keyword", search);
      FD.append("auterizetokenid", user?.id);

      const res = await APIHitCall(FD);

      if (res?.data?.status === 200) {
        setDoctorData(res?.data?.result);
      }
    } catch (error) {
      setDoctorData([]);
      console.error(error);
    } finally {
    }
  }
  useEffect(() => {
    getDoctorListApi();
  }, [search]);

  useEffect(() => {
    get_doctor_list();
  }, []);

  const [doctorData, setDoctorData] = useState([]);
  const [doctorDataForRquesting, setDoctorDataRequesting] = useState([]);

  return (
    <>
      <span style={{ fontWeight: "500", fontSize: "20px", padding: "0 30px" }}>
        {"PROVIDER INFORMATION"}
      </span>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-4 mt-2">
          <Form.Item
            label={"Requesting Provider"}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="requestingProvider"
            rules={[{ required: true, message: "Please select!" }]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select"
              optionFilterProp="children"
              // Set search state on search

              onChange={(value) => {
                form.setFieldsValue({ requestingProvider: value });
                var doc = doctorDataForRquesting.find(
                  (item) => item.doctorid === value
                );
                form.setFieldsValue({ providerAreaCode: doc.phone });
                form.setFieldsValue({ providerNPI: doc.npi_number });
                form.setFieldsValue({ providerCompleteAddress: doc.address });
                let name = `${doc.f_name} ${doc.l_name}`;
                requestingProviderName = name;
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {doctorDataForRquesting.map((item) => (
                <Option key={item.doctorid} value={item.doctorid}>
                  {item.f_name + " " + item.l_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* {FormInput(
            "Requesting Provider",
            "requestingProvider",
            true,
            "Please input your requesting provider"
          )} */}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "NPI#",
            "providerNPI",
            true,
            "Please input your NPI number"
          )}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "Area Code/ph#",
            "providerAreaCode",
            true,
            "Please input your Area code/Phone"
          )}
        </div>
      </div>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-12 mt-2">
          {FormInput(
            "Complete Address",
            "providerCompleteAddress",
            true,
            "Please input your complete address!"
          )}
        </div>
      </div>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-4 mt-2">
          <Form.Item
            label={"Service Provider"}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="serviceProvider"
            rules={[{ required: true, message: "Please select!" }]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select"
              optionFilterProp="children"
              onSearch={(value) => setSearch(value)} // Set search state on search
              onChange={(value) => {
                form.setFieldsValue({ serviceProvider: value });
                var doc = doctorData.find((item) => item.doctorid === value);
                form.setFieldsValue({ serviceProviderAreaCode: doc.phone });
                form.setFieldsValue({ serviceProviderNPI: doc.npi_number });
                form.setFieldsValue({
                  serviceProviderCompleteAddress: doc.address,
                });
                let name = `${doc.f_name} ${doc.l_name}`;
                serviceProviderName = name;
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {doctorData.map((item) => (
                <Option key={item.doctorid} value={item.doctorid}>
                  {item.f_name + " " + item.l_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* {FormInput(
            "Service Provider",
            "serviceProvider",
            true,
            "Please input service provider name!"
          )} */}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "NPI#",
            "serviceProviderNPI",
            true,
            "Please input service provider NPI number!"
          )}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "Area Code/Ph#",
            "serviceProviderAreaCode",
            true,
            "Please input service provider area code!"
          )}
        </div>
      </div>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-12 mt-2">
          {FormInput(
            "Complete Address",
            "serviceProviderCompleteAddress",
            true,
            "Please input service provider complete address!"
          )}
        </div>
      </div>

      {/* <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-12 mt-2">
          <Form.Item
            label={"Service Facility"}
            name={"serviceFacility"}
            rules={[
              {
                required: false,
                message: "Please select at least one service facility!",
              },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group onChange={serviceFacilityHandleChange}>
              <Checkbox value="inPatient">Inpatient</Checkbox>
              <Checkbox value="outPatient">Outpatient</Checkbox>
              <Checkbox value="office">Office</Checkbox>
              <Checkbox value="home">Home</Checkbox>
              <Checkbox value="serviceFacilityOther">Other</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div> */}

      {/* isServiceFacilityOtherSelected ? (
        <div className="row" style={{ padding: "0 20px" }}>
          <div className="col-md-12 mt-2">
            {FormInput(
              "If other, please specify",
              "serviceFacilitySpecify",
              isServiceFacilityOtherSelected ? true : false,
              "Please input your reason!"
            )}
          </div>
        </div>
      ) : null */}

      {/* <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-12 mt-2">
          {FormInput(
            "Complete Address",
            "serviceFacilityCompleteAddress",
            true,
            "Please input your service facility complete address!"
          )}
        </div>
      </div> */}

      {/* <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-6 mt-2">
          {FormInput(
            "Area Code/Ph#",
            "serviceFacilityAreaCode",
            true,
            "Please input your service facility area code!"
          )}
        </div>

        <div className="col-md-6 mt-2">
          {FormInput(
            "Service Facility NPI",
            "serviceFacilityNPI",
            true,
            "Please input your service facility NPI number!"
          )}
        </div>
      </div> */}
    </>
  );
};

const RequestedProcedureDetails = () => {
  const [isAnesthesiaYesSelected, setIsAnesthesiaYesSelected] = useState(false);
  const [isAssistantSurgeonYesSelected, setIsAssistantSurgeonYesSelected] =
    useState(false);
  const [isSurgicalApproachYesSelected, setIsSurgicalApproachYesSelected] =
    useState(false);

  const onRadioHandleChange = (e) => {
    if (e.target.value === "anesthesiaYes" || e.target.value === "anesthesiaNo")
      setIsAnesthesiaYesSelected(e.target.value === "anesthesiaYes");

    if (
      e.target.value === "assistantSurgeonYes" ||
      e.target.value === "assistantSurgeonNo"
    )
      setIsAssistantSurgeonYesSelected(
        e.target.value === "assistantSurgeonYes"
      );

    const anesthesiaResult = {
      anesthesia: e.target.value === "anesthesiaYes",
    };

    const assistantSurgeonResult = {
      assistantSurgeon: e.target.value === "assistantSurgeonYes",
    };

    const computerizedResult = {
      computerizedSystem: e.target.value === "computerizedNavigationYes",
    };

    anesthesiaObject = anesthesiaResult;
    assistantSurgeonObject = assistantSurgeonResult;
    computerizedSystemObject = computerizedResult;
  };

  const anesthesiaSpecifyTypeHandleChange = (checkedValues) => {
    const result = {
      Local: checkedValues.includes("local"),
      "Conscious Sedation": checkedValues.includes("consciousSedation"),
      General: checkedValues.includes("general"),
    };

    anesthesiaYesObject = result;
  };

  const surgicalApproachHandleChange = (checkedValues) => {
    setIsSurgicalApproachYesSelected(
      checkedValues.includes("surgicalApproachOther")
    );

    const result = {
      Open: checkedValues.includes("open"),
      Laparoscopic: checkedValues.includes("laparoscopic"),
      Endoscopic: checkedValues.includes("endoscopic"),
      Robotic: checkedValues.includes("robotic"),
      Other: checkedValues.includes("surgicalApproachOther"),
    };

    surgicalApproachObject = result;
  };

  return (
    <>
      <span style={{ fontWeight: "500", fontSize: "20px", padding: "0 30px" }}>
        {"REQUESTED PROCEDURES AND/OR SERVICES"}
      </span>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-4 mt-2">
          {FormInput(
            "Diagnosis Code",
            "diagnosisCode",
            false,
            "Please input diagnosis code!"
          )}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "CPT/HCPCS Code",
            "cptCode",
            false,
            "Please input CPT/HCPCS code!"
          )}
        </div>

        <div className="col-md-4 mt-2">
          {FormInput(
            "Units/Visits",
            "unitsVisits",
            false,
            "Please input Units/Visits code!"
          )}
        </div>
      </div>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-4 mt-2">
          {FormInput(
            "DME Purchase Price",
            "dmePurchasePrice",
            false,
            "Please input DME Purchase Price!"
          )}
        </div>

        <div className="col-md-8 mt-2">
          {FormInput(
            "Procedure/Device Description",
            "procedureDescription",
            false,
            "Please input Procedure/Device description!"
          )}
        </div>
      </div>

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-12 mt-2">
          {FormRadio(
            "Anesthesia",
            "anesthesia",
            "anesthesiaYes",
            "anesthesiaNo",
            onRadioHandleChange
          )}
        </div>
      </div>

      {isAnesthesiaYesSelected ? (
        <div className="row" style={{ padding: "0 20px" }}>
          <div className="col-md-12 mt-2">
            <Form.Item
              label={"If yes, Specify Type"}
              name={"specifyType"}
              rules={[
                {
                  required: isAnesthesiaYesSelected ? true : false,
                  message: "Please select at least one type!",
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Checkbox.Group onChange={anesthesiaSpecifyTypeHandleChange}>
                <Checkbox value="local">Local</Checkbox>
                <Checkbox value="consciousSedation">
                  Conscious Sedation
                </Checkbox>
                <Checkbox value="general">General</Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>
        </div>
      ) : null}

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-12 mt-2">
          {FormRadio(
            "Assistant Surgeon",
            "assistantSurgeon",
            "assistantSurgeonYes",
            "assistantSurgeonNo",
            onRadioHandleChange
          )}
        </div>
      </div>

      {isAssistantSurgeonYesSelected ? (
        <div className="row" style={{ padding: "0 20px" }}>
          <div className="col-md-12 mt-2">
            {FormInput(
              "If yes, Assistant surgeon name/NPI",
              "assistantSurgeonName",
              true,
              "Please input assistant surgeon name!"
            )}
          </div>
        </div>
      ) : null}

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-12 mt-2">
          <Form.Item
            label={"Surgical Approach"}
            name={"surgicalApproach"}
            rules={[
              {
                required: true,
                message: "Please select at least one type!",
              },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group onChange={surgicalApproachHandleChange}>
              <Checkbox value="open">Open</Checkbox>
              <Checkbox value="laparoscopic">Laparoscopic</Checkbox>
              <Checkbox value="endoscopic">Endoscopic</Checkbox>
              <Checkbox value="robotic">Robotic</Checkbox>
              <Checkbox value="surgicalApproachOther">Other</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div>

      {isSurgicalApproachYesSelected ? (
        <div className="row" style={{ padding: "0 20px" }}>
          <div className="col-md-12 mt-2">
            {FormInput(
              "If other, please specify",
              "surgicalOtherSpecify",
              true,
              "Please input your reason!"
            )}
          </div>
        </div>
      ) : null}

      <div className="row" style={{ padding: "0 20px" }}>
        <div className="col-md-12 mt-2">
          {FormRadio(
            "Will a computerized navigation system be used?",
            "computerizedNavigation",
            "computerizedNavigationYes",
            "computerizedNavigationNo",
            onRadioHandleChange
          )}
        </div>
      </div>
    </>
  );
};

const PreAuthForm = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const patient_data = location?.state?.patientData;
  if (!patient_data.f_name) {
    patient_data.f_name = patient_data?.Username;
    patient_data.patientid = patient_data?.user_id;
  }

  const { user, token } = useSelector(({ user }) => ({
    ...user,
  }));
  
  const [insuranceDetails,setinsuranceDetails]   = useState([]);
  
  
 
  
    async function getPatientDetail() {
      // The value we return becomes the `fulfilled` action payload
     
      const formData = new FormData();
      formData.append("action", "user_detail");
      formData.append("country", "US");
      formData.append("id", patient_data?.patientid);
      formData.append("auterizetokenid",  user?.id);
      formData.append("society_id",  user?.society_id ?? "");
 
      const response = await APIHitCall(formData);
      if(response.data.status === 200){
            setinsuranceDetails(response.data.result[0].insurancedetails);
            console.log("ins",response.data.result[0].insurancedetails)
            if(response.data.result[0].insurancedetails && response.data.result[0].insurancedetails.length>0){
              const filteredData = response.data.result[0].insurancedetails.filter(obj => obj.INS_PRIMARY === "1");
              if(filteredData.length >0){
                form.setFieldsValue({ primaryHealthInsurance: filteredData[0].insuranceCarrier });
                form.setFieldsValue({ primaryInsuranceID: filteredData[0].memberId });
                form.setFieldsValue({ primaryInsurancePlan: filteredData[0].account_number });
              }else{
            form.setFieldsValue({ primaryHealthInsurance: response.data.result[0].insurancedetails[0].insuranceCarrier });
            form.setFieldsValue({ primaryInsuranceID: response.data.result[0].insurancedetails[0].memberId });
            form.setFieldsValue({ primaryInsurancePlan: response.data.result[0].insurancedetails[0].account_number });
             }
            }
    
      }
     
    }

  useEffect(() => {
    patient_dob = moment(patient_data?.dob).format("YYYY-MM-DD");
  }, []);

  useEffect(() => {
    getPatientDetail();
   // dispatch(getPatientDetail(patient_data?.patientid));
  }, []);



  async function submitAuthForm(values, goBack) {
    let anesthesiaValue = "";
    let assistantSurgeonValue = "";
    let computerizedValue = "";
    const filteredServiceFacilityObject = {};
    const serviceFacilityKeys = {};

    if (Object.keys(serviceFacilityObject).length !== 0) {
      filteredServiceFacilityObject = Object.fromEntries(
        Object.entries(serviceFacilityObject).filter(
          ([key, value]) => value === true
        )
      );

      serviceFacilityKeys = Object.keys(filteredServiceFacilityObject).join(
        ", "
      );
    }

    const filteredAnesthesiaYesObject = Object.fromEntries(
      Object.entries(anesthesiaYesObject).filter(
        ([key, value]) => value === true
      )
    );

    const filteredSurgicalApproachObject = Object.fromEntries(
      Object.entries(surgicalApproachObject).filter(
        ([key, value]) => value === true
      )
    );

    const anesthesiaYesKeys = Object.keys(filteredAnesthesiaYesObject).join(
      ", "
    );
    const surgicalApproachKeys = Object.keys(
      filteredSurgicalApproachObject
    ).join(", ");

    const requestedProcedureValues = {
      diagnosis: values?.diagnosisCode || "",
      cpt: values?.cptCode || "",
      unit: values?.unitsVisits || "",
      dme: values?.dmePurchasePrice || "",
      procedure: values?.procedureDescription || "",
    };

    if (anesthesiaObject.anesthesia) {
      anesthesiaValue = "Yes";
    } else {
      anesthesiaValue = "No";
    }

    if (assistantSurgeonObject.assistantSurgeon) {
      assistantSurgeonValue = "Yes";
    } else {
      assistantSurgeonValue = "No";
    }

    if (computerizedSystemObject.computerizedSystem) {
      computerizedValue = "Yes";
    } else {
      computerizedValue = "No";
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("pt_id", patient_data?.patientid);

    formData.append("clinicid", user?.society_id);
    formData.append("action", "patient_preauth_submission");
    formData.append("current_date", todays_date || "");
    formData.append("date_of_service_from", date_from || "");
    formData.append("date_of_service_to", date_to || "");
    formData.append("contact_name", values.contactName || "");
    formData.append("contact_email", values.email || "");
    formData.append("contact_phone", values.caseManagerPhone || "");
    formData.append("contact_fax", values.caseManagerFax || "");
    formData.append("pt_name", values.patientName || "");
    formData.append("pt_dob", patient_dob || "");
    formData.append("pt_city_state", values.patientCityState || "");
    formData.append("pri_health_ins", values.primaryHealthInsurance || "");
    formData.append("pri_health_insID", values.primaryInsuranceID || "");
    formData.append("pri_health_insPlan", values.primaryInsurancePlan || "");
    formData.append("sec_health_ins", values.otherHealthInsurance || "");
    formData.append("sec_health_insID", values.otherInsuranceID || "");
    formData.append("sec_health_insPlan", values.otherInsurancePlan || "");
    formData.append("req_pro", requestingProviderName || "");
    formData.append("requesting_rpovider_id", values.requestingProvider || "");

    formData.append("req_pro_npi", values.providerNPI || "");
    formData.append("req_pro_address", values.providerCompleteAddress || "");
    formData.append("ser_pro", serviceProviderName || "");
    formData.append("service_provider_id", values.serviceProvider || "");

    formData.append("ser_rpo_npi", values.serviceProviderNPI || "");
    formData.append("ser_pro_area", values.serviceProviderAreaCode || "");
    formData.append(
      "ser_pro_address",
      values.serviceProviderCompleteAddress || ""
    );
    formData.append("service_facility", serviceFacilityKeys || "");

    formData.append(
      "service_facility_other",
      values.serviceFacilitySpecify || ""
    );
    formData.append(
      "service_facility_add",
      values.serviceFacilityCompleteAddress || ""
    );
    formData.append("service_facility_area", values.serviceFacilityAreaCode || "");
    formData.append("service_facility_npi", values.serviceFacilityNPI || "");
    formData.append(
      "requested_procedure",
      JSON.stringify(requestedProcedureValues)
    );
    formData.append("anesthesia", anesthesiaValue || "");
    formData.append("anesthesia_type", anesthesiaYesKeys);
    formData.append("assistant_surgeon", assistantSurgeonValue);
    formData.append(
      "assistant_surgeon_name",
      values.assistantSurgeonName || ""
    );
    formData.append("surgical_approach", surgicalApproachKeys);
    formData.append(
      "surgical_approach_other",

      values.surgicalOtherSpecify || ""
    );
    formData.append("comp_nav_sys", computerizedValue);
    formData.append("casemanager", user?.id);
    formData.append("auterizetokenid", user?.id);

    if ((fileList ?? []).length > 0) {
      (fileList ?? []).forEach((file) => {
        formData.append("attachment", file?.originFileObj, file.name);
      });
    }

    //let res = await apihitCall(formData, token);
    const res = await postApi(formData, token);

    if (res.data.status === 200) {
      message.success("Pre Auth Form submitted successfully");
      setLoading(false);
      goBack();
    }
    if (res?.data?.status === 400) {
      setLoading(false);
    }
  }

  const onFinish = (values) => {
    submitAuthForm(values, goBack);
  };

  const goBack = () => {
    navigation(-1);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";

    // || file.type ===
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isJpgOrPng) {
      setUploadError(true);
      message.error("You can only upload JPG/PNG/doc file!");
      return;
    }
    setUploadError(false);
    setFileList([...fileList, file]);

    return false;
  };

  const handlePreview = async (file) => {
    if (
      file?.type === "application/pdf"
      //  ||
      // file?.type ===
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      // `base64String` is the given base 64 data
      // const blob = base64toBlob(base64String);
      // const url = URL.createObjectURL(blob);

      setPreviewImage({
        type: file?.type,
        url: await getBase64(file.originFileObj),
      });
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
      );
      setPreviewOpen(true);
      return;
    }

    setPreviewImage({
      type: file?.type,
      url: file.thumbUrl,
    });
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = (info) => {
    let newFileList = [...info.fileList];
    if (newFileList?.length > 3) {
      message.destroy();
      message.error("You can upload only 3 files.");
    }

    newFileList = newFileList.slice(-3);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    onChange: handleChange,
    multiple: true,
    fileList,
  };

  return (
    <CaseMangerWrapper>
      <div
        style={{
          boxShadow: "0px 10px 15px #dddddd",
          margin: "20px 10px",
          padding: "10px",
        }}
      >
        <Form
          name="basic"
          form={form}
          initialValues={{
            todaysDate: moment(),
            contactName: user.username + " " + user.lastname,
            email: user.email,
            caseManagerPhone: user.phone !== "0" ? user.phone : "",
            patientName: patient_data?.f_name + " " + patient_data?.l_name,
            patientDOB: moment(patient_data?.dob, "MM-DD-YYYY"),
            serviceFacilityCompleteAddress: '',
            serviceFacilityAreaCode: '',
            serviceFacilityNPI: '',
            diagnosisCode: '',
            cptCode: '',
            unitsVisits: '',
            dmePurchasePrice: '',
            procedureDescription: '',
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {CaseManagerDetail()}
          {PatientInformationDetail(form, patient_data?.patientid)}
          {ProviderInformationDetail(form)}
          {RequestedProcedureDetails()}

          <div className="row" style={{ padding: "0 20px" }}>
            <div className="col-md-4 mt-2">
              <Form.Item name="files">
                <Dragger
                  listType="picture"
                  beforeUpload={beforeUpload}
                  onPreview={handlePreview}
                  {...props}
                  maxCount={1}
                  multiple
                  className="p-3"
                >
                  <p className="ant-upload-drag-icon">
                    <FiUploadCloud size={24} />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Only jpg,png,pdf files accepted.
                  </p>
                </Dragger>
              </Form.Item>
            </div>
          </div>

          <div className="row" style={{ padding: "0 20px" }}>
            <div className="col-md-4 mt-4">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </CaseMangerWrapper>
  );
};

export default PreAuthForm;
