import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Card, Typography, Modal, Result, Spin } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import { APIHitCall } from '../../../features/case-manager/caseManagerService';
import { apiHitWithTempToken } from '../../../service/authService';
import { getClientSecret, getClient_id } from '../../../utils/functions';
import axiosHIT from 'axios'
import { useDispatch } from 'react-redux';
import { setTempToken } from '../../../features/auth/authSlice';
import './ConferenceForm.css'; // Importing a new CSS file for custom styles
const { Title } = Typography;

const ConferenceForm = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalText, setModalText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const FD = new FormData();
      FD.append("action", "guest_contact_form");
      Object.keys(values).forEach(key => FD.append(key, values[key]));
     
      let res = await apiHitWithTempToken(FD);
      if (res.data.status === 200) {
        form.resetFields();

        setModalText('Thanks for Registering with MedSetGo! Someone from our office will be reaching out to you shortly.');
        setIsModalVisible(true);

      }
    } catch (error) {
      console.error(error);
      setModalText('An error occurred while submitting the form. Please try again.');
      setIsModalVisible(true);
    }
    setIsLoading(false);
  }


   const authStep1=(code)=>{
    const formdata = new FormData();
    formdata.append("client_id", getClient_id());
    formdata.append("client_secret", getClientSecret());
    formdata.append("grant_type",code?'authorization_code': "client_credentials");
    if(code){
      formdata.append("code",code);
    }
  
    return axiosHIT({
      url: `${process.env.REACT_APP_API_SCHEME}://${process.env.REACT_APP_API_HOST}/Token.php`,
      method: "post",
      data: formdata,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        
      },
    });
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const generateTempToken = async () => {
    try {
        const res = await authStep1();
        if (res.status === 200) {
            dispatch(setTempToken(res?.data?.access_token));
           
        }

    } catch (error) {}
}

useEffect(() => {
    generateTempToken();
}, [])

  return (
    <div id="conference-form-container">
    <img src="assets/images/logo-black.svg" alt="Logo" id="conference-logo"/>
    <Title level={5} id="conference-title">Free Limited Use of the MedSetGo Platform</Title>
    <Card id="conference-form-card">
      <Form  form={form}{...layout} onFinish={onFinish}>
          <Form.Item label="First Name" name="f_name" rules={[{ required: true, message: 'Please input your first name!' }]}>
            <Input disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Last Name" name="l_name" rules={[{ required: true, message: 'Please input your last name!' }]}>
            <Input disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[
            { type: 'email', message: 'The input is not valid E-mail!' },
            { required: true, message: 'Please input your email!' }
          ]}>
            <Input disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Cell Phone" name="phone" rules={[
            { required: false, message: 'Please input your cell phone!' },
            { pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/, message: 'Please enter a valid phone number!' }
          ]}>
            <Input disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Employer" name="employer" rules={[{ required: true, message: 'Please input your employer!' }]}>
            <Input disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Job Title" name="job_title" rules={[{ required: true, message: 'Please input your job title!' }]}>
            <Input disabled={isLoading} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button  type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
        </Card>
        <Modal centered style={{maxWidth: "90vw"}} visible={isModalVisible} footer={null} closable={false}>
        <Result
          icon={<LikeOutlined style={{ color: '#52c41a' }} />}
          title={<div id="conference-modal-text">{modalText}</div>}
          extra={<Button type="primary" onClick={handleOk}>Close</Button>}
        />
      </Modal>
    </div>
  );
};

export default ConferenceForm;
