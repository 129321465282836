import React, { useRef, useState } from "react";
import { Form, Button, Radio, Typography, Divider, Spin, message } from "antd";
import { postApi } from "../../../store/AddService/service";
import { authStep1 } from "../../../features/auth/authService";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useLocation } from "react-router-dom";
const { Title, Text } = Typography;

const options = {
  question1: ["Excellent", "Very good", "Good", "Fair", "Poor"],
  question2a: [
    "Yes, limited a lot",
    "Yes, limited a little",
    "No, not limited at all",
  ],
  question2b: [
    "Yes, limited a lot",
    "Yes, limited a little",
    "No, not limited at all",
  ],
  question3a: ["Yes", "No"],
  question3b: ["Yes", "No"],
  question4a: ["Yes", "No"],
  question4b: ["Yes", "No"],
  question5: [
    "Not at all",
    "A little bit",
    "Moderately",
    "Quite a bit",
    "Extremely",
  ],
  question6a: [
    "All of the time",
    "Most of the time",
    "Some of the time",
    "A little of the time",
    "None of the time",
  ],
  question6b: [
    "All of the time",
    "Most of the time",
    "Some of the time",
    "A little of the time",
    "None of the time",
  ],
  question6c: [
    "All of the time",
    "Most of the time",
    "Some of the time",
    "A little of the time",
    "None of the time",
  ],
  question7: [
    "All of the time",
    "Most of the time",
    "Some of the time",
    "A little of the time",
    "None of the time",
  ],
};

const defaultResponses = {
  question1: options.question1[0],
  question2a: options.question2a[2],
  question2b: options.question2b[2],
  question3a: options.question3a[1],
  question3b: options.question3b[1],
  question4a: options.question4a[1],
  question4b: options.question4b[1],
  question5: options.question5[0],
  question6a: options.question6a[0],
  question6b: options.question6b[0],
  question6c: options.question6c[4],
  question7: options.question7[4],
};

const sfForm = [
  {
    question: "1. In general, would you say your health is:",
    options: options.question1,
    name: "question1",
    answer: defaultResponses.question1,
  },
  {
    question: "2. The following questions are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
    subquestions: [
      {
        question: "a. Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf",
        options: options.question2a,
        name: "question2a",
        answer: defaultResponses.question2a,
      },
      {
        question: "b. Climbing several flights of stairs",
        options: options.question2b,
        name: "question2b",
        answer: defaultResponses.question2b,
      },
    ],
  },
  {
    question: "3. During the past 4 weeks, how much of the time have you had any of the following problems with your work or other regular daily activities as a result of your physical health?",
    subquestions: [
      {
        question: "a. Accomplished less than you would like",
        options: options.question3a,
        name: "question3a",
        answer: defaultResponses.question3a,
      },
      {
        question: "b. Were limited in the kind of work or other activities",
        options: options.question3b,
        name: "question3b",
        answer: defaultResponses.question3b,
      },
    ],
  },
  {
    question: "4. During the past 4 weeks, how much of the time have you had any of the following problems with your work or other regular daily activities as a result of any emotional problems (such as feeling depressed or anxious)?",
    subquestions: [
      {
        question: "a. Accomplished less than you would like",
        options: options.question4a,
        name: "question4a",
        answer: defaultResponses.question4a,
      },
      {
        question: "b. Did work or activities less carefully than usual",
        options: options.question4b,
        name: "question4b",
        answer: defaultResponses.question4b,
      },
    ],
  },
  {
    question: "5. During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the home and housework)?",
    options: options.question5,
    name: "question5",
    answer: defaultResponses.question5,
  },
  {
    question: "6. These questions are about how you feel and how things have been with you during the past 4 weeks. For each question, please give the one answer that comes closest to the way you have been feeling. How much of the time during the past 4 weeks...",
    subquestions: [
      {
        question: "a. Have you felt calm and peaceful?",
        options: options.question6a,
        name: "question6a",
        answer: defaultResponses.question6a,
      },
      {
        question: "b. Did you have a lot of energy?",
        options: options.question6b,
        name: "question6b",
        answer: defaultResponses.question6b,
      },
      {
        question: "c. Have you felt downhearted and blue?",
        options: options.question6c,
        name: "question6c",
        answer: defaultResponses.question6c,
      },
    ],
  },
  {
    question: "7. During the past 4 weeks, how much of the time has your physical health or emotional problems interfered with your social activities (like visiting with friends, relatives, etc.)?",
    options: options.question7,
    name: "question7",
    answer: defaultResponses.question7,
  },
];

const pcsWeights = {
  question1: [0, -1.31872, -3.02396, -5.56461, -8.37399],
  question2a: [-7.23216, -3.45555, 0],
  question2b: [-6.24397, -2.73557, 0],
  question3a: [-4.61617, 0],
  question3b: [-5.51747, 0],
  question4a: [3.04365, 0],
  question4b: [2.32091, 0],
  question5: [0, -3.8013, -6.50522, -8.38063, -11.25544],
  question6a: [0, 0.66514, 2.37241, 2.90426, 3.46638],
  question6b: [0, -0.42251, -1.6185, -2.02168, -2.44706],
  question6c: [4.61446, 3.41593, 1.28044, 0.41188, 0],
  question7: [-0.33682, -0.94342, -0.18043, 0.11038, 0],
};

const mcsWeights = {
  question1: [0, -0.06064, 0.03482, -0.16891, -1.71175],
  question2a: [3.93115, 1.8684, 0],
  question2b: [2.68282, 1.43103, 0],
  question3a: [1.4406, 0],
  question3b: [1.66968, 0],
  question4a: [-6.82672, 0],
  question4b: [-5.69921, 0],
  question5: [0, 0.90384, 1.49384, 1.76691, 1.48619],
  question6a: [0, -1.94949, -6.31121, -7.92717, -10.19085],
  question6b: [0, -0.92057, -3.29805, -4.88962, -6.02409],
  question6c: [-16.15395, -10.77911, -4.59055, -1.95934, 0],
  question7: [-6.29724, -8.26066, -5.63286, -3.13896, 0],
};

const rawScores = {
  question1: [5, 4, 3, 2, 1],
  question2a: [1, 2, 3],
  question2b: [1, 2, 3],
  question3a: [1, 2],
  question3b: [1, 2],
  question4a: [1, 2],
  question4b: [1, 2],
  question5: [5, 4, 3, 2, 1],
  question6a: [5, 4, 3, 2, 1],
  question6b: [5, 4, 3, 2, 1],
  question6c: [5, 4, 3, 2, 1],
  question7: [1, 2, 3, 4, 5],
};

// Placeholder mean and standard deviation values for PCS and MCS
let meanPCS = 56.57706;
const stdDevPCS = 10;
let meanMCS = 60.75781;
const stdDevMCS = 10;

const calculatePCS = (responses) => {
  let sumPCS = 0;
  Object.keys(responses).forEach((key) => {
    const index = options[key].indexOf(responses[key]);
    const weight = pcsWeights[key][index];
    sumPCS += weight;
  });
  const mpcs = meanPCS + sumPCS;
  const pcsScore = mpcs - 50;
  return { pcsScore, mpcs };
};

const calculateMCS = (responses) => {
  let sumMCS = 0;
  Object.keys(responses).forEach((key) => {
    const index = options[key].indexOf(responses[key]);
    const weight = mcsWeights[key][index];
    sumMCS += weight;
  });
  const mmcs = meanMCS + sumMCS;
  const mcsScore = mmcs - 50;
  return { mcsScore, mmcs };
};

const OutgoingSurveyForm = () => {
  const formRef = useRef();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const patientId = queryParams.get("patientId");
  const clinic_ID = queryParams.get("clinic_ID");
  const providerId = queryParams.get("providerId");

  const [sfFormData, setSfFormData] = useState(sfForm);
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState(defaultResponses);
  const [pcsScore, setPcsScore] = useState(
    calculatePCS(defaultResponses).pcsScore
  );
  const [pcsTotalScore, setPcsTotalScore] = useState(
    calculatePCS(defaultResponses).mpcs
  );
  const [mcsScore, setMcsScore] = useState(
    calculateMCS(defaultResponses).mcsScore
  );
  const [mcsTotalScore, setMcsTotalScore] = useState(
    calculateMCS(defaultResponses).mmcs
  );

  const handleFinish = (values) => {
    console.log("Form values:", values);
    submitForm();
  };

  const submitForm = async () => {
    setLoading(true);
    const resdata = await authStep1();
    try {
      const pdf = await handleGeneratePdf();
      const pdfBlob = pdf.output("blob");

      const FD = new FormData();

      FD.append("action", "submit_patient_sf12");
      FD.append("patientId", patientId);
      FD.append("clinicId", clinic_ID);
      FD.append("form_field", JSON.stringify(sfFormData));
      FD.append("physical_score", pcsTotalScore.toFixed(5));
      FD.append("mental_score", mcsTotalScore.toFixed(5));
      FD.append("providerId", providerId);
      FD.append("pdffile", pdfBlob, "form.pdf");

      const res = await postApi(FD, resdata?.data?.access_token);
      console.log('response ', res);
      if (res?.status === 200) {
        setLoading(false);
        message.success("SF-12 form submitted successfully");
      } else {
        setLoading(false);
      }
    } catch (error) {
       console.log('error message ', error);
    }
  }

  const handleChange = (e, name) => {
    const updatedSfForm = sfFormData.map((item) => {
      if (item.name === name) {
        return { ...item, answer: e.target.value };
      } else if (item.subquestions) {
        return {
          ...item,
          subquestions: item.subquestions.map((sub) => {
            if (sub.name === name) {
              return { ...sub, answer: e.target.value };
            }
            return sub;
          }),
        };
      }
      return item;
    });

    const updatedResponses = {
      ...responses,
      [name]: e.target.value,
    };

    setSfFormData(updatedSfForm);
    const { pcsScore, mpcs } = calculatePCS(updatedResponses);
    const { mcsScore, mmcs } = calculateMCS(updatedResponses);
    setResponses(updatedResponses);
    setPcsScore(pcsScore);
    setMcsScore(mcsScore);
    setPcsTotalScore(mpcs);
    setMcsTotalScore(mmcs);
  }

  const handleGeneratePdf = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500)); // Add delay
    const formElement = formRef.current;
    const canvas = await html2canvas(formElement, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    pdf.addImage(imgData, "PNG", 10, 10, undefined, undefined, undefined, "FAST");
    pdf.addImage(imgData, "PNG", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);

    return pdf;
  };

  return (
    <div
      ref={formRef}
      style={{
        padding: "40px",
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
      }}
    >
      <Title level={2} style={{ textAlign: "center" }}>
        SF-12 Form
      </Title>
      <Form
        layout="vertical"
        onFinish={handleFinish}
        //initialValues={defaultResponses}
      >
        {sfFormData.map((item, index) => (
          <div key={index}>
            <Form.Item label={<Text strong>{item.question}</Text>}>
              {item.options ? (
                <Radio.Group
                  options={item.options}
                  value={item.answer}
                  onChange={(e) => handleChange(e, item.name)}
                  optionType="button"
                  buttonStyle="solid"
                />
              ) : (
                item.subquestions.map((sub, subIndex) => (
                  <Form.Item 
                  key={subIndex} 
                  label={<Text strong>{sub.question}</Text>} 
                  style={{ marginLeft: 20 }}>
                    <Radio.Group
                      options={sub.options}
                      value={sub.answer}
                      onChange={(e) => handleChange(e, sub.name)}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                ))
              )}
            </Form.Item>
          </div>
        ))}

        <Divider />
        <Text strong>Physical Component Summary (PCS) Score: </Text>
        <Text>{pcsScore.toFixed(5)}</Text>
        <br />
        <Text strong>PCS Total Score: </Text>
        <Text>{pcsTotalScore.toFixed(5)}</Text>
        <br />
        <Text strong>Mental Component Summary (MCS) Score: </Text>
        <Text>{mcsScore.toFixed(5)}</Text>
        <br />
        <Text strong>MCS Total Score: </Text>
        <Text>{mcsTotalScore.toFixed(5)}</Text>
        <Divider />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block={false}
            disabled={loading}
            size="large"
            style={{ width: "30%", margin: "0 auto", display: "block"}}
          >
            {loading ? <Spin /> : 'Submit'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default OutgoingSurveyForm;
