import { useDispatch, useSelector } from "react-redux";
import { updateLoginModal, updateSignModal } from "../features/auth/authSlice";

import { CaseMangerHeaderComponent } from "./Header";

import "./style/style.css";

import ChatUI from "./UI/Chat";
import Footer from "./common/Footer";
import PublicNavBar from "./header/PublicNavBar";
import ChatUICaseManager from "./UI/Chat/ChatCaseToCase";

const Wrapper = (props) => {
  return (
    <div className="wrapper-container">
      {props?.headerDisable ? <></> : <PublicNavBar />}
      <div>{props?.children}</div>
    
    </div>
  );
};

export const CaseMangerWrapper = (props) => {
  const dispatch = useDispatch();
  const { currentChat } = useSelector(({ caseManagerSlice, user, chat }) => ({
    ...caseManagerSlice,
    ...user,
    ...chat,
  }));
  return (
    <div className="wrapper-container">
      {currentChat && currentChat?.vendorid && currentChat?.vendorname && (
        (currentChat.type === "CaseManager" ?<ChatUICaseManager type={currentChat.type} />:<ChatUI/>)
       
      )}
      <CaseMangerHeaderComponent
        onLoginClick={() => dispatch(updateLoginModal(true))}
        onSignupClick={() => dispatch(updateSignModal(true))}
      />

      <div >{props?.children}</div>
    </div>
  );
};

export default Wrapper;
