
import { Divider } from 'antd'
import React from 'react'
import { NavLink } from 'react-router-dom'

const WhoWeAre = () => (
    <div className='d-flex flex-column who-we-are-wrapper w-lg-100'>
       <NavLink  to="/service-partner" className={({isActive})=>isActive?'item active':'item'}>Service Partners</NavLink>
       <Divider />
       <NavLink to="/health-system"className={({isActive})=>isActive?'item active':'item'}>Health System</NavLink>
      
       {/* <NavLink to="/patients"className={({isActive})=>isActive?'item active':'item'}>Patients</NavLink> */}
       {/* <NavLink to="/payors"className={({isActive})=>isActive?'item active':'item'}>Payors</NavLink> */}
    </div>
  )


export default WhoWeAre