import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";

import { useCallback, useEffect, useMemo, useState } from "react";
import Draggable from "react-draggable";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { chatURL } from "../../../config/socketConfig";
import { setCurrentChat } from "../../../store/chat/Slice";
const socket = io.connect(chatURL());

var chatmsg ="chatmsg"

const ChatUI = () => {
  const { user, currentChat } = useSelector(
    ({ caseManagerSlice, user, chat }) => ({
      ...caseManagerSlice,
      ...user,
      ...chat,
    })
  );



  useEffect(()=>{
    return () =>{
      // socket?.disconnect();
      // dispatch(setCurrentChat(null));
    }
  },[])

  const dispatch = useDispatch();

  const [roomId, setRoomId] = useState(null);

  const [message, setMessages] = useState([]);

  const connectSocket = () => {
    socket.connect("connect", () => {
      console.log("socket connected =>", socket.connected);
      //
    });
  };


  const onClose = () => {
    dispatch(setCurrentChat(null));
  };
  // const disconnectSocket=()=>{
  //   socket.disconnect("disconnect", () => {
  //     console.log("socket disconnect =>", socket.connected);
  //     //
  //   });
  // }
  useMemo(() => {
    connectSocket();
    // return ()=>disconnectSocket()
    // currentChat?.vendorid
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const receiveMessage = () => {
    socket.on("chatmsg", (arg) => {

      const newMessgae = {
        message: arg?.body?.data,
        is_read: 1,
        from: Number(user?.id),
        to: arg?.body?.to_user,
        created_at: new Date(),
        sender: arg?.body?.user,
      };
      setMessages((prev) => [...prev, newMessgae]);
    });
  };
  const getChatHistory = useCallback(
    (rId) => {

      socket.emit("cm_get_room_history", {
        room: rId || roomId,
        user: user?.id,
        meta: {
          room: rId || roomId,
          current_user: {
            user_id: user?.id,
            name: user?.username,
          },
          to_user: {
            user_id: currentChat?.vendorid,
          },
          context: "cs_to_sp",
        },
      });

      socket.on("cm_get_room_history", (data) => {

        setMessages(data ?? []);
      });
    },
    [currentChat?.vendorid, roomId, user?.id, user?.username]
  );

  const addToRoom = useCallback(
    (roomid) => {
      socket.emit("addToRoom", {
        room: roomid || roomId,
        user: user?.id,
        meta: {
          current_user: {
            user_id: user?.id,
            name: user?.username,
          },
          to_user: {
            user_id: currentChat?.vendorid,
          },
          context: "cs_to_sp",
        },
      });

      getChatHistory(roomid || roomId);
    },
    [currentChat?.vendorid, getChatHistory, roomId, user?.id, user?.username]
  );

  const createRoom = useCallback(() => {
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var roomid = "";

    for (let i = 0; i < 10; i++) {
      roomid += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    setRoomId(roomid);

    addToRoom(roomid);
  }, [addToRoom]);
  
  const getRoomList = useCallback(() => {
    if (!socket) return;
    let obj = {
      user: user?.id,
      meta: {
        current_user: {
          user_id: user?.id,
          name: user?.username,
        },
        to_user: {
          user_id: currentChat?.vendorid,
        },
        context: "list_of_rooms",
      },
    };
    socket.emit("get_room_list", obj);
    socket.on("get_room_list", (data) => {
      console.log("get_room_list",data);
      
      if (data?.length === 0) {
        createRoom();
      } else {
        setRoomId(data?.[0]);
        addToRoom(data?.[0]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChat?.vendorid, createRoom, user?.id, user?.username]);

  useEffect(() => {
    if (socket.connected) {
      getRoomList();
      receiveMessage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const onMsgSend = (m) => {
    const chatData = {
      data: m,
      user: user?.id,
      meta: {
        room: roomId,
        current_user: {
          user_id: user?.id,
          name: user?.username,
        },
        to_user: {
          user_id: currentChat?.vendorid,
        },
        context: "cs_to_sp",
      },
    };

    socket.emit("chatmsg", chatData);

    setMessages((p) => [
      ...p,
      {
        chat_room_id: roomId,
        message: m,
        is_read: 1,
        from: Number(user?.id),
        to: currentChat?.vendorid,
        created_at: new Date(),
        sender: user?.username,
      },
    ]);
  };

  if (currentChat && currentChat?.vendorid && currentChat?.vendorname) {
    return (
      <Draggable>
        <div className="float-chat-wrapper">
          <div style={{ position: "relative", height: "100%" }}>
            <MdClose
              size={26}
              className="position-absolute cursor-pointer"
              style={{ top: 20, right: 10, zIndex: 9 }}
              onClick={onClose}
            />
            <MainContainer>
              <ChatContainer>
                <ConversationHeader className="position-relative">
                  {/* <Avatar src={receiverImg} name={currentChat?.vendorname} /> */}
                  <ConversationHeader.Content
                    userName={currentChat?.vendorname}
                    info=""
                  />
                </ConversationHeader>
                <MessageList autoScrollToBottomOnMount={true}>
                  {message?.map((el) => (
                    <Message
                      model={{
                        message: el?.message,
                        sentTime: el?.created_at,
                        sender: el?.sender,
                        direction:
                          el?.sender === user?.username
                            ? "outgoing"
                            : "incoming",
                      }}
                    />

                  ))}
                </MessageList>
                <MessageInput
                  style={{ minHeight: 70 }}
                  className="d-flex align-items-center"
                  attachButton={false}
                  fancyScroll
                  placeholder="Type message here"
                  onSend={onMsgSend}
                />
              </ChatContainer>
            </MainContainer>
          </div>
        </div>
      </Draggable>
    );
  }
  return null;
};

export default ChatUI;
