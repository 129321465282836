import React from "react";
import { testimonialData } from "../utils/constants";
import Slider from "react-slick";
import {BiChevronLeft, BiChevronRight} from 'react-icons/bi'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
    className={`${className} cursor-pointer d-flex align-items-center justify-content-center`}
      style={{ ...style,position:'absolute', right:-40,height:35,width:35,borderRadius:'50%', background: "#fff" }}
      onClick={onClick}
    >
<BiChevronRight size={30} className='text-black'/>

    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} cursor-pointer d-flex align-items-center justify-content-center`}
      style={{ ...style, position:'absolute', left:-40,height:35,width:35,borderRadius:'50%', background: "#fff" }}
      onClick={onClick}
    >
      <BiChevronLeft size={30} className='text-black'/>
    </div>
  );
}

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  centerMode: true,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: true,
  autoplay:true,
  autoplaySpeed:3000,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};

const TestimonialComponent = () => {
  return (
    <section className="testimonial">
      <div className="container">
        <div className="user-testimonial">
          <h3>User Testimonials</h3>
        
            <Slider className="px-0" centerPadding={0}  {...settings} adaptiveHeight >
              {testimonialData?.map((item) => (
                <div
                style={{background:'transparent'}}
                className="px-3"
                >

           
                <div
                  style={{
                    // width: "4",

                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: '250px',
                  
                    // opacity:0.3
                  }}
                  className="ant-card p-3 rounded w-100"
                >
                  <div className="">
                    <img
                      style={{ width: 20 }}
                      src="assets/images/testimonial-icon.svg"
                     
                      alt=""
                    />
                    <p style={{ marginTop: 15, fontSize: 16 }}>
                      {item?.description}
                    </p>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "unset",
                      columnGap: 15,
                    }}
                    className="user-info flex"
                  >
                    <img
                      style={{ width: 40, height: 40 }}
                      // src="assets/images/testimonial.png"
                      src={item?.icon}
                      alt=""
                    />
                    <div className="user-content">
                      <h4>{item?.username}</h4>
                    </div>
                  </div>
                </div>
                </div>
              ))}
            </Slider>
          
        </div>
      </div>
    </section>
  );
};

export const TeamComponent = () => {
  return (
    <section style={{ background: "#fff" }} className="testimonial">
      <div className="container">
        <div className="user-testimonial">
          <h3>Meet the lead</h3>
          <div className="testimonial-row grid-container-three">
            {/* Iteam 3  */}
            <div className="testimonial-block grid-item">
              <div className="user-info flex">
                <img
                  src="assets/images/team/Profile Pic and Bio Shrey Kapoor.png"
                  alt=""
                />
                <div className="user-content">
                  <span style={{ fontSize: 20 }}>Shrey Kapoor</span>
                  <p style={{ marginTop: 0 }}>Chief Executive Officer</p>
                </div>
              </div>
              <p
                className="lead-description"
                style={{ flex: 1, textAlign: "justify" }}
              >
                Shrey Kapoor is an MD/MBA Candidate at the Johns Hopkins School
                of Medicine (recipient of Schier and Harry P. Clause Jr.
                Scholarship) after receiving his undergraduate degrees at
                Hopkins in Public Health and Natural Sciences in 2019. His
                aspirations are to improve health outcomes at both the
                individual and societal level by leveraging technological
                innovations and applying them to the healthcare setting, and to
                provide patient-centered clinical care. His current research
                focuses include big data projects in orthopedic surgery as well
                as optimizing postoperative care in the ICU setting. Shrey’s
                hobbies include playing pick-up hoop, traveling the world, and
                enjoying the beach with his family.
              </p>
            </div>

            {/* Iteam 2  */}
            <div className="testimonial-block grid-item">
              <div className="user-info flex">
                <img
                  src="assets/images/team/Chief Medical Officer.jpeg"
                  alt=""
                />
                <div className="user-content">
                  <span style={{ fontSize: 20 }}>Andre</span>
                  <p style={{ marginTop: 0 }}>Chief Medical Officer</p>
                </div>
              </div>
              <p
                className="lead-description"
                style={{ flex: 1, textAlign: "justify" }}
              >
                Andre (Nic) Gay, MD Dr. Gay treats leg, ankle and foot
                conditions in adults and children, with a focus on non-surgical
                techniques when possible. He has significant training in total
                ankle replacement, arthroscopic techniques and sports-related
                conditions that occur in athletes. Dr. Gay uses a comprehensive
                approach to each patient, focusing on mutual decision-making in
                which patients help guide their care and treatment plan. When
                Dr. Gay is not seeing patients, he spends time playing golf,
                surfing, wakeboarding and traveling.
              </p>
            </div>

            {/* Iteam 1  */}
            <div className="testimonial-block grid-item">
              <div className="user-info flex">
                <img src="assets/images/team/Harry Kharodh.jpeg" alt="" />
                <div className="user-content">
                  <span style={{ fontSize: 20 }}>Harry Kharodh</span>
                  <p style={{ marginTop: 0 }}>Chief Technology Officer</p>
                </div>
              </div>
              <p
                className="lead-description"
                style={{ flex: 1, textAlign: "justify" }}
              >
                Harry Kharodh (Chief Technology Officer) joins MedSetGo with
                more than 30 years of experience (17 years with Kaiser
                Permanente) building strong technical teams and providing
                leadership at the nexus of business and technology, implementing
                innovative business concepts that transform business and
                optimize operations. Harry is established leader, deeply skilled
                in strategic planning, portfolio management, digital
                transformation, enterprise architecture and delivering next
                generation products and platforms in the Healthcare, Banking and
                Telecom industries. Harry enjoys playing golf, traveling and
                spending time with his family.
              </p>
            </div>

            {/* Iteam 4  */}
            <div className="testimonial-block grid-item">
              <div className="user-info flex">
                <img src="assets/images/team/vivekv3.png" alt="" />
                <div className="user-content">
                  <span style={{ fontSize: 20 }}>Vivek Kapoor</span>
                  <p style={{ marginTop: 0 }}>Chief Innovation Officer</p>
                </div>
              </div>
              <p
                className="lead-description"
                style={{ flex: 1, textAlign: "justify" }}
              >
                Vivek Kapoor (Chief Innovation Officer) - Before starting
                MedSetGo Vivek was one of the strongest forces behind the
                success and growth of TownConnect. His leadership qualities and
                abilities to guide and coordinate the functioning of a team as
                big as this is what sets him apart from all the others. With a
                master’s degree in Computer Science, Vivek has held several
                leadership positions in some of the biggest and the leading tech
                companies like Oracle, Sun Microsystems Cisco, NetApp, Deloitte,
                etc. before starting TownConnect. A sports enthusiast, he enjoys
                cricket in his free time and love spending time with his family.
              </p>
            </div>

            {/* Iteam 5  */}
            <div className="testimonial-block grid-item">
              <div className="user-info flex">
                <img src="assets/images/team/Matthew_Perrault.jpeg" alt="" />
                <div className="user-content">
                  <span style={{ fontSize: 20 }}>Matthew Perrault</span>
                  <p style={{ marginTop: 0 }}>Senior Financial Advisor</p>
                </div>
              </div>
              <p
                className="lead-description"
                style={{ flex: 1, textAlign: "justify" }}
              >
                Matt brings 30+ Years in Finance, 100+ M&A IPOs, and his
                experience as a SEC & Technology Audit Practice leader to the
                team.
              </p>
            </div>

            {/* Iteam 6  */}
            <div className="testimonial-block grid-item">
              <div className="user-info flex">
                <img src="assets/images/team/Sanchi.jpeg" alt="" />
                <div className="user-content">
                  <span style={{ fontSize: 20 }}>Saanchi Kapoor</span>
                  <p style={{ marginTop: 0 }}>Co-Founder</p>
                </div>
              </div>
              <p
                className="lead-description"
                style={{ flex: 1, textAlign: "justify" }}
              >
                As a co-founder of Medsetgo she is working on customer
                acquisition strategy and helping build AI recommendations models
                for home health services. With a passion for the combined fields
                of business and computer science, Saanchi partakes in a wide
                range of projects from wet lab research on nanoparticles (patent
                pending!) Her most notable accomplishment is winning as the
                state champion in DECA for Principles of Business Management and
                Administration and placing as a finalist at the International
                level. In her free time, she enjoys dancing, traveling the world
                with her family, and cooking vegan recipes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialComponent;
