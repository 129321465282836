import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatList: [],
  loading: true,
  currentChat: {
    vendorid: null,
    vendername:null,
  },
};

export const chat = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setChatData: (state, action) => {
      state.chatList = action.payload;
    },
    updateChatLoader: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentChat:(state,action)=>{
      state.currentChat=action.payload
    }
  },
});

export const { setChatData,updateChatLoader,setCurrentChat } = chat.actions;

export default chat.reducer;
