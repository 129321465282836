


import "./style/style.css";


import { Link } from "react-router-dom";

export const MedBannerSection = () => {
  return (
    <section className="medical-service-banner">
      <div className="container">
        <div className="service-banner-bottom">
          <div className="medical-banner-info">
            <h3>Medical Services, a click away!</h3>
            <p>
              Access USA’s top medical service provider on the MedSetGo app,
              available 24/7, from the comfort of your home.
            </p>
            <div className="download-app">
              <h4>Download the App for Free!</h4>
              <div className="apps-area">
                <a
                  target={"_blank"}
                  href="https://play.google.com/store/apps/details?id=com.med.medsetgo"
                  rel="noreferrer"
                >
                  <img src="assets/images/google-play-icon.png" alt="" />
                </a>
                <a
                  target={"_blank"}
                  href="https://apps.apple.com/us/app/medsetgo/id1639987121"
                  rel="noreferrer"
                >
                  <img src="assets/images/app-store-icon.png" alt="" />
                </a>
              </div>
            </div>
          </div>
          <img src="assets/images/mobiles.png" alt="" className="mobile-img" />
        </div>
      </div>
    </section>
  );
};

export const ServiceBanner = ({ imageSrc, title, desc, onClick }) => {
  return (
    <section onClick={onClick} className="service-banner">
      <div className="container">
        <div className="serv-banner-inner flex align-items-center">
          <div className="banner-left-box">
            <div className="services-banner-info">
              <h1>{title || ""}</h1>
              <p
                className="desc-container"
                style={{
                  whiteSpace: "initial",
                  minHeight: 150,
                }}
                dangerouslySetInnerHTML={{ __html: desc }}
              />
            </div>
          </div>
          <div className="banner-right-box">
            <img src={imageSrc || ""} alt="" className="flex" />
          </div>
        </div>
      </div>
    </section>
  );
};

export const AboutUsBanner = () => {
  return (
    <section className="service-banner">
      <div className="container">
        <div className="serv-banner-inner flex align-items-center">
          <div className="banner-right-box">
            <img
              style={{ width: "100%" }}
              src="assets/images/about-us-banner.jpg"
              alt=""
              className="flex"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export const MedFeatureSection = () => {
  return (
    <section className="med-features">
      <div className="container">
        <div className="med-features-box">
          <ul>
            <li>
              <img src="assets/images/accurate-reports-icon.svg" alt="" />
              <span>Timely and Accurate Reports</span>
            </li>
            <li>
              <img src="assets/images/sample-collection-icon.svg" alt="" />
              <span>Home sample collection for FREE</span>
            </li>
            <li>
              <img src="assets/images/offers-icon.svg" alt="" />
              <span>Offers and affordable prices</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export const AboutUsSection = () => {
  return (
    <section style={{ marginTop: 40 }} className="service-banner">
      <div className="container">
        <div className="service-banner-bottom about-us-container">
          <div>
            <img
              alt=""
              className="img-about"
              src="/assets/images/closeup-support-hands.jpg"
            />
          </div>
          <div className="medical-banner-info para-desc">
            <h3>About MedSetGo</h3>
            <p>
              Access USA’s top medical service provider on the MedSetGo app,
              available 24/7, from the comfort of your home.MedSetGo offers
              patients and caregivers to patients a platform to access high
              quality and reliable non-emergent healthcare services on demand
              and cheaply, with process improvements for each stakeholder.
            </p>
            <div className="link-btn">
              <Link to={"/about-us"}>
                <p style={{ color: "#fff" }}>Learn More</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const WhyMedSetGo = () => (
  <section className="service-banner about-left why-section">
    <div className="container container-why-section">
      <span className="heading">Why MedSetGo</span>
      <div className="item-parent-container">
        <div className="item-container">
          <img
            className="img-about"
            alt=""
            src="/assets/images/Group 1269.png"
          />
          <span className="item-text">Customer Satisfaction Guarantee</span>
        </div>
        <div className="item-container">
          <img
            className="img-about"
            alt=""
            src="/assets/images/Group 1270.png"
          />
          <span className="item-text">
            Hassle-Free and On-Time Home Services
          </span>
        </div>
        <div className="item-container">
          <img
            className="img-about"
            alt=""
            src="/assets/images/Group 1271.png"
          />
          <span className="item-text">Trusted, Reliable Partners </span>
        </div>
        <div className="item-container">
          <img
            className="img-about"
            alt=""
            src="/assets/images/Group 1272.png"
          />
          <span className="item-text">Transparency and Communication</span>
        </div>
      </div>
    </div>
  </section>
);

