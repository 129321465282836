
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  alertTab: 1,
  fetchAlert: false
};

export const GlobalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setAlertTab: (state, action) => {
      state.alertTab = action.payload;
    },
    callAlertsApi: (state, action) => {
      state.fetchAlert = !state.fetchAlert;
    },
  },

});


export const {
  setAlertTab,
  callAlertsApi

} = GlobalSlice.actions;

export default GlobalSlice.reducer;
