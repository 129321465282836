import React from 'react'
import MessageResponse from "../assets/images/send-message-success.gif"
import { Modal } from 'antd'

function ResponseMessageModal({openAlert, setIsAlert}) {
    return (
        <Modal
            centered
            open={openAlert}
            onOk={() => setIsAlert(false)}
            onCancel={() => setIsAlert(false)}
            width={449}
            footer={false}
        >
            <div className='text-center'>
                <img src={MessageResponse} className='message-response' alt="Message response" />
            </div>
            <p className='text-center mt-3'>Thank you for showing interest! Our team will get back to you shortly.</p>
        </Modal>
    )
}

export default ResponseMessageModal